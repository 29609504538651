import { Injectable } from '@angular/core';
import { HttpEngine } from './http_engine';

@Injectable()
export class RecordingService {
  private readonly URL_DELETE_RECORDING_CALLS = 'recording_call/delete';
  private readonly GET_TRANSCRIPTION_PATH = 'transcriber';
  private readonly URL_SET_ENABLE_ANALYSIS = 'cdr/enable_analysis';

  constructor(
    private readonly httpEngine: HttpEngine
  ) { }

  deleteRecordCalls(ids: number[]): Promise<any> {
    const params = {
      ids,
    };

    return this.httpEngine.apiPostV2(this.URL_DELETE_RECORDING_CALLS, params);
  }

  getTranscription(id: number): Promise<any> {
    return this.httpEngine.apiGetV2(`${this.GET_TRANSCRIPTION_PATH}/${id.toString()}/transcription`);
  }

  setEnableAnalysis(recordingCallId: number): Promise<any> {
    return this.httpEngine.apiPostV2(this.URL_SET_ENABLE_ANALYSIS, {
      recording_call_id: recordingCallId
    });
  }

}
