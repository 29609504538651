import { NgModule, ModuleWithProviders, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { WephoneUtilsModule, MaterialModule, DialogService } from '@wephone-utils';

import { VirtualScrollerModule } from 'ngx-virtual-scroller';
import { NetStatusComponent } from './net-status/net-status.component';

import {
  CallingNumberSelectFormInput,
  CallingProfileSelectFormInput,
  ConferenceSelectFormInput,
  DidSelectFormInput,
  CurrencySelectFormInput,
  FlexSelectCustomFilterInput,
  FlexSelectGroupInput,
  FlexSelectQualificationInput,
  FlexSelectQueueInput,
  FlexSelectTreeInput,
  FlexSelectUserInput,
  FlexTreeInput,
  FlexSelectHotelRoomFormInput,
  IvrMenuSelectFormInput,
  EnterpriseSelectFormInput,
  IvrScriptSelectFormInput,
  LanguageSelectFormInputComponent,
  OpeningHourFormInput,
  OpeningHourSpecialDateFormInput,
  PauseReasonSelectFormInput,
  QualificationSelectFormInput,
  QueueSelectFormInput,
  RemoteApplicationSelectFormInput,
  RoutingAppSelectFormInput,
  SipNumberSelectFormInput,
  SipPhoneSelectFormInput,
  SkillSelectFormInput,
  SubscriptionPackSelectFormInput,
  BillingPlanSelectFormInput,
  CountrySelectFormInput,
  TeamGroupSelectFormInput,
  UserSelectFormInput,
  UserGroupSelectFormInput,
  FlexSelectTreeDialog,
  FlexSelectPauseInput,
  FlexTimePickerInput,
  FlexDurationInput,
  ObjectConditionSelect,
  FlexSelectDidInput,
  UserTagSelectFormInput,
  NotAssignNumberSelectFormInput,
  IvrEvaluationSelectFormInput,
  FlexSelectEnterpriseCrmFormInput,
  FlexSelectRoutingServiceFormInput,
  FlexSelectMenuInput,
  FlexSelectCampaignInput,
  SipTrunkSelectFormInput,
} from './form/inputs';

import { QualificationService, CallCenterQualificationDialog } from './qualification';
import {
  AgentItemComponent,
  AgentStatusComponent,
  AgentStatusSinceComponent,
  UserAvatarComponent,
  AvatarNameComponent,
  AgentGroupsComponent,
  UserVoiceMailComponent,
  UserCallTransferComponent,
  UserCallDetailComponent,
  UserCallRedirectDestDialog,
  PhoneAvailableAgentsComponent,
  PhoneBusyAgentsComponent,
} from './user';

import {
  MyvoicemailqueueComponent,
  MissedCallInfoDialogComponent,
  HandledCallsComponent,
  MissedCallsComponent,
  CallLogDetail
} from './call-lists';
import { RouterModule } from '@angular/router';
import { FlexSelectQueue, FlexSelectUser } from './inputs';
import { WephoneAudioModule } from '@wephone-audio';
import { AppConfigService } from './service/appconfig.service';
import { AutoUpdateService, CommonCustomIconsService, EnterpriseSipGatewayService, SipPhoneService } from './service';
import { WephoneCoreModule } from '@wephone-core/wephone-core.module';
import { CrmRoutingRuleSelectFormInput } from './form/inputs/crm-routing-rule-select/crm-routing-rule-select.component';
import { DataBuilderService } from '@wephone/services/data-builder.service';
import { EavesdropService } from './service/eavesdrop.service';
import { EnterPhoneNumberDialog } from './modals/dialog-enter-phone-number/dialog-enter-phone-number';
import { UserListComponent } from './user/user-list/user-list';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { PauseAgentDialog } from './modals/dialog-pause-agent/dialog-pause-agent';
import { WephoneCoreUIModule } from '@wephone-core-ui';
import { UrlInput } from './form/inputs/url-input/url-input.component';
import { ChatbotDialog, UserProfileDialog, SipphoneConfigSelectComponent, UserCallReceptionDialog } from './modals';
import { ChatbotModule } from '../chatbot';
import { TimezoneSelectComponent } from './form/input/timezone-select/timezone-select.component';
import { PackSelectComponent } from './form/inputs/pack-select/pack-select.component';
import { CountryFlagComponent } from './form/country-flag/country-flag.component';
import { LanguageSwichingComponent } from './language-swiching/language-swiching.component';
import { ChangeUserPasswordModal } from '@wephone/modals/change-user-password/change-user-password';
import { UpdatePaymentMethodModal } from '@wephone/modals/update-payment-method/update-payment-method.component';
import { ChooseSubscriptionPacksComponent } from './subscription/choose-subscription-packs/choose-subscription-packs.component';
import { AddCreditCardComponent } from '@wephone/modals/add-credit-card/add-credit-card.component';
import { AddSepaDebitComponent } from '@wephone/modals/add-sepa-debit/add-sepa-debit.component';
import { SubscriptionPacksModal } from './subscription-packs-modal/subscription-packs-modal.component';
import { FlexAlertUpgradeComponent } from './flex-alert-upgrade/flex-alert-upgrade.component';
import { PeriodSelectComponent } from './period-select/period-select.component';
import { CountryGlobalSelectComponent } from './form/inputs/country-global-select/country-global-select.component';
import { MissedCallExpandComponent } from './call-lists/missed-call/missed-call-expand/missed-call-expand.component';
import { CallInfoModalComponent } from './modals/call-info-modal/call-info-modal.component';
import { CreateBlackListModalComponent } from './modals/create-black-list-modal/create-black-list-modal.component';
import { UserCallReceptionComponent } from './user/user-call-reception/user-call-reception.component';
import { RegisteredSipPhonesComponent } from './registered-sip-phones/registered-sip-phones.component';
import { EnterpriseObjectTemplateInputComponent } from './form/inputs/enterprise-object-template-input/enterprise-object-template-input.component';
import { OutboundRuleSelectComponent } from './form/inputs/outbound-rule-select/outbound-rule-select.component';
import { LoadingScreenComponent } from './loading-screen/loading-screen';
import { QualificationServiceV2 } from './qualification/qualification2.service';
import { UserTurnOnMfaComponent } from './modals/user-turn-on-mfa/user-turn-on-mfa.component';
import { SipphonePhoneModelInputComponent } from './form/inputs/sipphone-phone-model-input/sipphone-phone-model-input.component';
// import { MatDialogModule } from '@angular/material/dialog';
// import { CommonModule } from '@angular/common';

@NgModule({
  imports: [
    WephoneAudioModule,
    WephoneUtilsModule,
    WephoneCoreModule,
    WephoneCoreUIModule,
    // MaterialModule,
    // CommonModule,
    RouterModule,
    VirtualScrollerModule,
    ChatbotModule,
    NgxMaterialTimepickerModule
  ],
  exports: [
    // CommonModule,
    // MaterialModule,
    VirtualScrollerModule,
    WephoneCoreModule,
    WephoneCoreUIModule,
    AgentGroupsComponent,
    UserAvatarComponent,
    AvatarNameComponent,
    AgentItemComponent,
    AgentStatusComponent,
    AgentStatusSinceComponent,
    UserVoiceMailComponent,
    UserCallTransferComponent,
    UserCallDetailComponent,
    CallingNumberSelectFormInput,
    CallingProfileSelectFormInput,
    ConferenceSelectFormInput,
    DidSelectFormInput,
    OutboundRuleSelectComponent,
    CurrencySelectFormInput,
    FlexSelectCustomFilterInput,
    FlexSelectGroupInput,
    FlexSelectPauseInput,
    FlexSelectQualificationInput,
    FlexSelectQueueInput,
    FlexSelectTreeInput,
    FlexSelectUserInput,
    FlexTreeInput,
    FlexSelectHotelRoomFormInput,
    IvrMenuSelectFormInput,
    EnterpriseSelectFormInput,
    IvrScriptSelectFormInput,
    LanguageSelectFormInputComponent,
    SipphonePhoneModelInputComponent,
    OpeningHourFormInput,
    OpeningHourSpecialDateFormInput,
    PauseReasonSelectFormInput,
    QualificationSelectFormInput,
    QueueSelectFormInput,
    RemoteApplicationSelectFormInput,
    RoutingAppSelectFormInput,
    SipNumberSelectFormInput,
    SipPhoneSelectFormInput,
    SkillSelectFormInput,
    SubscriptionPackSelectFormInput,
    BillingPlanSelectFormInput,
    CountrySelectFormInput,
    CountryGlobalSelectComponent,
    TeamGroupSelectFormInput,
    UserSelectFormInput,
    UserGroupSelectFormInput,
    FlexSelectTreeDialog,
    FlexSelectQueue,
    FlexSelectUser,
    CrmRoutingRuleSelectFormInput,
    PauseAgentDialog,
    FlexTimePickerInput,
    FlexDurationInput,
    ObjectConditionSelect,
    UrlInput,
    MissedCallsComponent,
    CallLogDetail,
    NetStatusComponent,
    ChatbotDialog,
    TimezoneSelectComponent,
    PackSelectComponent,
    CountryFlagComponent,
    FlexSelectDidInput,
    UserTagSelectFormInput,
    LanguageSwichingComponent,
    UserProfileDialog,
    ChooseSubscriptionPacksComponent,
    SubscriptionPacksModal,
    FlexAlertUpgradeComponent,
    PeriodSelectComponent,
    NotAssignNumberSelectFormInput,
    IvrEvaluationSelectFormInput,
    FlexSelectEnterpriseCrmFormInput,
    FlexSelectRoutingServiceFormInput,
    PhoneAvailableAgentsComponent,
    PhoneBusyAgentsComponent,
    CallInfoModalComponent,
    CreateBlackListModalComponent,
    UserCallReceptionComponent,
    UserCallReceptionDialog,
    RegisteredSipPhonesComponent,
    FlexSelectMenuInput,
    EnterpriseObjectTemplateInputComponent,
    FlexSelectCampaignInput,
    LoadingScreenComponent,
    SipTrunkSelectFormInput,
  ],
  declarations: [
    CallingNumberSelectFormInput,
    CallingProfileSelectFormInput,
    ConferenceSelectFormInput,
    DidSelectFormInput,
    CurrencySelectFormInput,
    FlexSelectCustomFilterInput,
    FlexSelectGroupInput,
    FlexSelectPauseInput,
    FlexSelectQualificationInput,
    FlexSelectQueueInput,
    FlexSelectTreeInput,
    FlexSelectUserInput,
    FlexTreeInput,
    FlexSelectHotelRoomFormInput,
    IvrMenuSelectFormInput,
    EnterpriseSelectFormInput,
    IvrScriptSelectFormInput,
    LanguageSelectFormInputComponent,
    OpeningHourFormInput,
    OpeningHourSpecialDateFormInput,
    PauseReasonSelectFormInput,
    QualificationSelectFormInput,
    QueueSelectFormInput,
    RemoteApplicationSelectFormInput,
    RoutingAppSelectFormInput,
    SipNumberSelectFormInput,
    SipPhoneSelectFormInput,
    SkillSelectFormInput,
    SubscriptionPackSelectFormInput,
    BillingPlanSelectFormInput,
    CountrySelectFormInput,
    CountryGlobalSelectComponent,
    TeamGroupSelectFormInput,
    UserSelectFormInput,
    UserGroupSelectFormInput,
    FlexSelectQueue,
    FlexSelectUser,
    MyvoicemailqueueComponent,
    AgentItemComponent,
    AgentStatusComponent,
    AgentStatusSinceComponent,
    AgentGroupsComponent,
    UserAvatarComponent,
    UserVoiceMailComponent,
    UserCallTransferComponent,
    UserCallDetailComponent,
    FlexSelectTreeDialog,
    CrmRoutingRuleSelectFormInput,
    EnterPhoneNumberDialog,
    UserCallRedirectDestDialog,
    UserListComponent,
    CallCenterQualificationDialog,
    HandledCallsComponent,
    MissedCallsComponent,
    CallLogDetail,
    MissedCallInfoDialogComponent,
    PauseAgentDialog,
    FlexTimePickerInput,
    FlexDurationInput,
    ObjectConditionSelect,
    UrlInput,
    NetStatusComponent,
    ChatbotDialog,
    TimezoneSelectComponent,
    PackSelectComponent,
    AvatarNameComponent,
    FlexSelectDidInput,
    CountryFlagComponent,
    UserTagSelectFormInput,
    LanguageSwichingComponent,
    UserProfileDialog,
    ChooseSubscriptionPacksComponent,
    SubscriptionPacksModal,
    FlexAlertUpgradeComponent,
    PeriodSelectComponent,
    SipphoneConfigSelectComponent,
    NotAssignNumberSelectFormInput,
    IvrEvaluationSelectFormInput,
    FlexSelectEnterpriseCrmFormInput,
    MissedCallExpandComponent,
    FlexSelectRoutingServiceFormInput,
    PhoneAvailableAgentsComponent,
    PhoneBusyAgentsComponent,
    CallInfoModalComponent,
    CreateBlackListModalComponent,
    UserCallReceptionComponent,
    UserCallReceptionDialog,
    RegisteredSipPhonesComponent,
    FlexSelectMenuInput,
    EnterpriseObjectTemplateInputComponent,
    FlexSelectCampaignInput,
    OutboundRuleSelectComponent,
    LoadingScreenComponent,
    SipTrunkSelectFormInput,
    UserTurnOnMfaComponent,
    SipphonePhoneModelInputComponent,
  ],
  entryComponents: [
    MatProgressSpinner,
    AgentGroupsComponent,
    UserAvatarComponent,
    AvatarNameComponent,
    AgentItemComponent,
    AgentStatusComponent,
    AgentStatusSinceComponent,
    UserVoiceMailComponent,
    UserCallTransferComponent,
    UserCallDetailComponent,
    FlexSelectTreeDialog,
    EnterPhoneNumberDialog,
    UserCallRedirectDestDialog,
    MissedCallInfoDialogComponent,
    HandledCallsComponent,
    MissedCallsComponent,
    CallLogDetail,
    CallCenterQualificationDialog,
    PauseAgentDialog,
    ChatbotDialog,
    LanguageSwichingComponent,
    UserProfileDialog,
    ChangeUserPasswordModal,
    UpdatePaymentMethodModal,
    AddCreditCardComponent,
    AddSepaDebitComponent,
    ChooseSubscriptionPacksComponent,
    SubscriptionPacksModal,
    FlexAlertUpgradeComponent,
    PeriodSelectComponent,
    SipphoneConfigSelectComponent,
    MissedCallExpandComponent,
    PhoneAvailableAgentsComponent,
    PhoneBusyAgentsComponent,
    CallInfoModalComponent,
    CreateBlackListModalComponent,
    UserCallReceptionComponent,
    UserCallReceptionDialog,
    FlexSelectCampaignInput,
    LoadingScreenComponent,
    SipTrunkSelectFormInput,
    UserTurnOnMfaComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class WephoneCommonModule {
  static forDashboardSA(): ModuleWithProviders {
    return {
      ngModule: WephoneCommonModule,
      providers: [
        EnterpriseSelectFormInput
      ]
    };
  }

  static forRoot(): ModuleWithProviders<WephoneCommonModule> {
    return {
      ngModule: WephoneCommonModule,
      providers: [
        QualificationService,
        QualificationServiceV2,
        AppConfigService,
        AutoUpdateService,
        CommonCustomIconsService,
        DataBuilderService,
        DialogService,
        EavesdropService,
        SipPhoneService,
        EnterpriseSipGatewayService,
      ]
    };
  }
}
