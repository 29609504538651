import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { AuthenticationService } from '@wephone-core/service/authentication';
import { EnterpriseEntity } from '@wephone-core/model/entity/enterprise';
import { EntityManager } from '@wephone-core/wephone-core.module';
import { EnterpriseRepository } from '@wephone-core/model/repository/enterprise';
import { ToastService, DialogService, EditingComponent, generateApikey, NoWhitespaceValidator } from '@wephone-utils';
// import { TranslateService } from '@ngx-translate/core';
import { _tk, _ti } from '@wephone-translation';
import { UrlValidator } from '@wephone-common/form/inputs/url-input/url-validate';
import * as _ from 'lodash';

@Component({
  selector: 'app-manage-api',
  templateUrl: './manage-api.component.html',
  styleUrls: ['./manage-api.component.scss']
})
export class ManageApiComponent extends EditingComponent implements OnInit {
  form: FormGroup;
  myEnterprise: EnterpriseEntity;

  private _enterpriseRepo: EnterpriseRepository;

  constructor(
    public authService: AuthenticationService,
    private readonly fb: FormBuilder,
    private readonly toast: ToastService,
    private readonly dialogService: DialogService,
    // private readonly translate: TranslateService,
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();

    this._enterpriseRepo = EntityManager.getRepository<EnterpriseRepository>('EnterpriseRepository');
    this.myEnterprise = this._enterpriseRepo.getMyEnterprise();

    const api_webcallback = this.myEnterprise.api_webcallback || {};
    this.form = this.fb.group({
      apikey: [this.myEnterprise.apikey],
      api_ip_whitelist: [this.myEnterprise.api_ip_whitelist, [NoWhitespaceValidator]],
      call_start_url: [api_webcallback.call_start_url, [UrlValidator.isValidUrl]],
      call_end_url: [api_webcallback.call_end_url, [UrlValidator.isValidUrl]]
    });

    this.form.valueChanges.subscribe(() => {
      this.onFormValueChange();
    });
  }

  get apikeyControl(): FormControl {
    return this.form.get('apikey') as FormControl;
  }

  get callStartUrlControl(): FormControl {
    return this.form.get('call_start_url') as FormControl;
  }

  get callEndUrlControl(): FormControl {
    return this.form.get('call_end_url') as FormControl;
  }

  copyToClipboard(val: string): void {
    const selBox = document.createElement('input');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.toast.showSuccess(_ti('clipboard.copied'));
  }

  renewApiKey(): void {
    this.dialogService.confirmDialog(
      _ti('dialogs.confirmation'),
      _ti('manage_integration.confirm_renew_title'),
      () => {
        this.apikeyControl.markAsDirty();
        this.apikeyControl.setValue(generateApikey());
      }
    );
  }

  async updateMyEnterprise(): Promise<any> {
    try {
      if (this.form.invalid) {
        this.form.markAllAsTouched();
        this.toast.showError(_ti('public.message.data_invalid'));
        return;
      }

      const api_webcallback = {
        call_start_url: this.callStartUrlControl.value,
        call_end_url: this.callEndUrlControl.value
      };
      const postData = {
        id: this.myEnterprise.id,
        apikey: this.apikeyControl.value,
        api_ip_whitelist: this.form.get('api_ip_whitelist').value,
        api_webcallback
      };
      const ret = await this._enterpriseRepo.updateEnterprise(postData);
      if (ret) {
        this.toast.showSuccess(_ti('public.message.update_success'));
        this.myEnterprise.setObjectData(ret, true);
        this.resetForm();
      }
    } catch (e) {
      this.formService.handleResponsedError(e, this.form);
      console.error('Update api webcallback error', e);
      this.toast.showErrorMessage(e, _ti('public.message.update_failure'));
    }
  }

  resetForm(): void {
    const api_webcallback: any = this.myEnterprise.api_webcallback || {};
    this.form.reset({
      apikey: this.myEnterprise.apikey,
      api_ip_whitelist: this.myEnterprise.api_ip_whitelist,
      call_start_url: api_webcallback.call_start_url,
      call_end_url: api_webcallback.call_end_url
    });
    this.form.markAsPristine();
  }
}
