
<form [formGroup]="form" *ngIf="form">
    <div fxLayout="column" class="ma-1">
        <div>
            <mat-form-field class="full-width">
                <input matInput [placeholder]="'sipphone_config_template.content.name'|translate" formControlName="name" />
                
                <mat-error [flexFormControlError]="form.get('name')"
                    [customMessages]="{duplicated: 'public.message.name_exist'|translate}"
                    *ngIf="!form.get('name').valid && form.get('name').touched"></mat-error>
            </mat-form-field>
        </div>
    
        <div>
            <mat-form-field class="full-width">
                <sipphone-phone-model-input [enabled]="false"
                    formControlName="model"></sipphone-phone-model-input>

                <mat-error [flexFormControlError]="form.get('model')"
                    *ngIf="!form.get('model').valid && form.get('model').touched"></mat-error>
            </mat-form-field>
        </div>
        
        <div>
            <mat-form-field class="full-width">
                <input readonly matInput [placeholder]="'sipphone_config_template.content.version'|translate" formControlName="version" />

                <mat-error [flexFormControlError]="form.get('version')"
                    *ngIf="!form.get('version').valid && form.get('version').touched"></mat-error>
            </mat-form-field>
        </div>
        
        <div>
            <mat-form-field class="full-width">
                <textarea matInput [placeholder]="'sipphone_config_template.content.content'|translate" formControlName="content" rows="50"></textarea>

                <mat-error [flexFormControlError]="form.get('content')"
                    *ngIf="!form.get('content').valid && form.get('content').touched"></mat-error>
            </mat-form-field>
        </div>
    </div>
  </form>
  