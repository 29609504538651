<form [formGroup]="formGenerateVoice" fxLayout="column" *ngIf="is_ready && formGenerateVoice">
  <div fxLayout="row" fxLayoutAlign="start center">
    <div fxFlex>
      <a class="flex-link" [flexDialogInfo]="{title: 'sound_manager.content.supported_langs'|translate, message: 'sound_manager.content.eleven_labs_supported_langs'|translate}">
        {{'sound_manager.content.supported_langs'|translate}}</a>
    </div>
    <div class="pl-1"></div>
    <div fxFlex>
      <mat-form-field class="full-width">
        <mat-label>{{ 'public.voice' | translate }}</mat-label>
        <flex-select data-ci="voice_name" [placeholder]="'public.voice'| translate" formControlName="voice_name"
          [itemList]="text2speechVoices" [itemTemplate]="itemTemplate"
          [options]="ttsVoiceSelectOptions" (selectionChange)="clearTTSSound()">
          <ng-template #itemTemplate let-item="item">
            {{ item }}
          </ng-template>
        </flex-select>
        <!-- <mat-select
          data-ci="voice_name"
          formControlName="voice_name"
          (selectionChange)="clearTTSSound()"
          [placeholder]="'public.voice' | translate"
        >
            <mat-option
                *ngFor="let text2speechVoice of text2speechVoices"
                [value]="text2speechVoice">{{ text2speechVoice }}
            </mat-option>
        </mat-select> -->

        <mat-error [flexFormControlError]="formGenerateVoice.get('voice_name')"
          [customMessages]="{
            'no_voice': 'sound_manager.message.no_voice'|translate
          }"></mat-error>
      </mat-form-field>
    </div>
  </div>

  <div>
    <mat-form-field class="full-width">
      <mat-label>{{ 'sound_manager.content.text' | translate }}</mat-label>
      <textarea
        data-ci="tts-text"
        matInput
        rows="5"
        formControlName="text"
        [placeholder]="'sound_manager.content.text' | translate"
      ></textarea>

      <mat-error [flexFormControlError]="formGenerateVoice.get('text')"></mat-error>
    </mat-form-field>
  </div>

  <div class="text-right mt-1">
    <button
      data-ci="generate"
      type="button"
      mat-stroked-button
      color="accent"
      (click)="recordByText2speech()"
      [disabled]="!formGenerateVoice.get('text').value || !formGenerateVoice.get('text').value.trim()"
    >
      {{ 'fileentry.action.generate' | translate }}
    </button>
  </div>
</form>

<!--text-to-speech-row-->

<mat-card *ngIf="newFileEntry" class="card-widge sound-file mt-1">
  <div mat-card-widget>
    <span fxFlex>{{ 'sound_manager.content.generated_sound'|translate }}</span>
    <div fxFlex="50" class="pl-1 pr-1">
      <p>
        <fileentry-player-button [filePublicId]="newFileEntry?.public_id" playerId="main"> </fileentry-player-button>
      </p>
    </div>
    <button fxFlex="40px" mat-icon-button class="mat-text-muted" (click)="clearTTSSound()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
</mat-card>
<!--text-to-speech-sound-->
