import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { TTSFileOption, GoogleCloudVoiceParam, Text2SpeechDataService, Text2SpeechLangItem, Text2SpeechLangVoice } from '@wephone-audio/service';
import { FlexBaseComponent } from '@wephone-core-ui';
import { FileEntryEntity, TTSData } from '@wephone-core/model/entity/fileentry';
import { FileEntryRepository } from '@wephone-core/model/repository/fileentry';
import { FileEntryService } from '@wephone-core/service/file_entry_service';
import { EntityManager } from '@wephone-core/wephone-core.module';
import { _ti } from '@wephone-translation';
import * as _ from 'lodash';
import { IFlexSelectOptions, NoWhitespaceValidator, regexSearch } from '@wephone-utils';
import { FileEntryOptions } from '../fileentry.i';
import { LocalManager } from '@wephone-core/service/local_manager';

@Component({
  selector: 'app-tts-google-cloud',
  templateUrl: './tts-google-cloud.component.html',
  styleUrls: ['./tts-google-cloud.component.scss']
})
export class TtsGoogleCloudComponent extends FlexBaseComponent implements OnInit {
  @Input() fileEntry: FileEntryEntity;
  @Input() newFileEntryOptions: FileEntryOptions = {};
  @Output() readonly onChange = new EventEmitter<any>();

  formGenerateVoice: FormGroup;

  // text2speech
  text2speech_lang_list: Text2SpeechLangItem[] = [];
  text2speech_lang_voices: Text2SpeechLangVoice; // Map between lang & voice

  newFileEntry: FileEntryEntity; // New file entry which will be created

  private language: string;
  // langSearch = '';
  ttsLangSelectOptions: IFlexSelectOptions = {
    filterFunc: (item: Text2SpeechLangItem, filterString: string) => {
      return regexSearch(item.label, filterString);
    },
    compareWith: (o1: Text2SpeechLangItem, o2: Text2SpeechLangItem) => {
      return o1 && o2 && o1.value === o2.value;
    }
  };

  private fileEntryRepo = EntityManager.getRepository<FileEntryRepository>('FileEntryRepository');

  constructor(
    private readonly text2SpeechDataService: Text2SpeechDataService,
    private readonly localeManager: LocalManager,
    private readonly fileEntryService: FileEntryService,
    private readonly fb: FormBuilder,
    readonly cdr: ChangeDetectorRef,
  ) {
    super(cdr);
  }

  ngOnInit(): void {
    super.ngOnInit();

  }

  protected async resolveData(): Promise<void> {
    this.language = this.localeManager.getLangForDefaultFileEntry();

    try {
      await this.text2SpeechDataService.listGGCloudVoices();
    } catch (error) {
      console.warn('Cannot get text2speech voices', error);
      // return;
      this.showError(_ti('sound_manager.message.no_voice'));
    }

    this.text2speech_lang_list = this.text2SpeechDataService.text2speech_lang_list || [];
    this.text2speech_lang_voices = this.text2SpeechDataService.text2speech_lang_voices || {};

    let ttsLang: Text2SpeechLangItem = this.text2speech_lang_list.length && this.text2speech_lang_list[0];// && this.text2speech_lang_list[0].value;
    // Use default tts-lang according to enterprise country
    const defaultLangCode = this.text2SpeechDataService.getDefaultTTSLocaleForEnterprise();
    if (defaultLangCode) {
      const defaultTtsLang = this.text2speech_lang_list.find(x => _.startsWith(x.value.toLowerCase(), defaultLangCode.toLowerCase())); // 'en-GB', 'en-GB'
      if (defaultTtsLang) {
        ttsLang == defaultTtsLang;
        // ttsLang = defaultTtsLang.value;
      }
    }

    let ttsText: string;
    let ttsVoice: GoogleCloudVoiceParam;

    if (this.fileEntry && this.fileEntry.tts_data) {
      const ttsData: TTSData = this.fileEntry.tts_data;
      ttsText = ttsData.text;
      const lang: string = ttsData.lang;
      const voice: string = ttsData.voice;

      // ttsLang = lang;
      ttsLang = this.text2speech_lang_list.find(x => x.value === lang);
      if (!this.text2speech_lang_voices[lang]) {
        console.error(`Not support lang  ${lang} for text2speech voices`);
      } else {
        const v = this.text2speech_lang_voices[lang].find(x => x.gender === voice);
        if (v) {
          ttsVoice = v.value;
        }
      }
    }

    this.formGenerateVoice = this.fb.group({
      text: [ttsText, [Validators.required, Validators.maxLength(900), NoWhitespaceValidator]],
      lang: [ttsLang, [Validators.required]],
      voice: [ttsVoice, [Validators.required]]
    });

    // Init TTS lang & voice
    if (!ttsVoice) {
      this.selectDefaultVoice();
    }

    this.addSubscription(
      this.formGenerateVoice.get('text').valueChanges.subscribe(() => {
        this.clearTTSSound();
      })
    );

    this.detectChanges();
  }

  clearTTSSound(): void {
    this.newFileEntry = undefined;
    this.detectChanges();
  }

  // get langList(): Text2SpeechLangItem[] {
  //   if (this.langSearch) {
  //     return this.text2speech_lang_list.filter(x => regexSearch(x.label, this.langSearch));
  //   }
  //   return this.text2speech_lang_list || [];
  // }

  changeText2speechLang(): void {
    this.clearTTSSound();
    // set default value for voice
    if (!this.formGenerateVoice.get('lang').value) {
      console.error('No lang selected');
      return;
    }
    this.selectDefaultVoice();
    this.detectChanges();
  }

  private selectDefaultVoice(): void {
    const text2speech_lang: string = this.formGenerateVoice.get('lang').value ? this.formGenerateVoice.get('lang').value.value : undefined;

    if (!text2speech_lang) {
      console.warn('No lang selected');
      return;
    }

    this.formGenerateVoice.get('voice').setValue(this.text2speech_lang_voices[text2speech_lang] &&
      this.text2speech_lang_voices[text2speech_lang][0] &&
      this.text2speech_lang_voices[text2speech_lang][0].value);
  }

  async recordByText2speech(): Promise<void> {
    const formValid: boolean = this.formGenerateVoice.valid;

    if (!formValid) {
      this.formGenerateVoice.markAllAsTouched();

      this.detectChanges();
      this.showError(_ti('public.message.data_invalid'));
      return;
    }

    try {
      const res = await this.text2SpeechDataService.recordText2SpeechGGCloud(
        this.formGenerateVoice.get('text').value, this.soundOptions, this.formGenerateVoice.get('voice').value
      );
      this.fileEntryRepo.setDataAsReady();
      this.setNewFileEntry(this.fileEntryRepo.getObjectById(res.id));

      this.onChange.emit({ new_file_entry: this.newFileEntry });
    } catch (error) {
      console.error('Record text to speech failed', error);
      this.toastService.showErrorMessage(error, _ti('fileentry.error.unknown'));
    }
  }

  get soundOptions(): TTSFileOption {
    const soundOptions: TTSFileOption = {
      name: this.newFileEntryOptions.name,
      create_temp_file: this.newFileEntryOptions.is_temp ? 1 : 0
    };

    if (this.newFileEntryOptions.file_entry_id) {
      soundOptions.file_entry_id = this.newFileEntryOptions.file_entry_id;
    }

    return soundOptions;
  }

  private setNewFileEntry(fileentry: FileEntryEntity): void {
    this.newFileEntry = fileentry;
    if (!this.newFileEntry) {
      console.error('No file entry responsed');
      return;
    }
    this.newFileEntry.setRemoteUrl(
      this.fileEntryService.getFileUrl(this.newFileEntry.public_id, false, fileentry.is_default ? this.language : null)
    );

    this.detectChanges();
  }
}
