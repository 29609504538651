import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import {
  ToastService, DialogActionButton, Colors, IFlexDialogConfig,
  NoWhitespaceValidator
} from '@wephone-utils';
import * as _ from 'lodash';
import { DialogComponentBase } from '@wephone-core-ui';
import { _tk, _ti } from '@wephone-translation';
import { SipTrunkRepository } from '@wephone-core/model/repository/siptrunk';
import { PhoneNumberOnlyDigitValidated } from '@wephone/services/form-validator';
import { MatDialogRef } from '@angular/material/dialog';
import { FlexIvrSettings } from '@wephone-core/wephone-core.module';
import { SipTrunkService } from '@wephone/services/siptrunk.service';

@Component({
  selector: 'app-create-siptrunk-modal',
  templateUrl: './create-siptrunk-modal.component.html',
  styleUrls: ['./create-siptrunk-modal.component.scss']
})
export class CreateSiptrunkModalComponent extends DialogComponentBase implements OnInit {
  static modalConfig: IFlexDialogConfig = {
    size: 'l',
    width: '600px',
    minHeight: '90%',
  };

  dialogTitle = _tk('siptrunk.title.create');
  dialogRightActions: DialogActionButton[] = [
    {
      label: _tk('public.apply'),
      action: () => {
        this.submitForm();
      },
      visible: () => {
        return true;
      },
      color: Colors.PRIMARY
    }
  ];

  _cancelButton: DialogActionButton = {
    label: _tk('public.button.close'),
    action: () => {
      this.dismiss();
    }
  };

  private readonly sipTrunkRepo = SipTrunkRepository.getInstance<SipTrunkRepository>();
  readonly uiSipTrunk = FlexIvrSettings.getInstance().uiSipTrunk();

  form: FormGroup;
  isExistDefaultSipTrunk = false;
  hidePassword = true;

  constructor(
    dialogRef: MatDialogRef<CreateSiptrunkModalComponent>,
    private readonly fb: FormBuilder,
    private readonly toast: ToastService,
    private readonly siptrunkService: SipTrunkService
  ) {
    super(dialogRef);
  }

  async ngOnInit(): Promise<void> {
    await super.ngOnInit();

    this.isExistDefaultSipTrunk = !!this.sipTrunkRepo.getObjectList().find(sipTrunk => !!sipTrunk.is_default);
    this.initFormGroup();
  }

  protected async resolveData(): Promise<void> {
    await this.sipTrunkRepo.findAll();
  }

  initFormGroup(): void {
    this.form = this.fb.group({
      name: [undefined, [Validators.required, Validators.maxLength(255)]],
      username: [undefined, [Validators.required, Validators.maxLength(64)]],
      password: [undefined, [Validators.required, Validators.minLength(8), Validators.maxLength(64)]],
      proxy_host: [undefined, [Validators.maxLength(255)]],
      proxy_port: [undefined, [Validators.min(0), Validators.max(65535)]],
      secondary_proxy_host: [undefined, [Validators.maxLength(255)]],
      secondary_proxy_port: [undefined, [Validators.min(0), Validators.max(65535)]],
      secondary_proxy_mode: [0],
      outgoing_enabled: [],
      alias: [undefined, [Validators.maxLength(255)]],
      backup_number: [undefined],
      backup_number_enable: [],
      sip_ping_enable: [],
      channel_max: [undefined, [Validators.min(1)]],
      is_default: [this.uiSipTrunk && !this.isExistDefaultSipTrunk ? true : false],
    });

    this.setBackupNumberValidation();

    this.addSubscription(
      this.form.get('backup_number_enable').valueChanges.subscribe((isEnabled: boolean) => {
        this.setBackupNumberValidation();
        if (isEnabled) {
          this.setControlValue('sip_ping_enable', true)

          this.form.get('backup_number').markAsDirty();
        }
      })
    );

    this.addSubscription(
      this.form.get('sip_ping_enable').valueChanges.subscribe((isEnabled: boolean) => {
        if (!isEnabled) {
          this.setControlValue('backup_number_enable', false);
        }
      })
    );

    this.addSubscription(
      this.form.get('backup_number').valueChanges.subscribe(() => {
        this.form.get('backup_number_enable').markAsDirty();
      })
    );
  }

  private setControlValue(controlName: string, value: any): void {
    this.form.get(controlName).markAsDirty();
    this.form.get(controlName).setValue(value);
  }

  private getFormData(): Record<string, any> {
    const data: Record<string, any> = this.form.value;
    return this.siptrunkService.transformData(data);
  }

  private setBackupNumberValidation(): void {
    this.form.get('backup_number').setErrors(null);
    this.form.get('backup_number').clearValidators();

    const validators = [Validators.maxLength(20), PhoneNumberOnlyDigitValidated(), NoWhitespaceValidator];
    if (this.form.get('backup_number_enable').value) {
      validators.push(Validators.required);
    }

    this.form.get('backup_number').addValidators(validators);
  }

  private isFormValid(): boolean {
    return this.form && this.form.valid;
  }

  async submitForm(): Promise<void> {
    if (!this.isFormValid()) {
      this.form.markAllAsTouched();
      this.toast.showError(_ti('form.validator.data_invalid'));
      return;
    }

    try {
      const formDataUpdated = this.getFormData();
      const createdEntity = await this.sipTrunkRepo.createAndSave(formDataUpdated);
      this.toast.showSuccess(_ti('public.message.create_success'));
      this.dismiss(createdEntity, false);
    } catch (error) {
      console.error('Create sip-trunk error', error);
      this.toast.showErrorMessage(error, _ti('public.message.create_failure'));
    }
  }

  clearValue(event: MouseEvent, controlName: string): void {
    event.stopPropagation();
    this.setControlValue(controlName, null)
  }
}
