import * as _ from 'lodash';

import { Injectable } from '@angular/core';
import { _ti } from '@wephone-translation';
import { DidEntity } from '@wephone-core/model/entity/did';
import { Deferred } from 'ts-deferred';
import { DialogService } from '@wephone-utils';
import { SipTrunkEntity } from '@wephone-core/model/entity/siptrunk';

@Injectable()
export class SipTrunkService {
  // Constructor
  constructor(
    private dialogService: DialogService,
  ) { }

  async getConfirmDeleteSipTrunks(sipTrunks: SipTrunkEntity[]): Promise<boolean> {
    let msg: string = _ti('user.title.delete');
    const routedDids: DidEntity[] = [];
    const msgs: string[] = [];

    for (const sipTrunk of sipTrunks) {
      for (const routedDid of sipTrunk.routedDids) {
        if (!_.includes(routedDids, routedDid)) {
          routedDids.push(routedDid);
        }
      }
    }

    if (routedDids.length) {
      let sipTrunkObjects: SipTrunkEntity[] = sipTrunks.filter(i => i.routedDids.length);
      sipTrunkObjects = _.uniqBy(sipTrunkObjects, i => i.id);

      msgs.push(_ti('did.message.confirm_delete_with_dependent_did', {
        dependent_numbers: routedDids.map(x => x.display_number).join(', '),
        objects: sipTrunkObjects.map(x => x.name).join(', '),
      }));
    }

    const ret = new Deferred<boolean>();
    const canDelete = !msgs.length;

    if (!canDelete) {
      msgs.push(_ti('public.message.cannot_continue_action_with_dependent_items'));
      msg = msgs.join('\n');

      await this.dialogService.showAlert(_ti('dialogs.warning'), msg);

      ret.resolve(canDelete);
      return ret.promise;
    }

    await this.dialogService.confirmDialog(
      _ti('dialogs.confirmation'),
      msg,
      () => {
        ret.resolve(canDelete);
      }
    );
    return ret.promise;
  }

  transformData(formData: any): any {
    const data: any = {};
    const attrList = Object.keys(formData);
    for (const attr of attrList) {
      switch (attr) {
        case 'outgoing_enabled':
        case 'sip_ping_enable':
        case 'is_default':
        case 'backup_number_enable':
          data[attr] = formData[attr] ? 1 : 0;
          break;
        default:
          // If backup_number_enable is false, skip update backup_number
          if (attr === 'backup_number' && attrList.find(f => f === 'backup_number_enable') && !formData.backup_number_enable) {
            break;
          }

          data[attr] = formData[attr];
          break;
      }
    }
    return data;
  }
}
