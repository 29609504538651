<div>
  <form [formGroup]="form" (ngSubmit)="submitForm()">
    <div align="end" *ngIf="uiSipTrunk && isExistDefaultSipTrunk">
      <mat-slide-toggle formControlName="is_default" color="primary">
        <span class="fontfamily-bold"> {{ 'siptrunk.form.set_as_default' | translate }}</span>
      </mat-slide-toggle>
    </div>

    <div>
      <mat-form-field class="full-width">
        <mat-label>{{'siptrunk.form.name'|translate}}</mat-label>
        <input matInput formControlName="name" data-ci="name" [placeholder]="'siptrunk.form.name'|translate">

        <mat-error [flexFormControlError]="form.get('name')"></mat-error>
      </mat-form-field>
    </div>

    <div fxLayout="row" fxLayoutAlign="start center" fxFlexFill fxLayoutGap="10px">
      <div fxFlex="50">
        <mat-form-field class="full-width">
          <mat-label>{{'siptrunk.form.username'|translate}}</mat-label>
          <input matInput formControlName="username" data-ci="username"
            [placeholder]="'siptrunk.form.username'|translate">

          <mat-error [flexFormControlError]="form.get('username')"></mat-error>
        </mat-form-field>
      </div>
      <div fxFlex="50">
        <mat-form-field class="full-width">
          <mat-label>{{'siptrunk.form.password'|translate}}</mat-label>
          <input matInput formControlName="password" [type]="hidePassword ? 'password' : 'text'"
            [placeholder]="'siptrunk.form.password'|translate" data-ci="password">
          <button matSuffix type="button" mat-icon-button (click)="hidePassword = !hidePassword"
            title="{{ (hidePassword ? 'public.show_pw' : 'public.hide_pw')|translate }}">
            <mat-icon>{{ hidePassword ? 'visibility_off' : 'visibility' }}</mat-icon>
          </button>
          <!-- <button type="button" mat-icon-button matSuffix (click)="generatePassword()" data-ci="generate-password"
            [matTooltip]="'siptrunk.form.generate_password'|translate">
            <mat-icon>key</mat-icon>
          </button> -->

          <mat-error [flexFormControlError]="form.get('password')"></mat-error>
        </mat-form-field>
      </div>
    </div>

    <div fxLayout.xs="column" fxLayout="row" fxLayoutAlign.gt-xs="start center" fxFlexFill fxLayoutGap.xs="0" fxLayoutGap="10px">
      <div fxFlex>
        <mat-slide-toggle formControlName="backup_number_enable" color="primary">
          <span class="fontfamily-bold"> {{ 'siptrunk.form.backup_number_enable' | translate }}</span>
        </mat-slide-toggle>
      </div>
      <div fxFlex>
        <mat-slide-toggle formControlName="sip_ping_enable" color="primary">
          <span class="fontfamily-bold"> {{ 'siptrunk.form.sip_ping_enable' | translate }}</span>
        </mat-slide-toggle>
      </div>
    </div>

    <div *ngIf="form.get('backup_number_enable').value">
      <mat-form-field class="full-width">
        <mat-label>{{'siptrunk.form.backup_number'|translate}}</mat-label>
        <input matInput formControlName="backup_number" [placeholder]="'siptrunk.form.backup_number'|translate"
          data-ci="backup_number">
        <button type="button" mat-icon-button matSuffix data-ci="clear-backup-number"
          (click)="clearValue($event, 'backup_number')" [disabled]="!form.get('backup_number').value">
          <mat-icon>clear</mat-icon>
        </button>

        <mat-error [flexFormControlError]="form.get('backup_number')"
          *ngIf="form.get('backup_number').invalid && form.get('backup_number').touched"></mat-error>
      </mat-form-field>
    </div>

    <h2 class="pt-2">{{ 'siptrunk.form.primary_proxy'|translate }}</h2>
    <div fxLayout="row" fxLayoutAlign="start center" fxFlexFill fxLayoutGap="10px">
      <div fxFlex="50">
        <mat-form-field class="full-width">
          <mat-label>{{'siptrunk.form.host'|translate}}</mat-label>
          <input matInput formControlName="proxy_host" [placeholder]="'siptrunk.form.host'|translate">

          <mat-error [flexFormControlError]="form.get('proxy_host')"></mat-error>
        </mat-form-field>
      </div>
      <div fxFlex="50">
        <mat-form-field class="full-width">
          <mat-label>{{'siptrunk.form.port'|translate}}</mat-label>
          <input matInput placeholder="{{'siptrunk.form.port'|translate}}" formControlName="proxy_port" type="number">

          <mat-error [flexFormControlError]="form.get('proxy_port')"></mat-error>
        </mat-form-field>
      </div>
    </div>

    <h2 class="pt-2">{{ 'siptrunk.form.secondary_proxy'|translate }}</h2>
    <div>
      <mat-form-field class="full-width">
        <mat-label>{{'siptrunk.form.mode'|translate}}</mat-label>
        <mat-select formControlName="secondary_proxy_mode">
          <mat-option [value]="0">{{'siptrunk.form.disabled'|translate}}</mat-option>
          <mat-option [value]="1">{{'siptrunk.form.load_balancing'|translate}}</mat-option>
          <mat-option [value]="2">{{'siptrunk.form.fail_over'|translate}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxFlexFill fxLayoutGap="10px"
      *ngIf="form.get('secondary_proxy_mode').value != 0">
      <div fxFlex="50">
        <mat-form-field class="full-width">
          <mat-label>{{'siptrunk.form.host'|translate}}</mat-label>
          <input matInput formControlName="secondary_proxy_host" [placeholder]="'siptrunk.form.host'|translate">

          <mat-error [flexFormControlError]="form.get('secondary_proxy_host')"></mat-error>
        </mat-form-field>
      </div>
      <div fxFlex="50">
        <mat-form-field class="full-width">
          <mat-label>{{'siptrunk.form.port'|translate}}</mat-label>
          <input matInput formControlName="secondary_proxy_port" type="number" min="10"
            [placeholder]="'siptrunk.form.port'|translate">

          <mat-error [flexFormControlError]="form.get('secondary_proxy_port')"></mat-error>
        </mat-form-field>
      </div>
    </div>
    <div>
      <mat-checkbox formControlName="outgoing_enabled">
        {{'siptrunk.form.outgoing_enabled'|translate}}
      </mat-checkbox>
    </div>
    <div>
      <mat-form-field class="full-width">
        <mat-label>{{'siptrunk.form.alias'|translate}}</mat-label>
        <input matInput formControlName="alias" [placeholder]="'siptrunk.form.alias'|translate">

        <mat-error [flexFormControlError]="form.get('alias')"></mat-error>
      </mat-form-field>
    </div>
    <div>
      <mat-form-field class="full-width">
        <mat-label>{{'siptrunk.form.channel_max'|translate}}</mat-label>
        <input type="number" matInput formControlName="channel_max" [min]="0"
          [placeholder]="'siptrunk.form.channel_max'|translate" data-ci="channel_max">
        <button type="button" mat-icon-button matSuffix data-ci="clear-channel-max"
          (click)="clearValue($event, 'channel_max')" [disabled]="!form.get('channel_max').value">
          <mat-icon>clear</mat-icon>
        </button>

        <mat-error [flexFormControlError]="form.get('channel_max')"></mat-error>
      </mat-form-field>
    </div>
  </form>
</div>
