import { datafield, Entity } from '@wephone-core/model/model';
import { IEnterpriseObjectTemplate, IEnterpriseObjectTemplateContent } from './enterprise_object_template.i';
import { EnterpriseEntity } from './enterprise';
// import { EntityManager } from '@wephone-core/wephone-core.module';
import { EnterpriseRepository } from '../repository/enterprise';

export class EnterpriseObjectTemplateEntity extends Entity implements IEnterpriseObjectTemplate {
  static object_type_id = 'enterprise_object_template';

  @datafield
  id: number;

  @datafield
  enterprise_id: number;

  @datafield
  template_name: string;

  @datafield
  template_content: string;

  @datafield
  template_content_object: IEnterpriseObjectTemplateContent;

  get template_variables(): Record<string, string> {
    return this.template_content_object && this.template_content_object.variables || {};
  }

  // get template_configs(): Record<string, string | boolean | number> {
  //   return this.template_content_object && this.template_content_object.configuration || {};
  // }

  get enterprise(): EnterpriseEntity {
    return this.enterprise_id ? EnterpriseRepository.getInstance().getObjectById(this.enterprise_id) : undefined;
  }
}
