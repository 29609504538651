<div class="pa-1" *ngIf="form">
  <div fxLayout="row" fxLayoutAlign="center center"
    *ngIf="enterpriseCrm && enterpriseCrm.auth_method === 'oauth' && !enterpriseCrm.is_authenticated">
    <button mat-stroked-button color="primary" (click)="addOauthAccount()">
      <mat-icon>vpn_key</mat-icon>
      {{'enterprise_crm.message.click_add_your_account'|translate}}
    </button>
  </div>

  <div fxLayout="column" fxLayoutAlign="center center"
    *ngIf="enterpriseCrm && enterpriseCrm.is_authenticated && enterpriseCrm.isOracleHospitality">

    <!-- <button mat-stroked-button color="primary" (click)="synchronizeHotelRoom()">
      <mat-icon>sync</mat-icon>
      {{'enterprise_crm.content.synchronize_hotel_room'|translate}}
    </button> -->

    <div *ngIf="crmSyncRoom" fxLayout="column" class="text-center">
      <span *ngIf="crmSyncRoom.is_not_processed">
        {{ 'enterprise_crm.message.sync_task.synchronize_sent'|translate }}
      </span>
      <span *ngIf="crmSyncRoom.is_running">
        {{ 'enterprise_crm.message.sync_task.synchronize_inprogress'|translate }}
      </span>
      <span *ngIf="crmSyncRoom.is_success">
        {{ 'enterprise_crm.message.sync_task.synchronize_success'|translate }}
      </span>
      <div *ngIf="crmSyncRoom.is_failed">
        {{ 'enterprise_crm.message.sync_task.synchronize_failed'|translate }} &nbsp;
        <span class="mat-error">
          {{ crmSyncRoom.last_error_msg }}
        </span>
      </div>
      <div *ngIf="crmSyncRoom.last_run_dt">{{ 'enterprise_crm.content.last_sync_date'|translate }}: {{
        crmSyncRoom.last_run_dt|date:'yyyy-MM-dd' }}</div>
    </div>

  </div>

  <form [formGroup]="form">
    <ng-container *ngIf="!enterpriseCrm || enterpriseCrm.type !=='customcrm'; else tplCustomCrm">
      <ng-container
        *ngIf="enterpriseCrm && !enterpriseCrm.is_authenticated && isType(['zendesk', 'shopify', 'dynamics'])">
        <mat-form-field class="full-width">
          <mat-label>{{'enterprise_crm.content.url'|translate}}</mat-label>
          <input matInput #urlInput placeholder="{{'enterprise_crm.content.url'|translate}}" formControlName="url">
          <mat-hint align="start">
            <strong>Example: https://smartcontact.{{enterpriseCrm.type}}.com</strong>
          </mat-hint>
          
          <mat-error [flexFormControlError]="form.get('url')"></mat-error>
        </mat-form-field>
      </ng-container>

      <ng-container *ngIf="(enterpriseCrm.auth_method !== 'oauth' || enterpriseCrm.is_authenticated)">
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
          <div fxFlex="initial">
            <img *ngIf="type" src="assets/images/{{ type.logo }}">
          </div>
          <div fxFlex="auto" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
            <div fxFlex="initial" *ngIf="syncState == 'in_progress'">
              <mat-spinner diameter="20"></mat-spinner>
            </div>

            <div fxFlex>
              <h3 class="margin-none">
                <ng-container *ngIf="enterpriseCrm.is_authenticated;else NotAuthenticated">
                  <span class="mat-text-primary">{{ 'enterprise_crm.content.authenticated'|translate }}</span>

                  <div fxLayout="row" fxLayoutAlign="start center">
                    {{ 'enterprise_crm.content.synchronization_state'|translate }}:&nbsp;
                    <ng-container *ngIf="(syncState == 'waiting' && enterpriseCrm.last_error_msg); else stateBlock">
                      <span class="mat-text-warn">{{enterpriseCrm.last_error_msg}}</span>
                    </ng-container>

                    <ng-template #stateBlock>
                      <span class="margin-none mat-text-primary" [ngClass]="{'mat-text-warn': syncState == 'error'}">
                        {{ syncMessage | translate }}
                      </span>
                    </ng-template>

                    <div *ngIf="enterpriseCrm.sync_state === 2">
                      <button mat-icon-button (click)="retrySync()"
                        [matTooltip]="'crm.action.retry_sync'|translate">
                        <mat-icon>sync_alt</mat-icon>
                      </button>
                    </div>
                  </div>
                </ng-container>

                <ng-template #NotAuthenticated>
                  <span class="mat-text-warn">{{ 'enterprise_crm.content.not_authenticated'|translate }}</span>
                </ng-template>
              </h3>
            </div>
          </div>
          <div fxFlex="initial">
            <div fxFlex>
              <mat-slide-toggle data-ci="crm-change-active" [checked]="isActive" (change)="changeActive($event)">
                {{'enterprise_crm.content.is_default'|translate}}
              </mat-slide-toggle>
            </div>
          </div>
        </div>

        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" class="mt-1">
          <div fxFlex>
            <mat-form-field class="full-width">
              <mat-label>{{'enterprise_crm.content.name'|translate}}</mat-label>
              <input matInput [placeholder]="'enterprise_crm.content.name'|translate" formControlName="name">
              
              <mat-error [flexFormControlError]="form.get('name')"></mat-error>
            </mat-form-field>
          </div>

          <div fxFlex>
            <ng-container *ngIf="isType(['oraclehospitality', 'zendesk', 'freshdesk', 'vtiger', 'dynamics'])">
              <mat-form-field class="full-width">
                <mat-label>{{'enterprise_crm.content.url'|translate}}</mat-label>
                <input matInput placeholder="{{'enterprise_crm.content.url'|translate}}" formControlName="url">
                <mat-hint align="start">
                  <strong>Example: https://smartcontact.{{enterpriseCrm.type}}.com</strong>
                </mat-hint>
                
                <mat-error [flexFormControlError]="form.get('url')"></mat-error>
              </mat-form-field>
            </ng-container>
          </div>
        </div>

        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
          <div fxFlex>
            <ng-container *ngIf="authMethod ==='apikey' && !isType(['freshdesk'])">
              <mat-form-field class="full-width">
                <mat-label>{{'enterprise_crm.content.username' | translate}}</mat-label>
                <input matInput placeholder="{{'enterprise_crm.content.username' | translate}}"
                  formControlName="username" [errorStateMatcher]="matcher">
                  
                <mat-error [flexFormControlError]="form.get('username')"></mat-error>
              </mat-form-field>
            </ng-container>
          </div>
          <div fxFlex>
            <ng-container *ngIf="isType(['oraclehospitality'])">
              <mat-form-field class="full-width">
                <mat-label>{{'enterprise_crm.content.password' | translate}}</mat-label>
                <input type="password" matInput placeholder="{{'enterprise_crm.content.password' | translate}}"
                  formControlName="password" [errorStateMatcher]="matcher">
                  
                <mat-error [flexFormControlError]="form.get('password')"></mat-error>
              </mat-form-field>
            </ng-container>
          </div>
        </div>

        <div fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="10px">
          <div class="full-width" fxFlex *ngIf="authMethod == 'apikey' && !isType(['oraclehospitality'])">
            <mat-form-field class="full-width">
              <mat-label>{{'enterprise_crm.content.apikey' | translate}}</mat-label>
              <input matInput type="text" placeholder="{{'enterprise_crm.content.apikey'|translate}}"
                formControlName="apikey" [errorStateMatcher]="matcher">
                
              <mat-error [flexFormControlError]="form.get('apikey')"></mat-error>
            </mat-form-field>
          </div>

          <ng-container *ngIf="isType(['oraclehospitality'])">
            <div class="full-width" fxLayout="row">
              <div fxFlex>
                <mat-form-field class="full-width">
                  <mat-label>{{'enterprise_crm.oracle_hospitality.client_id' | translate}}</mat-label>
                  
                  <input matInput type="text" placeholder="{{'enterprise_crm.oracle_hospitality.client_id'|translate}}"
                    formControlName="client_id" [errorStateMatcher]="matcher">
                  
                  <mat-error [flexFormControlError]="form.get('client_id')"></mat-error>
                </mat-form-field>
              </div>

              <div fxFlex="10px"></div>

              <div fxFlex>
                <mat-form-field class="full-width">
                  <mat-label>{{'enterprise_crm.oracle_hospitality.client_secret' | translate}}</mat-label>
                  
                  <input matInput type="text" placeholder="{{'enterprise_crm.oracle_hospitality.client_secret'|translate}}"
                    formControlName="client_secret" [errorStateMatcher]="matcher">
                  
                  <mat-error [flexFormControlError]="form.get('client_secret')"></mat-error>
                </mat-form-field>
              </div>
            </div>

            <div class="full-width">
              <mat-form-field class="full-width">
                <mat-label>{{'enterprise_crm.oracle_hospitality.hotel_id' | translate}}</mat-label>
                <input matInput type="text" placeholder="{{'enterprise_crm.oracle_hospitality.hotel_id'|translate}}"
                  formControlName="hotel_id" [errorStateMatcher]="matcher">

                <mat-error [flexFormControlError]="form.get('hotel_id')"></mat-error>
              </mat-form-field>
            </div>

            <div class="full-width" fxLayout="row">
              <div fxFlex>
                <mat-form-field class="full-width">
                  <mat-label>{{'enterprise_crm.oracle_hospitality.cashier_id' | translate}}</mat-label>
                  
                  <mat-select placeholder="{{ 'enterprise_crm.oracle_hospitality.cashier_id' | translate }}" formControlName="cashier_id"
                    [disabled]="!enterpriseCrm.is_authenticated" [errorStateMatcher]="ohMatcher">
                    <mat-option *ngFor="let item of ohCashiers" [value]="item.cashierId">{{ item.name }}</mat-option>
                  </mat-select>
  
                  <mat-hint>{{ 'enterprise_crm.message.opera_cloud_hint_charge_phone_call'|translate }}</mat-hint>

                  <mat-error *ngIf="errors.cashier_id && form.get('cashier_id').touched">
                    {{'enterprise_crm.message.oracle_hospitality.get_cashiers_failed'|translate}}
                  </mat-error>
                </mat-form-field>
              </div>

              <div fxFlex="10px"></div>

              <div fxFlex>
                <mat-form-field class="full-width">
                  <mat-label>{{'enterprise_crm.oracle_hospitality.transaction_code' | translate}}</mat-label>
                  
                  <mat-select placeholder="{{ 'enterprise_crm.oracle_hospitality.transaction_code' | translate }}" formControlName="transaction_code"
                    [disabled]="!enterpriseCrm.is_authenticated" [errorStateMatcher]="ohMatcher">
                    <mat-option *ngFor="let item of ohTransactionCodes" [value]="item.transactionCode">{{ item.description }}</mat-option>
                  </mat-select>

                  <mat-hint>{{ 'enterprise_crm.message.opera_cloud_hint_charge_phone_call'|translate }}</mat-hint>
                  
                  <mat-error *ngIf="errors.transaction_code && form.get('transaction_code').touched">
                    {{'enterprise_crm.message.oracle_hospitality.get_transactions_failed'|translate}}
                  </mat-error>
                </mat-form-field>
              </div>
            </div>

          </ng-container>
        </div>

        <div *ngIf="isType(['helpscout'])" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
          <div fxFlex="50">
            <mat-form-field class="full-width">
              <mat-label>{{'enterprise_crm.content.mailbox'|translate}}</mat-label>
              <mat-select placeholder="{{'enterprise_crm.content.mailbox'|translate}}" formControlName="mailbox_id">
                <mat-option *ngFor="let mailbox of mailboxes" [value]="mailbox.id">
                  {{mailbox.email}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div fxFlex="auto">
            <button type="button" mat-icon-button color="primary" matSuffix (click)="reloadMailboxes()"
              data-ci="reload-mailboxes">
              <span class="inline-flex">
                <mat-icon>sync</mat-icon>
              </span>
            </button>
          </div>
        </div>

        <div *ngIf="logToList.length && !isType(['oraclehospitality'])" fxLayout="row" fxLayoutAlign="start center"
          fxLayoutGap="10px">
          <div fxFlex="50">
            <mat-form-field class="full-width" data-ci="log-call-to">
              <mat-label>{{'enterprise_crm.content.log_call_to'|translate}}</mat-label>
              <mat-select placeholder="{{'enterprise_crm.content.log_call_to'|translate}}"
                formControlName="log_call_to">
                <mat-option *ngFor="let logTo of logToList" [value]="logTo.value">
                  {{logTo.label|translate}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>

        <div *ngIf="type && type.log_state && !isType(['oraclehospitality'])" fxLayout="row"
          fxLayoutAlign="start center" fxLayoutGap="10px">
          <div fxFlex>
            <mat-form-field class="full-width" data-ci="log-state-outbound">
              <mat-label>{{'enterprise_crm.content.log_state_outbound'|translate}}</mat-label>
              <mat-select placeholder="{{'enterprise_crm.content.log_state_outbound'|translate}}"
                formControlName="log_state_outbound">
                <mat-option *ngFor="let state of callLogStateList" [value]="state.value">
                  {{state.label|translate}}
                </mat-option>
              </mat-select>
              <button mat-icon-button matSuffix type="button" data-ci="remove-log-state-outbound"
                *ngIf="!isType(['vtiger'])"
                (click)="removeLogState($event, 'log_state_outbound')" [disabled]="form && !form.get('log_state_outbound').value">
                <mat-icon>close</mat-icon>
              </button>
              
              <mat-error [flexFormControlError]="form.get('log_state_outbound')"></mat-error>
            </mat-form-field>
          </div>

          <div fxFlex>
            <mat-form-field class="full-width" [hidden]="!form || form && form.get('log_state_outbound').value !== 4">
              <mat-label>{{'enterprise_crm.content.log_state_outbound_text'|translate}}</mat-label>
              <input matInput type="text" formControlName="log_state_outbound_text" />
              
              <mat-error [flexFormControlError]="form.get('log_state_outbound_text')"></mat-error>
            </mat-form-field>
          </div>
        </div>

        <div *ngIf="type && type.log_state && !isType(['oraclehospitality'])" fxLayout="row"
          fxLayoutAlign="start center" fxLayoutGap="10px">
          <div fxFlex>
            <mat-form-field class="full-width" data-ci="log-state-inbound">
              <mat-label>{{'enterprise_crm.content.log_state_inbound'|translate}}</mat-label>
              <mat-select placeholder="{{'enterprise_crm.content.log_state_inbound'|translate}}"
                formControlName="log_state_inbound">
                <mat-option *ngFor="let state of callLogStateList" [value]="state.value">
                  {{state.label|translate}}
                </mat-option>
              </mat-select>
              <button mat-icon-button matSuffix type="button" data-ci="remove-log-state-inbound"
                *ngIf="!isType(['vtiger'])"
                (click)="removeLogState($event, 'log_state_inbound')" [disabled]="form && !form.get('log_state_inbound').value">
                <mat-icon>close</mat-icon>
              </button>
              
              <mat-error [flexFormControlError]="form.get('log_state_inbound')"></mat-error>
            </mat-form-field>
          </div>

          <div fxFlex>
            <mat-form-field class="full-width" [hidden]="!form || form && form.get('log_state_inbound').value !== 4">
              <mat-label>{{'enterprise_crm.content.log_state_inbound_text'|translate}}</mat-label>
              <input matInput type="text" formControlName="log_state_inbound_text" />
              
              <mat-error [flexFormControlError]="form.get('log_state_inbound_text')"></mat-error>
            </mat-form-field>
          </div>
        </div>

        <div *ngIf="type && type.log_state && !isType(['oraclehospitality'])" fxLayout="row"
          fxLayoutAlign="start center" fxLayoutGap="10px">
          <div fxFlex>
            <mat-form-field class="full-width" data-ci="log-state-missed">
              <mat-label>{{'enterprise_crm.content.log_state_missed'|translate}}</mat-label>
              <mat-select placeholder="{{'enterprise_crm.content.log_state_missed'|translate}}"
                formControlName="log_state_missed">
                <mat-option *ngFor="let state of callLogStateList" [value]="state.value">
                  {{state.label|translate}}
                </mat-option>
              </mat-select>
              <button mat-icon-button matSuffix type="button" data-ci="remove-log-state-missed"
                *ngIf="!isType(['vtiger'])"
                (click)="removeLogState($event, 'log_state_missed')" [disabled]="form && !form.get('log_state_missed').value">
                <mat-icon>close</mat-icon>
              </button>
              
              <mat-error [flexFormControlError]="form.get('log_state_missed')"></mat-error>
            </mat-form-field>
          </div>

          <div fxFlex>
            <mat-form-field class="full-width" [hidden]="!form || form && form.get('log_state_missed').value !== 4">
              <mat-label>{{'enterprise_crm.content.log_state_missed_text'|translate}}</mat-label>
              <input matInput type="text" formControlName="log_state_missed_text" />
              
              <mat-error [flexFormControlError]="form.get('log_state_missed_text')"></mat-error>
            </mat-form-field>
          </div>
        </div>

        <div *ngIf="!isType(['oraclehospitality'])" fxLayout="column" fxLayoutGap="10px" class="mt-1">
          <section *ngIf="enterpriseCrm">
            <mat-checkbox formControlName="createContactInboundCall">
              {{'enterprise_crm.content.create_contact_inbound_call'|translate}}
            </mat-checkbox>
          </section>

          <section *ngIf="enterpriseCrm && enterpriseCrm.is_authenticated && enterpriseCrm.type === 'hubspot'">
            <mat-checkbox formControlName="autoRunTask">{{'enterprise_crm.content.auto_run_task'|translate}}
            </mat-checkbox>
          </section>
        </div>

        <div *ngIf="enterpriseCrm && isType(['hubspot', 'salesforce', 'pipedrive'])" class="mt-3">
          <button type="button" mat-flat-button color="accent" (click)="importUsers()">
            {{'enterprise_crm.content.import_users'|translate}}
          </button>
        </div>
      </ng-container>
    </ng-container>

    <ng-template #tplCustomCrm>
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" class="mt-1">
        <mat-form-field fxFlex>
          <mat-label>{{'enterprise_crm.content.name'|translate}}</mat-label>
          <input matInput placeholder="{{'enterprise_crm.content.name'|translate}}" formControlName="name">
          
          <mat-error [flexFormControlError]="form.get('name')"></mat-error>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field class="full-width">
          <mat-label>{{'enterprise_crm.content.apikey' | translate}}</mat-label>
          <input matInput type="text" placeholder="{{'enterprise_crm.content.apikey'|translate}}"
            formControlName="apikey" [errorStateMatcher]="matcher">
            
          <mat-error [flexFormControlError]="form.get('apikey')"></mat-error>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field class="full-width">
          <mat-label>{{'enterprise_crm.content.url'|translate}}</mat-label>
          <input matInput #urlInput placeholder="{{'enterprise_crm.content.url'|translate}}" formControlName="url">
          <button type="button" mat-button color="primary" matSuffix (click)="verifyCustomCrmConfig()"
            class="btn-test-customcrm">
            <span class="inline-flex">
              <mat-icon>check</mat-icon>{{ 'enterprise_crm.content.test_crm_service'|translate }}
            </span>
          </button>
          <mat-hint align="start">
            <strong>Example: https://smartcontact.{{enterpriseCrm.type}}.com</strong>
            <!-- ?apikey=xxxx&remote_number=0601020304&local_number=0186534900 -->
          </mat-hint>
          
          <mat-error [flexFormControlError]="form.get('url')"></mat-error>
        </mat-form-field>
      </div>

      <div>
        <mat-form-field class="full-width">
          <mat-label>{{'enterprise_crm.content.callstart_url'|translate}}</mat-label>
          <input matInput #urlInput placeholder="{{'enterprise_crm.content.callstart_url'|translate}}"
            formControlName="callstart_url">
            
          <mat-error [flexFormControlError]="form.get('callstart_url')"></mat-error>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field class="full-width">
          <mat-label>{{'enterprise_crm.content.callend_url'|translate}}</mat-label>
          <input matInput #urlInput placeholder="{{'enterprise_crm.content.callend_url'|translate}}"
            formControlName="callend_url">
            
          <mat-error [flexFormControlError]="form.get('callend_url')"></mat-error>
        </mat-form-field>
      </div>

    </ng-template>
  </form>
</div>
