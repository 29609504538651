// import * as Raven from 'raven-js';
import * as Sentry from "@sentry/browser";
// import { BrowserModule } from '@angular/platform-browser';
import { ModuleWithProviders, NgModule } from '@angular/core';
// import { HttpClientModule } from '@angular/common/http';
// import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FlexLayoutModule } from '@angular/flex-layout';
import { WephoneTranslationModule } from '@wephone-translation';
import { ChartModule } from 'angular-highcharts';
import { AndroidPermissions } from '@ionic-native/android-permissions/ngx/';
import { KeychainTouchId } from '@ionic-native/keychain-touch-id/ngx/';
import { Push } from '@ionic-native/push/ngx/';
import { CookieService as NgxCookieService } from 'ngx-cookie-service';
import { NgxElectronModule } from 'ngx-electron';
import { TreeModule } from '@circlon/angular-tree-component';
// import { MaterialModule } from '../wephone-utils/material.module';
import { WephoneCoreModule } from '@wephone-core/wephone-core.module';

// == Service ==
import { WephoneUtilsModule, CookieService } from '@wephone-utils';

import { UserService } from './services/user.service';
import { EnterpriseService } from './services/enterprise.service';
import { HistoryService } from './services/history.service';
import { AgentService } from './services/agent.service';
import { DidService } from './services/did.service';
import { CallQueueService } from './services/callqueue.service';
import { DataBuilderService } from './services/data-builder.service';
import { UserProfileService } from './services/user-profile.service';
import { ValidatorService } from '@wephone-utils/services/validator.service';
import { CallDestinationService } from './services/call-destination.service';
import { ResellerService } from './services/reseller.service';


// == Pipe ==
import { CommonModule, DatePipe } from '@angular/common';

// == Dialog ==
import { ChooseAgentOrNumberDialog } from '@wephone/modals/agent/dialog-choose-agent-or-number/dialog-choose-agent-or-number';
import { AddAgentsToQueueDialog } from '@wephone/modals/agent/dialogAddAgentsToQueue/dialogAddAgentsToQueue';
// import { PauseAgentDialog } from '@wephone/modals/agent/dialogPauseAgent/dialogPauseAgent';
import { AgentChangeQueueListDialog } from '@wephone/modals/agent/agentChangeQueueList/agentChangeQueueList';
import { SetupNewDidsDialogComponent } from '@wephone/modals/did/setup-new-dids-dialog/setup-new-dids-dialog.component';
import { DidOrderDialogComponent } from '@wephone/modals/did/did-order-dialog/did-order-dialog';

// == Directive ==
import { AgentSelectorOneClick } from '@wephone/directives/agent-selector-one-click/agent-selector-one-click';
import { LiveQosWatchSelectorDirective } from '@wephone/directives/liveQosWatchSelector/liveQosWatchSelector';
import { QueueAgentsDirective } from '@wephone/directives/queue-agents/queue-agents';

// == Components ==
import { AgentListComponent } from '@wephone/components/agents/agent-list/agent-list';
import { AvailableAgentsComponent } from '@wephone/components/agents/available-agents/available-agents';
import { ConnectedAgentsComponent } from '@wephone/components/agents/connected-agents/connected-agents';
import { FlexTreeComponent } from '@wephone/components/flex-tree/flex-tree';
import { FlexTreeSelectComponent } from '@wephone/components/flex-tree-select/flex-tree-select';

// -- Calendar --
import {
  CalendarFormInput,
  CalendarSmallListFormInput,
  CalendarSelectFormInput,
  CalendarSmallFormInput,
  OpeningCalendarEditDialogComponent,
  CalendarSmallComponent,
  CalendarSelectListDialogComponent
} from './components/calendar';

import { AccountBasicSettingsComponent } from '@wephone/components/account-basic-settings/account-basic-settings';
import { UserBasicInfoComponent } from '@wephone/components/user/user-basic-info/user-basic-info';
import { CalendarViewComponent } from '@wephone/components/calendar-view/calendar-view.component';

// == Modals ==
import { QueuesFilterPage } from '@wephone/modals/queues-filter/queues-filter';
import { TreeSelectModalPage } from '@wephone/modals/tree-select-modal/tree-select-modal';
import { CalendarEditModal } from '@wephone/modals/calendar/calendar-edit/calendar-edit';
import { ChangeUserPasswordModal } from '@wephone/modals/change-user-password/change-user-password';
import { WephoneAudioModule } from '@wephone-audio';
import { UserCreateModal } from '@wephone/modals/user-create/user-create-modal.component';
import { UserImportModal } from '@wephone/modals/user-import/user-import-modal.component';
// import { UserCallReceptionDialog } from '@wephone-common/modals/user-call-reception-dialog/user-call-reception-dialog';
import { GroupTreeModal } from '@wephone/modals/group-tree-modal/group-tree-modal';
import { IvrMenuCreateModal } from '@wephone/modals/ivr-menu/ivr-menu-create/ivr-menu-create-modal.component';
import { IvrMenuCustomAttributesModal } from '@wephone/modals/ivr-menu/ivr-menu-custom-attributes/ivr-menu-custom-attributes-modal.component';

// == Page ==
import { LoadingScreen } from '@wephone/pages/loading-screen/loading-screen';
import { OverviewEnterprisePage } from '@wephone/pages/overview/enterprise/overview-enterprise';
import { OverviewCallCenterPage } from '@wephone/pages/overview/call-center/overview-callcenter';
import { OverviewPage } from '@wephone/pages/overview/overview';
import { DashboardPage } from '@wephone/pages/dashboard/dashboard/dashboard';
import { CampaignPage } from '@wephone/pages/campaign/overview/campaign';
import { ActiveCallsPage } from '@wephone/pages/active-calls/active-calls';
import { AgentListPage } from '@wephone/pages/agent-list/agent-list';
import { SipPhoneEditComponent } from '@wephone/components/sipphone/sipphone-edit/sipphone-edit.component';
import { SipPhoneEditModal } from '@wephone/modals/sipphone/sipphone-edit/sipphone-edit-modal.component';
import { SipPhonePage } from '@wephone/pages/sipphone-page/sipphone-page.component';
import { NumberRoutingListComponent } from '@wephone/pages/number-routing/number-routing-list/number-routing-list';
import { NumberRoutingEditComponent } from '@wephone/pages/number-routing/number-routing-edit/number-routing-edit';
import { UserPage } from '@wephone/pages/user/user-page.component';
import { QualificationListComponent } from '@wephone/pages/qualification/qualification-list/qualification-list.component';
import { QualificationFormDialogComponent } from '@wephone/pages/qualification/dialogs/qualification-form-dialog/qualification-form-dialog.component';
import { QualificationEditComponent } from '@wephone/pages/qualification/qualification-edit/qualification-edit.component';
import { IvrMenuPage } from '@wephone/pages/ivrmenu/ivrmenu-page.component';
import '@wephone/localforageConfig'; // Setup localforage module
import { MasterDetailLayoutComponent } from '@wephone/components/layout/master-detail-layout/master-detail-layout';
import { UserEditComponent } from '@wephone/components/user/user-edit/user-edit.component';
import { IvrMenuEditComponent } from '@wephone/components/ivr-menu/ivr-menu-edit/ivr-menu-edit.component';
import { EnterpriseInfoPage } from '@wephone/pages/enterprise-info/enterprise-info';
import { TransformPremiumPage } from '@wephone/modals/transform-premium/transform-premium';
import { AddCreditCardComponent } from '@wephone/modals/add-credit-card/add-credit-card.component';
import { PaymentAdyenService } from '@wephone/services/payment-adyen.service';
import { PayCreditRechargeComponent } from '@wephone/modals/pay-credit-recharge/pay-credit-recharge.component';
import { IvrSubMenuComponent } from '@wephone/components/ivr-menu/ivr-sub-menu/ivr-sub-menu.component';
import { PhonepadPickerComponent } from '@wephone/components/phonepad-picker/phonepad-picker.component';
import { CallDestinationComponent } from '@wephone/components/call-destination/call-destination.component';
import { OpeningCalendarComponent } from '@wephone/components/opening-calendar/opening-calendar.component';
import { CampaignPageComponent } from '@wephone/pages/campaign/campaign-page/campaign-page.component';
import { OutcallcampaignEditComponent } from '@wephone/components/outcallcampaign/outcallcampaign-edit/outcallcampaign-edit.component';
import { PauseReasonsComponent } from '@wephone/pages/pause-reasons/pause-reasons.component';
import { SipPhoneCreateModal } from '@wephone/modals/sipphone/sipphone-create/sipphone-create-modal.component';
import { PauseReasonEditComponent } from '@wephone/components/pause-reason-edit/pause-reason-edit.component';
import { OpeningHoursComponent } from '@wephone/pages/opening-hours/opening-hours.component';
import { OutcallcampaignGeneralComponent } from '@wephone/components/outcallcampaign/outcallcampaign-general/outcallcampaign-general.component';
import { OutcallcampaignContactComponent } from '@wephone/components/outcallcampaign/outcallcampaign-contact/outcallcampaign-contact.component';
import { OutcallcampaignAgentComponent } from '@wephone/components/outcallcampaign/outcallcampaign-agent/outcallcampaign-agent.component';
import { OutcallcampaignAdvanceComponent } from '@wephone/components/outcallcampaign/outcallcampaign-advance/outcallcampaign-advance.component';
import { OutcallcampaignResetModalComponent } from '@wephone/modals/outcallcampaign/outcallcampaign-reset-modal/outcallcampaign-reset-modal.component';
import { OutcallcampaignCreateContactComponent } from '@wephone/modals/outcallcampaign/outcallcampaign-create-contact/outcallcampaign-create-contact.component';
import { OutcallcampaignImportContactsComponent } from '@wephone/modals/outcallcampaign/outcallcampaign-import-contacts/outcallcampaign-import-contacts.component';
import { QueueAgentListComponent } from '@wephone/components/agents/queue-agent-list/queue-agent-list.component';
import { AddAgentToQueueComponent } from '@wephone/modals/agent/add-agent-to-queue/add-agent-to-queue.component';
import { ConfirmAgentEligibleComponent } from '@wephone/modals/agent/confirm-agent-eligible/confirm-agent-eligible.component';
import { OpeningHourCalendarEditComponent } from '@wephone/components/opening-hour-calendar-edit/opening-hour-calendar-edit.component';
import { CallQueuePage } from '@wephone/pages/call-queue/call-queue.component';
import { CallQueueEditComponent } from '@wephone/components/call-queue/call-queue-edit/call-queue-edit.component';
import { BlackListComponent } from '@wephone/pages/black-list/black-list.component';
import { UserCallcenterComponent } from '@wephone/components/user/user-callcenter/user-callcenter.component';
import { UserGeneralComponent } from '@wephone/components/user/user-general/user-general.component';
import { UserApiComponent } from '@wephone/components/user/user-api/user-api.component';
import { EditBlackListComponent } from '@wephone/components/edit-black-list/edit-black-list.component';
import { CreatePauseReasonModalComponent } from '@wephone/modals/create-pause-reason-modal/create-pause-reason-modal.component';
import { UserAccessRightComponent } from '@wephone/components/user/user-access-right/user-access-right.component';
import { QueueEditGeneralComponent } from '@wephone/components/call-queue/queue-edit-general/queue-edit-general.component';
import { QueueEditAgentsComponent } from '@wephone/components/call-queue/queue-edit-agents/queue-edit-agents.component';
import { QueueEditAdvanceComponent } from '@wephone/components/call-queue/queue-edit-advance/queue-edit-advance.component';
import { QueueEditDidrouteComponent } from '@wephone/components/call-queue/queue-edit-didroute/queue-edit-didroute.component';
import { InvoiceDetailComponent } from '@wephone/components/invoice-detail/invoice-detail.component';
import { InvoiceComponent } from '@wephone/pages/invoice/invoice.component';
import { CustomMaxInqueueTimeModal } from '@wephone/modals/call-queue/custom-max-inqueue-time/custom-max-inqueue-time.component';
import { RecordingCallComponent } from '@wephone/pages/recording-call/recording-call.component';
import { EnterpriseCrmListComponent } from '@wephone/components/enterprise-crm/enterprise-crm-list/enterprise-crm-list.component';
import { EnterpriseCrmEditComponent } from '@wephone/components/enterprise-crm/enterprise-crm-edit/enterprise-crm-edit.component';
import { GroupsComponent } from '@wephone/pages/groups/groups.component';
import { CreateGroupModalComponent } from './modals/create-group-modal/create-group-modal.component';
import { GroupEditComponent } from './components/group-edit/group-edit.component';
import { AddDidToQueueModalComponent } from './modals/call-queue/add-did-to-queue-modal/add-did-to-queue-modal.component';
import { EditDidInQueueModalComponent } from './modals/call-queue/edit-did-in-queue-modal/edit-did-in-queue-modal.component';
import { ShortDialCodeListComponent } from './pages/short-dial-code/short-dial-code-list/short-dial-code-list.component';
import { CreateShortDialCodeModalComponent } from './modals/create-short-dial-code-modal/create-short-dial-code-modal.component';
import { ShortDialCodeEditComponent } from './pages/short-dial-code/short-dial-code-edit/short-dial-code-edit.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { RoutingService } from '@wephone/wephone.routing';
import { CreateEnterpriseCrmModalComponent } from '@wephone/modals/create-enterprise-crm-modal/create-enterprise-crm-modal.component';
import { CreateQueueModalComponent } from './modals/call-queue/create-queue-modal/create-queue-modal.component';
import { ActiveOutboundCampaignsComponent } from './pages/dashboard/active-outbound-campaigns/active-outbound-campaigns.component';
import { AgentConnectionContentDialog } from './dialogs/agent-connection-content-dialog/agent-connection-content-dialog';
import { RouterModule } from '@angular/router';
import { GraphChartComponent } from './pages/dashboard/graph-chart/graph-chart.component';
import { GraphChartGroupComponent } from './pages/dashboard/graph-chart/graph-chart-group/graph-chart-group.component';
import { GraphChartQueueComponent } from './pages/dashboard/graph-chart/graph-chart-queue/graph-chart-queue.component';
import { CallQueueLogoComponent } from './components/call-queue/call-queue-logo/call-queue-logo.component';
import { ReusableSoundComponent } from './pages/reusable-sound/reusable-sound.component';
import { ReusableSoundConfirmDeleteComponent } from './modals/reusable-sound-confirm-delete/reusable-sound-confirm-delete.component';
import { ManageApiComponent } from './pages/manage-api/manage-api.component';
import { DidOrderModule } from '../did-order/did-order.module';
import { LivePage } from './pages/dashboard/live/live.component';
import { WephoneCommonModule } from '@wephone-common';
import { ConferencesComponent } from './pages/conferences/conferences.component';
import { CreateConferencesModalComponent } from './modals/create-conferences-modal/create-conferences-modal.component';
import { EditConferenceComponent } from './components/edit-conference/edit-conference.component';
import { ShortcutComponent } from './pages/shortcut/shortcut.component';
import { CreateShortcutModalComponent } from './modals/create-shortcut-modal/create-shortcut-modal.component';
import { EditShortcutComponent } from './components/edit-shortcut/edit-shortcut.component';
import { DidConfigWizardModalComponent } from './modals/did/did-config-wizard-modal/did-config-wizard-modal.component';
import { SipTrunkComponent } from './pages/sip-trunk/sip-trunk.component';
import { CreateSiptrunkModalComponent } from './modals/create-siptrunk-modal/create-siptrunk-modal.component';
import { EditSiptrunkComponent } from './components/edit-siptrunk/edit-siptrunk.component';
import { InvoiceEditModal } from './modals/invoice/invoice-edit/invoice-edit.component';
import { ImportUsersFromCrmModal } from '@wephone/modals/import-users-from-crm-modal/import-users-from-crm-modal.component';
import { SipPhoneLogModal } from './modals/sip-phone-log-modal/sip-phone-log-modal.component';

/*
if (window['SENTRY_DSN']) {
  Raven.config(SENTRY_DSN).install();
}
*/
// import { EnterprisesComponent } from './pages/enterprises/enterprises.component';
// import { EnterpriseEditComponent } from './components/enterprise/enterprise-edit/enterprise-edit.component';
import { QueueDidListFormInput } from '@wephone/components/call-queue/queue-did-list/queue-did-list.component';
import { QueueAgentListFormInput } from '@wephone/components/call-queue/queue-agent-list/queue-agent-list.component';
import { WephoneGridModule } from '../wephone-grid/wephone-grid.module';
import { DialogQueueHistoryComponent } from './modals/dialog-queue-history/dialog-queue-history.component';
import { GlobalSearchComponent } from '@wephone/components/global-search/global-search.component';
// import { WephoneStatisticsRoutingModule } from '@wephone-statistics/wephone-statistics-routing.module';
import { PhoneBookManagementComponent } from './pages/phone-book-management/phone-book-management.component';
import { CreatePhoneBookModalComponent } from './modals/create-phone-book-modal/create-phone-book-modal.component';
import { EditPhoneBookComponent } from './components/edit-phone-book/edit-phone-book.component';
import { GroupUserListComponent } from './components/group-user-list/group-user-list.component';
import { GroupAddUserComponent } from './modals/group-add-user/group-add-user.component';
import { RecordingCallAgentComponent } from './pages/recording-call-agent/recording-call-agent.component';
import { InlineTextboxComponent } from './components/inline-textbox/inline-textbox.component';
// import { UserStatsComponent } from './pages/user-stats/user-stats.component';
import { AgentQueuesComponent } from './components/agents/agent-queues/agent-queues';
import { IvrRemoteAppsComponent } from '@wephone/pages/ivr-remote-apps/ivr-remote-apps.component';
import { CreateIvrRemoteAppModalComponent } from './modals/create-ivr-remote-app-modal/create-ivr-remote-app-modal.component';
import { IvrRemoteAppEditComponent } from './components/ivr-remote-app-edit/ivr-remote-app-edit.component';
import { EditCustomStatsFilterComponent } from './components/edit-custom-stats-filter/edit-custom-stats-filter.component';
import { RequestResetPwDialogComponent } from './modals/request-reset-pw-dialog/request-reset-pw-dialog.component';
import { CallQueueMultiEditComponent } from './components/call-queue/call-queue-multi-edit/call-queue-multi-edit.component';
import { AssignQueuesToAgentDialog } from './dialogs/assign-queues-to-agent-dialog/assign-queues-to-agent-dialog';
import { CrmRoutingComponent } from './pages/crm-routing/crm-routing.component';
import { CreateCrmRoutingModalComponent } from './modals/create-crm-routing-modal/create-crm-routing-modal.component';
import { EditCrmRoutingComponent } from './components/edit-crm-routing/edit-crm-routing.component';
import { EditMultipleSipphoneComponent } from './components/edit-multiple-sipphone/edit-multiple-sipphone.component';
import { OutcallNextTasks } from './components/outcall-next-tasks/outcall-next-tasks.component';
import { NumberRoutingMultiEditComponent } from './pages/number-routing/number-routing-multi-edit/number-routing-multi-edit.component';
import { ToolbarMenuShortcuts } from './components/toolbar-menu-shortcuts/toolbar-menu-shortcuts.component';
import { OutcallcampaignMultiEditComponent } from './components/outcallcampaign/outcallcampaign-multi-edit/outcallcampaign-multi-edit.component';
import { ViewAgentStatisticsModalComponent } from './modals/view-agent-statistics-modal/view-agent-statistics-modal.component';
import { PaymentWizardModelComponent } from './modals/payment/payment-wizard-model/payment-wizard-model.component';
import { CreditCardComponent } from '../credit-card/credit-card.component';
import { PaymentService } from './services/payment.service';
import { PaymentDialogService } from './services/payment-dialog.service';
import { InvoiceSendEmailModal } from './modals/invoice/invoice-send-emails/invoice-send-emails';
import { InvoiceInfoComponent } from './components/invoice-info/invoice-info.component';
import { IvrModuleComponent } from './pages/ivr-module/ivr-module.component';
import { IvrModuleEditComponent } from './components/ivr-module-edit/ivr-module-edit.component';
import { IvrModuleCreateModalComponent } from './modals/ivr-module/ivr-module-create-modal/ivr-module-create-modal.component';
import { DebugMessageCallModalComponent } from './modals/debug-message-call-modal/debug-message-call-modal.component';
import { EnterpriseBillingPlanComponent } from './components/enterprise-billing-plan/enterprise-billing-plan.component';
import { ReusableSoundEditComponent } from './components/reusable-sound/reusable-sound-edit/reusable-sound-edit.component';
import { SlackChannelListPage } from './pages/slack-channel/slack-channel-list/slack-channel-list';
import { SlackChannelEditComponent } from './pages/slack-channel/slack-channel-edit/slack-channel-edit';
import { EnterpriseParametersComponent } from './pages/enterprise-parameters/enterprise-parameters.component';
import { AgentWrapperComponent } from './components/agents/agent-wrapper/agent-wrapper';
import { QosComponent } from './components/dashboard/qos/qos.component';
import { EnterprisePaymentInfo } from './pages/enterprise-payment-info/enterprise-payment-info';
import { UpdatePaymentMethodModal } from './modals/update-payment-method/update-payment-method.component';
import { AddSepaDebitComponent } from './modals/add-sepa-debit/add-sepa-debit.component';
import { DidSetupWizardComponent } from './components/did/did-setup-wizard/did-setup-wizard.component';
import { IvrSubMenuEditDialog } from './modals/ivr-sub-menu-edit-dialog/ivr-sub-menu-edit-dialog';
import { UpdateQueueCalendarComponent } from './components/call-queue/update-queue-calendar/update-queue-calendar.component';
import { QueueAgentListOptionsDialog } from './components/call-queue/queue-agent-list-options/queue-agent-list-options.component';
import { OpeningHourCalendarSelectComponent } from './components/opening-hour-calendar-select/opening-hour-calendar-select.component';
import { UserSipComponent } from './components/user/user-sip/user-sip.component';
import { OutcallcampaignContactsComponent } from './modals/outcallcampaign/outcallcampaign-contacts/outcallcampaign-contacts.component';
import { CreateCrmFromMarketPlace } from './components/enterprise-crm/create-from-market-place/create-from-market-place.component';
import { OpeningHourSelectComponent } from './components/opening-hour-select/opening-hour-select.component';
import { QueueEstimatedWaitTimeComponent } from './components/call-queue/queue-estimated-wait-time/queue-estimated-wait-time.component';
import { DidRequestDialogFlowComponent } from './components/did/did-request-dialogflow/did-request-dialogflow.component';
import { DialogFlowService } from '../chatbot/service/dialog-flow.service';
import { dialog } from 'electron';
import { UpdateNumberDescriptionModalComponent } from './modals/did/update-number-description-modal/update-number-description-modal.component';
import { NumberRoutingDestComponent } from './pages/number-routing/number-routing-dest/number-routing-dest';
import { DidMiniBlockComponent } from './components/did/did-mini-block/did-mini-block.component';
import { VoicemailOptionModal } from './modals/call-queue/voicemail-option-modal/voicemail-option-modal.component';
import { UserDedicatedNumberModalComponent } from './modals/user-dedicated-number-modal/user-dedicated-number-modal.component';
import { UserActionLogComponent } from './modals/user-action-log/user-action-log.component';
// import { UserCallReceptionComponent } from '@wephone-common/user/user-call-reception/user-call-reception.component';
import { CustomReportConfigComponent } from './pages/custom-report-config/custom-report-config.component';
import { EditCustomReportConfigComponent } from './components/edit-custom-report-config/edit-custom-report-config.component';
import { CustomReportWidgetComponent } from './components/custom-report-widget/custom-report-widget.component';
import { IvrMenuStatisticsComponent } from './modals/ivr-menu/ivr-menu-statistics/ivr-menu-statistics.component';
import { NumberOpeningCalendar } from './components/number-opening-calendar/number-opening-calendar.component';
import { WallboardComponent } from './components/wallboard/wallboard.component';
import { TagListComponent } from './components/tag/tag-list/tag-list.component';
import { TagEditComponent } from './components/tag/tag-edit/tag-edit.component';
import { CreateTagModalComponent } from './modals/create-tag-modal/create-tag-modal.component';
import { EnterpriseOauthSettingsComponent } from './pages/enterprise-oauth-settings/enterprise-oauth-settings.component';
import { WephoneCustomIconModule } from '@wephone-custom-icon';
import { TimeDependentRoutingComponent } from './components/did/time-dependent-routing/time-dependent-routing.component';
import { PhonebookImportComponent } from './modals/phonebook-import/phonebook-import.component';
import { PhoneBookService } from './services/phonebook.service';
import { CdrComponent } from './components/cdr/cdr.component';
import { SubscriptionService } from './services/subscription.service';
import { SipPhoneService } from '../wephone-common/service/sipphone.service';
import { ConferenceService } from './services/conference.service';
import { CrmRoutingRuleService } from './services/crmroutingrule.service';
import { IvrRemoteAppService } from './services/ivr-remote-app.service';
import { IvrScriptService } from './services/ivr-script.service';
import { EnterpriseInvoicesPamentComponent } from './pages/enterprise-invoices-pament/enterprise-invoices-pament.component';
import { AgentChangeInoutStateComponent } from './modals/agent/agent-change-inout-state/agent-change-inout-state.component';
import { IvrEvaluationComponent } from './pages/ivr-evaluation/ivr-evaluation.component';
import { IvrEvaluationEditComponent } from './components/edit-ivr-evaluation/edit-ivr-evaluation.component';
import { CreateIvrEvaluationModalComponent } from './modals/create-ivr-evaluation-modal/create-ivr-evaluation-modal.component';
import { TeamsComponent } from './pages/teams/teams.component';
import { TeamCreateModalComponent } from './modals/team-create-modal/team-create-modal.component';
import { TeamEditComponent } from './components/team-edit/team-edit.component';
import { EnterpriseCrmService } from './services/emterprise_crm.service';
import { RoutingServiceListComponent } from './pages/routing-service/routing-service-list/routing-service-list.component';
import { RoutingServiceEditComponent } from './pages/routing-service/routing-service-edit/routing-service-edit.component';
import { CreateRoutingServiceModalComponent } from './modals/create-routing-service-modal/create-routing-service-modal.component';
import { QueueMiniBlockComponent } from './components/call-queue/queue-mini-block/queue-mini-block.component';
import { OutcallcampaignMiniBlockComponent } from './components/outcallcampaign/outcallcampaign-mini-block/outcallcampaign-mini-block.component';
import { IvrMenuMiniBlockComponent } from './components/ivr-menu/ivr-menu-mini-block/ivr-menu-mini-block.component';
import { IvrEvaluationMiniBlockComponent } from './components/ivr-evaluation/ivr-evaluation-mini-block/ivr-evaluation-mini-block.component';
import { CrmRoutingRuleMiniBlockComponent } from './components/crm-routing-rule/crm-routing-rule-mini-block/crm-routing-rule-mini-block.component';
import { TestUrlServiceResultModalComponent } from './modals/test-url-service-result-modal/test-url-service-result-modal.component';
import { HotelRoomPageComponent } from './pages/hotel-room-page/hotel-room-page.component';
import { CreateMultipleHotelroomModalComponent } from './modals/create-multiple-hotelroom-modal/create-multiple-hotelroom-modal.component';
import { CreateHotelroomModalComponent } from './modals/create-hotelroom-modal/create-hotelroom-modal.component';
import { EditHotelroomComponent } from './components/edit-hotelroom/edit-hotelroom.component';
import { ReusableSoundService } from './services/reusable-sound.service';
import { HotelRoomLogModalComponent } from './modals/hotel-room-log-modal/hotel-room-log-modal.component';
import { SipPhoneProvisioningTokenListComponent } from './components/sip-phone-provisioning-token/sip-phone-provisioning-token-list.component';
import { UpdateSipPhoneProvisioningTokenModalComponent } from './modals/pending-config-requests/update-sip-phone-provisioning-token.component';
import { ShowProvisioningTokenModalComponent } from './modals/show-provisioning-token/show-provisioning-token.component';
import { SmsTemplateListComponent } from '@wephone/pages/sms-template/sms-template-list/sms-template-list';
import { SmsTemplateEditComponent } from '@wephone/pages/sms-template/sms-template-edit/sms-template-edit';
import { CreateSmsTemplateModalComponent } from './modals/create-sms-template-modal/create-sms-template-modal.component';
import { CalendarMiniBlockComponent } from './components/calendar/calendar-mini-block/calendar-mini-block.component';
import { SipTeminalSettingMiniBlockComponent } from './components/sip-teminal-setting-mini-block/sip-teminal-setting-mini-block.component';
import { SipConnectionInfoComponent } from './components/sip-connection-info/sip-connection-info.component';
import { EnterpriseSipGatewayComponent } from './pages/enterprise-sip-gateway/enterprise-sip-gateway.component';
import { EnterpriseSipGatewayCreateModalComponent } from './modals/enterprise-sip-gateway-create-modal/enterprise-sip-gateway-create-modal.component';
import { EditEnterpriseSipGatewayComponent } from './components/edit-enterprise-sip-gateway/edit-enterprise-sip-gateway.component';
import { OutcallcampaignViewAgentsComponent } from './modals/outcallcampaign/outcallcampaign-view-agents/outcallcampaign-view-agents.component';
import { RoutePriorityPipe } from './directives/did-route-priority/route-priority.pipe';
import { ShowGatewayProvisioninkModalComponent } from './modals/show-gateway-provisioning-link-modal/show-gateway-provisioning-link-modal.component';
import { SipphoneKeyConfigComponent } from './components/sipphone/sipphone-key-config/sipphone-key-config.component';
import { InvoiceUpdatePaymentInfoModal } from './modals/invoice/invoice-update-payment-info/invoice-update-payment-info.component';
import { SmsService } from '@wephone-sms/service/sms.service';
import { ImagesComponent } from './pages/images/images.component';
import { ImageEditComponent } from './components/image/image-edit/image-edit.component';
import { ImageCreateDialogComponent } from './components/image/image-create-dialog/image-create-dialog.component';
import { ImageViewerComponent } from './components/image/image-viewer/image-viewer.component';
import { SipphoneCustomConfigComponent } from './components/sipphone/sipphone-custom-config/sipphone-custom-config.component';
import { ImageChangeComponent } from './components/image/image-change/image-change.component';
import { SipphoneMiniBlockComponent } from './components/sipphone/sipphone-mini-block/sipphone-mini-block.component';
import { ImageConfirmDeleteComponent } from './components/image/image-confirm-delete/image-confirm-delete.component';
import { SipphoneConnectionLogComponent } from './components/sipphone-connection-log/sipphone-connection-log.component';
import { PermissionService } from './services/permission.service';
import { RecordingCallAnalysisResultComponent } from './modals/recording-call-analysis-result/recording-call-analysis-result.component';
import { CreateUpdateIvrEvaluationComponent } from './components/create-update-ivr-evaluation/create-update-ivr-evaluation.component';
import { SipTrunkService } from './services/siptrunk.service';
import { SipTrunkNumberRoutingEditComponent } from './pages/number-routing/siptrunk-number-routing-edit/siptrunk-number-routing-edit.component';
import { SipPhoneConfigTemplateComponent } from './components/sipphone-config-template/sip-phone-config-template/sip-phone-config-template.component';
import { SipPhoneConfigTemplateEditComponent } from './components/sipphone-config-template/sip-phone-config-template-edit/sip-phone-config-template-edit.component';
import { SipPhoneConfigTemplateAddComponent } from './components/sipphone-config-template/sip-phone-config-template-add/sip-phone-config-template-add.component';
import { SipPhoneConfigGroupAddEditComponent } from './components/sipphone-config-template/sip-phone-config-group-add-edit/sip-phone-config-group-add-edit.component';

export class SentryIonicErrorHandler {
  public handleError(error): void {
    //      super.handleError(error);
    try {
      // Raven.captureException(error.originalError || error);
      Sentry.captureException(error.originalError || error);
    } catch (e) {
      console.error(e);
    }
  }
}

@NgModule({
  declarations: [
    // == Dialog ==
    ChooseAgentOrNumberDialog,
    AddAgentsToQueueDialog,
    // PauseAgentDialog,
    AgentChangeQueueListDialog,
    SetupNewDidsDialogComponent,
    DidOrderDialogComponent,
    // == Directive ==
    RoutePriorityPipe,
    AgentSelectorOneClick,
    LiveQosWatchSelectorDirective,
    QueueAgentsDirective,
    // == Queue ==
    QueueDidListFormInput,
    QueueAgentListFormInput,
    QueueAgentListOptionsDialog,
    // == Components ==
    AgentListPage,
    AgentListComponent,
    AvailableAgentsComponent,
    ConnectedAgentsComponent,
    FlexTreeComponent,
    FlexTreeSelectComponent,
    // CalendarSmallComponent,
    AccountBasicSettingsComponent,
    UserBasicInfoComponent,
    CalendarViewComponent,
    AgentQueuesComponent,
    // == Layout ==
    MasterDetailLayoutComponent,
    // == Modals ==
    QueuesFilterPage,
    TreeSelectModalPage,
    CalendarEditModal,
    ChangeUserPasswordModal,
    SipPhoneEditModal,
    UserCreateModal,
    UserImportModal,
    PhonebookImportComponent,
    // UserCallReceptionDialog,
    GroupTreeModal,
    IvrMenuCreateModal,
    IvrMenuCustomAttributesModal,
    SipPhoneCreateModal,
    // == Calendar ==
    CalendarFormInput,
    CalendarSmallListFormInput,
    CalendarSelectFormInput,
    CalendarSmallFormInput,
    OpeningCalendarEditDialogComponent,
    CalendarSmallComponent,
    CalendarSelectListDialogComponent,
    // == Page ==
    LoadingScreen,
    OverviewCallCenterPage,
    OverviewEnterprisePage,
    OverviewPage,
    DashboardPage,
    CampaignPage,
    ActiveCallsPage,
    NumberRoutingDestComponent,
    NumberRoutingListComponent,
    CreditCardComponent,
    NumberRoutingEditComponent,
    NumberRoutingMultiEditComponent,
    SipTrunkNumberRoutingEditComponent,
    UserPage,
    SipPhonePage,
    QualificationListComponent,
    QualificationFormDialogComponent,
    SipPhoneEditComponent,
    UserEditComponent,
    IvrMenuPage,
    IvrMenuEditComponent,
    EnterpriseInfoPage,
    TransformPremiumPage,
    AddCreditCardComponent,
    InvoiceEditModal,
    InvoiceSendEmailModal,
    PayCreditRechargeComponent,
    IvrSubMenuComponent,
    PhonepadPickerComponent,
    CallDestinationComponent,
    OpeningCalendarComponent,
    CampaignPageComponent,
    PauseReasonsComponent,
    EnterpriseParametersComponent,
    PauseReasonEditComponent,
    OpeningHoursComponent,
    OutcallcampaignEditComponent,
    OutcallcampaignGeneralComponent,
    OutcallcampaignContactComponent,
    OutcallcampaignAgentComponent,
    OutcallcampaignAdvanceComponent,
    OutcallcampaignResetModalComponent,
    OutcallcampaignCreateContactComponent,
    OutcallcampaignImportContactsComponent,
    QueueAgentListComponent,
    AddAgentToQueueComponent,
    ConfirmAgentEligibleComponent,
    OpeningHourCalendarEditComponent,
    CallQueuePage,
    CallQueueEditComponent,
    CallQueueMultiEditComponent,
    BlackListComponent,
    UserCallcenterComponent,
    UserGeneralComponent,
    UserApiComponent,
    EditBlackListComponent,
    CreatePauseReasonModalComponent,
    UserAccessRightComponent,
    InvoiceComponent,
    InvoiceDetailComponent,
    QueueEditGeneralComponent,
    QueueEditAgentsComponent,
    QueueEditAdvanceComponent,
    QueueEditDidrouteComponent,
    CustomMaxInqueueTimeModal,
    QualificationFormDialogComponent,
    QualificationEditComponent,
    RecordingCallComponent,
    EnterpriseCrmListComponent,
    CreateEnterpriseCrmModalComponent,
    EnterpriseCrmEditComponent,
    GroupsComponent,
    CreateGroupModalComponent,
    GroupEditComponent,
    AddDidToQueueModalComponent,
    EditDidInQueueModalComponent,
    ShortDialCodeListComponent,
    CreateShortDialCodeModalComponent,
    CreateIvrRemoteAppModalComponent,
    ShortDialCodeEditComponent,
    IvrRemoteAppEditComponent,
    CreateQueueModalComponent,
    ActiveOutboundCampaignsComponent,
    CreateQueueModalComponent,
    AgentConnectionContentDialog,
    GraphChartComponent,
    GraphChartGroupComponent,
    GraphChartQueueComponent,
    CallQueueLogoComponent,
    ReusableSoundComponent,
    ReusableSoundConfirmDeleteComponent,
    ManageApiComponent,
    LivePage,
    ConferencesComponent,
    CreateConferencesModalComponent,
    EditConferenceComponent,
    ShortcutComponent,
    CreateShortcutModalComponent,
    EditShortcutComponent,
    DidConfigWizardModalComponent,
    SipTrunkComponent,
    CreateSiptrunkModalComponent,
    EditSiptrunkComponent,
    LivePage,
    DialogQueueHistoryComponent,
    GlobalSearchComponent,
    PhoneBookManagementComponent,
    CreatePhoneBookModalComponent,
    RequestResetPwDialogComponent,
    EditPhoneBookComponent,
    GroupUserListComponent,
    GroupAddUserComponent,
    PaymentWizardModelComponent,
    RecordingCallAgentComponent,
    // UserStatsComponent,
    InlineTextboxComponent,
    IvrRemoteAppsComponent,
    EditCustomStatsFilterComponent,
    AssignQueuesToAgentDialog,
    CrmRoutingComponent,
    CreateCrmRoutingModalComponent,
    EditCrmRoutingComponent,
    EditMultipleSipphoneComponent,
    OutcallNextTasks,
    ToolbarMenuShortcuts,
    OutcallcampaignMultiEditComponent,
    ViewAgentStatisticsModalComponent,
    InvoiceInfoComponent,
    IvrModuleComponent,
    IvrModuleEditComponent,
    IvrModuleCreateModalComponent,
    DebugMessageCallModalComponent,
    EnterpriseBillingPlanComponent,
    ReusableSoundEditComponent,
    SlackChannelListPage,
    SlackChannelEditComponent,
    AgentWrapperComponent,
    QosComponent,
    EnterprisePaymentInfo,
    UpdatePaymentMethodModal,
    AddSepaDebitComponent,
    DidSetupWizardComponent,
    IvrSubMenuEditDialog,
    UpdateQueueCalendarComponent,
    OpeningHourCalendarSelectComponent,
    UserSipComponent,
    OutcallcampaignContactsComponent,
    CreateCrmFromMarketPlace,
    OpeningHourSelectComponent,
    QueueEstimatedWaitTimeComponent,
    DidRequestDialogFlowComponent,
    ImportUsersFromCrmModal,
    SipPhoneLogModal,
    UpdateNumberDescriptionModalComponent,
    DidMiniBlockComponent,
    VoicemailOptionModal,
    UserDedicatedNumberModalComponent,
    UserActionLogComponent,
    // UserCallReceptionComponent,
    CustomReportConfigComponent,
    EditCustomReportConfigComponent,
    CustomReportWidgetComponent,
    IvrMenuStatisticsComponent,
    NumberOpeningCalendar,
    WallboardComponent,
    TagListComponent,
    TagEditComponent,
    CreateTagModalComponent,
    EnterpriseOauthSettingsComponent,
    TimeDependentRoutingComponent,
    PhonebookImportComponent,
    CdrComponent,
    EnterpriseInvoicesPamentComponent,
    AgentChangeInoutStateComponent,
    IvrEvaluationComponent,
    IvrEvaluationEditComponent,
    CreateIvrEvaluationModalComponent,
    TeamsComponent,
    TeamCreateModalComponent,
    TeamEditComponent,
    RoutingServiceListComponent,
    RoutingServiceEditComponent,
    CreateRoutingServiceModalComponent,
    QueueMiniBlockComponent,
    OutcallcampaignMiniBlockComponent,
    IvrMenuMiniBlockComponent,
    IvrEvaluationMiniBlockComponent,
    CrmRoutingRuleMiniBlockComponent,
    TestUrlServiceResultModalComponent,
    HotelRoomPageComponent,
    CreateMultipleHotelroomModalComponent,
    CreateHotelroomModalComponent,
    EditHotelroomComponent,
    HotelRoomLogModalComponent,
    SipPhoneProvisioningTokenListComponent,
    UpdateSipPhoneProvisioningTokenModalComponent,
    ShowProvisioningTokenModalComponent,
    SmsTemplateListComponent,
    SmsTemplateEditComponent,
    CreateSmsTemplateModalComponent,
    CalendarMiniBlockComponent,
    SipTeminalSettingMiniBlockComponent,
    SipConnectionInfoComponent,
    EnterpriseSipGatewayComponent,
    EnterpriseSipGatewayCreateModalComponent,
    EditEnterpriseSipGatewayComponent,
    OutcallcampaignViewAgentsComponent,
    ShowGatewayProvisioninkModalComponent,
    SipphoneKeyConfigComponent,
    InvoiceUpdatePaymentInfoModal,
    ImagesComponent,
    ImageEditComponent,
    ImageCreateDialogComponent,
    ImageViewerComponent,
    SipphoneCustomConfigComponent,
    ImageChangeComponent,
    SipphoneMiniBlockComponent,
    ImageConfirmDeleteComponent,
    SipphoneConnectionLogComponent,
    RecordingCallAnalysisResultComponent,
    CreateUpdateIvrEvaluationComponent,
    SipPhoneConfigTemplateComponent,
    SipPhoneConfigTemplateEditComponent,
    SipPhoneConfigTemplateAddComponent,
    SipPhoneConfigGroupAddEditComponent
  ],
  imports: [
    // BrowserModule,
    ChartModule,
    RouterModule,
    // BrowserAnimationsModule,
    FlexLayoutModule,
    WephoneCustomIconModule,
    WephoneTranslationModule.forRoot(),
    WephoneUtilsModule,
    WephoneAudioModule,
    WephoneCommonModule,
    NgxElectronModule,
    TreeModule,
    // HttpClientModule,
    WephoneCoreModule,
    // MaterialModule,
    ReactiveFormsModule,
    FormsModule,
    WephoneGridModule,
    DidOrderModule,
  ],
  exports: [
    EditDidInQueueModalComponent,
    GlobalSearchComponent,
    // MaterialModule,
    ToolbarMenuShortcuts,
    QueueEditDidrouteComponent,
    DidRequestDialogFlowComponent,
    NumberRoutingDestComponent,
    ChangeUserPasswordModal,
  ],
  entryComponents: [
    MasterDetailLayoutComponent,
    LoadingScreen,
    OverviewCallCenterPage,
    OverviewEnterprisePage,
    OverviewPage,
    DashboardPage,
    CampaignPage,
    ActiveCallsPage,
    QueuesFilterPage,
    ChooseAgentOrNumberDialog,
    AddAgentsToQueueDialog,
    AgentListPage,
    CallQueueEditComponent,
    QueueAgentListOptionsDialog,
    CallQueueMultiEditComponent,
    // PauseAgentDialog,
    AgentChangeQueueListDialog,
    AgentQueuesComponent,
    TreeSelectModalPage,
    CalendarEditModal,
    SipPhonePage,
    ChangeUserPasswordModal,
    UserCreateModal,
    UserImportModal,
    PhonebookImportComponent,
    // UserCallReceptionDialog,
    NumberRoutingDestComponent,
    NumberRoutingListComponent,
    CreditCardComponent,
    NumberRoutingEditComponent,
    NumberRoutingMultiEditComponent,
    SipTrunkNumberRoutingEditComponent,
    SipPhoneEditModal,
    ConfirmAgentEligibleComponent,
    UserPage,
    CalendarViewComponent,
    OpeningHourCalendarEditComponent,
    OutcallcampaignResetModalComponent,
    QualificationListComponent,
    QualificationEditComponent,
    QualificationFormDialogComponent,
    GroupTreeModal,
    IvrMenuCreateModal,
    IvrMenuCustomAttributesModal,
    SipPhoneCreateModal,
    EnterpriseInfoPage,
    TransformPremiumPage,
    AddCreditCardComponent,
    InvoiceEditModal,
    InvoiceInfoComponent,
    InvoiceSendEmailModal,
    EnterpriseParametersComponent,
    PayCreditRechargeComponent,
    SetupNewDidsDialogComponent,
    DidOrderDialogComponent,
    AddAgentToQueueComponent,
    OutcallcampaignEditComponent,
    OutcallcampaignCreateContactComponent,
    OutcallcampaignImportContactsComponent,
    OutcallcampaignViewAgentsComponent,
    CreatePauseReasonModalComponent,
    CustomMaxInqueueTimeModal,
    CreateEnterpriseCrmModalComponent,
    EnterpriseCrmEditComponent,
    GroupsComponent,
    CreateGroupModalComponent,
    GroupEditComponent,
    AddDidToQueueModalComponent,
    ActiveOutboundCampaignsComponent,
    GraphChartComponent,
    ShortDialCodeListComponent,
    CreateShortDialCodeModalComponent,
    CreateIvrRemoteAppModalComponent,
    ShortDialCodeEditComponent,
    IvrRemoteAppEditComponent,
    CreateQueueModalComponent,
    AgentConnectionContentDialog,
    EditDidInQueueModalComponent,
    ReusableSoundConfirmDeleteComponent,
    LivePage,
    CreateConferencesModalComponent,
    EditConferenceComponent,
    CreateShortcutModalComponent,
    EditShortcutComponent,
    DidConfigWizardModalComponent,
    CreateSiptrunkModalComponent,
    EditSiptrunkComponent,
    DialogQueueHistoryComponent,
    PauseReasonEditComponent,
    GlobalSearchComponent,
    CreatePhoneBookModalComponent,
    RequestResetPwDialogComponent,
    EditPhoneBookComponent,
    GroupAddUserComponent,
    PaymentWizardModelComponent,
    IvrRemoteAppsComponent,
    EditCustomStatsFilterComponent,
    AssignQueuesToAgentDialog,
    CreateCrmRoutingModalComponent,
    EditCrmRoutingComponent,
    EditMultipleSipphoneComponent,
    OutcallcampaignMultiEditComponent,
    ViewAgentStatisticsModalComponent,
    IvrModuleCreateModalComponent,
    DebugMessageCallModalComponent,
    EnterpriseBillingPlanComponent,
    ReusableSoundEditComponent,
    SlackChannelEditComponent,
    AgentWrapperComponent,
    EnterprisePaymentInfo,
    UpdatePaymentMethodModal,
    AddSepaDebitComponent,
    IvrSubMenuComponent,
    IvrSubMenuEditDialog,
    QueueEditDidrouteComponent,
    OutcallcampaignContactsComponent,
    OpeningHourSelectComponent,
    QueueEstimatedWaitTimeComponent,
    DidRequestDialogFlowComponent,
    ImportUsersFromCrmModal,
    OpeningCalendarEditDialogComponent,
    CalendarSelectListDialogComponent,
    SipPhoneLogModal,
    UpdateNumberDescriptionModalComponent,
    DidMiniBlockComponent,
    VoicemailOptionModal,
    UserDedicatedNumberModalComponent,
    UserActionLogComponent,
    // UserCallReceptionComponent,
    CustomReportConfigComponent,
    EditCustomReportConfigComponent,
    IvrMenuStatisticsComponent,
    NumberOpeningCalendar,
    WallboardComponent,
    TagEditComponent,
    CreateTagModalComponent,
    EnterpriseOauthSettingsComponent,
    CdrComponent,
    EnterpriseInvoicesPamentComponent,
    AgentChangeInoutStateComponent,
    IvrEvaluationComponent,
    IvrEvaluationEditComponent,
    CreateIvrEvaluationModalComponent,
    IvrMenuEditComponent,
    TeamCreateModalComponent,
    TeamEditComponent,
    RoutingServiceEditComponent,
    CreateRoutingServiceModalComponent,
    QueueMiniBlockComponent,
    OutcallcampaignMiniBlockComponent,
    IvrMenuMiniBlockComponent,
    IvrEvaluationMiniBlockComponent,
    CrmRoutingRuleMiniBlockComponent,
    TestUrlServiceResultModalComponent,
    CreateMultipleHotelroomModalComponent,
    CreateHotelroomModalComponent,
    EditHotelroomComponent,
    HotelRoomLogModalComponent,
    UpdateSipPhoneProvisioningTokenModalComponent,
    ShowProvisioningTokenModalComponent,
    SmsTemplateListComponent,
    SmsTemplateEditComponent,
    CreateSmsTemplateModalComponent,
    CalendarMiniBlockComponent,
    SipTeminalSettingMiniBlockComponent,
    SipConnectionInfoComponent,
    EnterpriseSipGatewayComponent,
    EnterpriseSipGatewayCreateModalComponent,
    EditEnterpriseSipGatewayComponent,
    ShowGatewayProvisioninkModalComponent,
    InvoiceUpdatePaymentInfoModal,
    ImageCreateDialogComponent,
    ImageEditComponent,
    SipphoneCustomConfigComponent,
    ImageChangeComponent,
    ImageConfirmDeleteComponent,
    RecordingCallAnalysisResultComponent
  ],
  providers: [
    NgxCookieService,
    CookieService,
    AndroidPermissions,
    KeychainTouchId,
    Push,
    // == Pipe ==
    DatePipe,
    // == Service ==
    UserService,
    PermissionService,
    SubscriptionService,
    PhoneBookService,
    PaymentService,
    PaymentDialogService,
    EnterpriseService,
    HistoryService,
    AgentService,
    DidService,
    CallQueueService,
    SipPhoneService,
    CrmRoutingRuleService,
    IvrRemoteAppService,
    IvrScriptService,
    ConferenceService,
    DataBuilderService,
    UserProfileService,
    ValidatorService,
    PaymentAdyenService,
    CallDestinationService,
    RoutingService,
    EnterpriseCrmService,
    ReusableSoundService,
    ResellerService,
    SmsService,
    SipTrunkService
  ]
})
export class WephoneModule {
  // constructor(dialogFlow: DialogFlowService) {
  //   dialogFlow.registerComponent('number-request', DidRequestDialogFlowComponent);
  // }
}
