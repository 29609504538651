
import * as _ from 'lodash';

import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { Colors, DialogActionButton, FormService, ToastService } from '@wephone-utils';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogComponentBase } from '@wephone-core-ui';
import { _tk, _ti } from '@wephone-translation';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SipPhoneConfigTemplateEntity } from '@wephone-core/model/entity/sipphone_config_template';
import { SipPhoneService } from '@wephone-common/service';
import { SipphonePhoneModelInputComponent } from '@wephone-common/form/inputs/sipphone-phone-model-input/sipphone-phone-model-input.component';


@Component({
  selector: 'app-sip-phone-config-template-edit',
  templateUrl: './sip-phone-config-template-edit.component.html',
  styleUrls: ['./sip-phone-config-template-edit.component.scss']
})
export class SipPhoneConfigTemplateEditComponent extends DialogComponentBase implements OnInit {
  dialogTitle = _tk('sipphone_config_template.title.update');
  dialogRightActions: DialogActionButton[] = [
    {
      label: _tk('public.apply'),
      action: () => {
        this.submitForm();
      },
      visible: () => {
        return true;
      },
      color: Colors.PRIMARY
    }
  ];

  form: FormGroup;
  item: SipPhoneConfigTemplateEntity;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
      item: SipPhoneConfigTemplateEntity
    },
    dialogRef: MatDialogRef<SipPhoneConfigTemplateEditComponent>,
    private readonly fb: FormBuilder,
    private readonly sipPhoneService: SipPhoneService,
    private readonly toast: ToastService,
    private readonly formService: FormService,
    cdr: ChangeDetectorRef
  ) {
    super(dialogRef, cdr);

    this.item = data.item;
  }

  async ngOnInit(): Promise<void> {
    super.ngOnInit();

    const model = await SipphonePhoneModelInputComponent.getPhoneModel(this.item.model);
    this.form = this.fb.group({
      name: [ this.item.name, [Validators.required, Validators.maxLength(128)] ],
      model: [ model , [Validators.required, Validators.maxLength(64)] ],
      version: [ this.item.version, [Validators.maxLength(64)] ],
      content: [ this.item.content, [Validators.required] ],
    });

    this.updateDialogLayout();
  }

  async submitForm(): Promise<void> {
    try {
      if (!this.form.valid) {
        console.error('Form invalid', this.form);
        this.form.markAllAsTouched();
  
        this.updateDialogLayout();
        throw new Error(_ti('public.message.data_invalid'));
      }
  
      const updateValue: any = {
        id: this.data.item.id,
        name: this.form.value.name,
        // model: this.form.value.model ? this.form.value.model.value : null,
        // version: this.form.value.version,
        content: this.form.value.content,
      };

      const updateItem: SipPhoneConfigTemplateEntity = await this.sipPhoneService.updateSipPhoneConfigTemplate(updateValue);

      if (!updateItem) {
        throw new Error('An error occurs');
      }

      console.log('responsed sipPhoneConfigTemplate', updateItem);
      this.toast.showSuccess(_ti('public.message.update_success'));
      this.dismiss(updateItem, false);
    } catch (e) {
      this.formService.handleResponsedError(e, this.form);
      console.error('Save sip phone config template error', e);
      this.toast.showErrorMessage(e, _ti('public.message.update_failure'));
    } finally {
      this.updateDialogLayout();
    }
  }
}
