import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ElevenLabsVoiceItem, TTSFileOption, GoogleCloudVoiceParam, Text2SpeechDataService, Text2SpeechLangItem, Text2SpeechLangVoice } from '@wephone-audio/service';
import { FlexBaseComponent } from '@wephone-core-ui';
import { FileEntryEntity } from '@wephone-core/model/entity/fileentry';
import { FileEntryRepository } from '@wephone-core/model/repository/fileentry';
import { FileEntryService } from '@wephone-core/service/file_entry_service';
import { EntityManager } from '@wephone-core/wephone-core.module';
import { _ti } from '@wephone-translation';
import * as _ from 'lodash';
import { IFlexSelectOptions, NoWhitespaceValidator, regexSearch } from '@wephone-utils';
import { FileEntryOptions } from '../fileentry.i';
import { LocalManager } from '@wephone-core/service/local_manager';


@Component({
  selector: 'app-tts-eleven-labs',
  templateUrl: './tts-eleven-labs.component.html',
  styleUrls: ['./tts-eleven-labs.component.scss']
})
export class TtsElevenLabsComponent extends FlexBaseComponent implements OnInit {
  @Input() fileEntry: FileEntryEntity;
  @Input() newFileEntryOptions: FileEntryOptions = {};
  @Output() readonly onChange = new EventEmitter<any>();

  formGenerateVoice: FormGroup;

  // text2speech
  text2speechVoices: string[] = []; // Map between lang & voice

  newFileEntry: FileEntryEntity; // New file entry which will be created

  language: string;
  langSearch = '';
  ttsVoiceSelectOptions: IFlexSelectOptions = {
    filterFunc: (item: any, filterString: string) => {
      return regexSearch(item, filterString);
    }
  };

  private fileEntryRepo = EntityManager.getRepository<FileEntryRepository>('FileEntryRepository');

  constructor(
    private readonly text2SpeechDataService: Text2SpeechDataService,
    private readonly fileEntryService: FileEntryService,
    private readonly localeManager: LocalManager,
    private readonly fb: FormBuilder,
    readonly cdr: ChangeDetectorRef,
  ) {
    super(cdr);
  }

  ngOnInit(): void {
    super.ngOnInit();

  }

  protected async resolveData(): Promise<void> {
    this.language = this.localeManager.getLangForDefaultFileEntry();

    let ttsVoice: string;
    try {
      const voices: ElevenLabsVoiceItem[] = await this.text2SpeechDataService.listElevenLabsVoices();
      this.text2speechVoices = voices.map(x => x.name);

      ttsVoice = this.text2speechVoices[0];
    } catch (error) {
      console.warn('Cannot get text2speech voices', error);
    }

    let ttsText: string;

    if (this.fileEntry && this.fileEntry.tts_data) {
      ttsText = this.fileEntry.tts_data.text;
      ttsVoice = (this.fileEntry.tts_data as any).voice_name;
    }

    this.formGenerateVoice = this.fb.group({
      text: [ttsText, [Validators.required, Validators.maxLength(5000), NoWhitespaceValidator]],
      voice_name: [_.includes(this.text2speechVoices, ttsVoice) ? ttsVoice : this.text2speechVoices[0], [Validators.required]]
    });

    this.addSubscription(
      this.formGenerateVoice.get('text').valueChanges.subscribe(() => {
        this.clearTTSSound();
      })
    );

    if (!this.text2speechVoices.length) {
      this.formGenerateVoice.get('voice_name').setErrors({ 'no_voice': true });
      this.formGenerateVoice.get('voice_name').markAllAsTouched();

      this.showError(_ti('sound_manager.message.no_voice'));
    }

    this.detectChanges();
  }

  clearTTSSound(): void {
    this.newFileEntry = undefined;
    this.detectChanges();
  }

  async recordByText2speech(): Promise<void> {
    const formValid: boolean = this.formGenerateVoice.valid;

    if (!formValid) {
      this.formGenerateVoice.markAllAsTouched();

      this.detectChanges();
      this.showError(_ti('public.message.data_invalid'));
      return;
    }

    try {
      const res = await this.text2SpeechDataService.recordText2SpeechElevenLabs(
        this.formGenerateVoice.get('text').value, this.soundOptions, this.formGenerateVoice.get('voice_name').value
      );
      this.fileEntryRepo.setDataAsReady();
      this.setNewFileEntry(this.fileEntryRepo.getObjectById(res.id));

      this.onChange.emit({ new_file_entry: this.newFileEntry });
    } catch (error) {
      console.error('Record text to speech failed', error);
      this.toastService.showErrorMessage(error, _ti('fileentry.error.unknown'));
    }
  }

  get soundOptions(): TTSFileOption {
    const soundOptions: TTSFileOption = {
      name: this.newFileEntryOptions.name,
      create_temp_file: this.newFileEntryOptions.is_temp ? 1 : 0
    };

    if (this.newFileEntryOptions.file_entry_id) {
      soundOptions.file_entry_id = this.newFileEntryOptions.file_entry_id;
    }

    return soundOptions;
  }

  private setNewFileEntry(fileentry: FileEntryEntity): void {
    this.newFileEntry = fileentry;
    if (!this.newFileEntry) {
      console.error('No file entry responsed');
      return;
    }
    this.newFileEntry.setRemoteUrl(this.fileEntryService.getFileUrl(this.newFileEntry.public_id, false, fileentry.is_default ? this.language : null));
    this.detectChanges();
  }
}
