import * as _ from 'lodash';

import { Component, Inject, OnInit } from '@angular/core';
import { Colors, DialogActionButton, FormService, ToastService } from '@wephone-utils';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogComponentBase } from '@wephone-core-ui';
import { _tk, _ti } from '@wephone-translation';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EnterpriseEntity } from '@wephone-core/model/entity/enterprise';
import { SipPhoneConfigGroupEntity } from '@wephone-core/model/entity/sipphone_config_group';
import { SipPhoneService } from '@wephone-common/service';



@Component({
  selector: 'app-sip-phone-config-group-add-edit',
  templateUrl: './sip-phone-config-group-add-edit.component.html',
  styleUrls: ['./sip-phone-config-group-add-edit.component.scss']
})
export class SipPhoneConfigGroupAddEditComponent extends DialogComponentBase implements OnInit {
  dialogTitle = _tk('sipphone_config_group.title.create');
  dialogRightActions: DialogActionButton[] = [
    {
      label: _tk('public.apply'),
      action: () => {
        this.submitForm();
      },
      visible: () => {
        return true;
      },
      color: Colors.PRIMARY
    }
  ];

  form: FormGroup;
  item: SipPhoneConfigGroupEntity;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
      item: SipPhoneConfigGroupEntity
    },
    dialogRef: MatDialogRef<SipPhoneConfigGroupAddEditComponent>,
    private readonly fb: FormBuilder,
    private readonly sipPhoneService: SipPhoneService,
    private readonly toast: ToastService,
    private readonly formService: FormService,
  ) {
    super(dialogRef);

    this.item = data.item;
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.form = this.fb.group({
      name: [ this.item && this.item.name || '', [Validators.required, Validators.maxLength(128)] ],
    });
  }

  get isEdit(): boolean {
    return this.item && !!this.item.id || false;
  }

  async submitForm(): Promise<void> {
    try {
      if (!this.form.valid) {
        console.error('Form invalid', this.form);
        this.form.markAllAsTouched();
  
        this.updateDialogLayout();
        throw new Error(_ti('public.message.data_invalid'));
      }
  
      const updateValue: any = {
        name: this.form.value.name,
      };

      let updatedItem: SipPhoneConfigGroupEntity;
      if (this.isEdit) {
        updateValue.id = this.item.id;
        updatedItem = await this.sipPhoneService.updateSipPhoneConfigGroup(updateValue);
      } else {
        updatedItem = await this.sipPhoneService.addSipPhoneConfigGroup(updateValue);
      }

      if (!updatedItem) {
        throw new Error('An error occurs');
      }

      console.log('responsed sipPhone config group', updatedItem);
      this.toast.showSuccess(!this.isEdit ? _ti('public.message.create_success') : _ti('public.message.update_success'));
      this.dismiss(updatedItem, false);
    } catch (e) {
      this.formService.handleResponsedError(e, this.form);
      console.error('Save sip phone config template error', e);
      this.toast.showErrorMessage(e, !this.isEdit ? _ti('public.message.create_failure') : _ti('public.message.update_failure'));
    } finally {
      this.updateDialogLayout();
    }
  }
}
