import { BaseRepository } from '@wephone-core/model/repository/base_repository';
import { FlexIvrSettings } from '@wephone-core/service/flexivr_settings';
import { HttpEngine } from '@wephone-core/service/http_engine';
import { EnterpriseObjectTemplateEntity } from '../entity/enterprise_object_template';
import { IEnterpriseObjectTemplate } from '../entity/enterprise_object_template.i';

export class EnterpriseObjectTemplateRepository extends BaseRepository {
  ENTITY_CLASS = EnterpriseObjectTemplateEntity;
  protected URL_PREFIX = 'sa/enterprise_object_template';

  static URL_LIST = 'list';

  protected getBaseUrl(): string {
    return FlexIvrSettings.getInstance().getAbsoluteApiUrlv2();
  }

  async list(params: {
    enterprise_id: number;
  }): Promise<EnterpriseObjectTemplateEntity[]> {
    const url = this.getUrlV2(EnterpriseObjectTemplateRepository.URL_LIST);
    const ret: IEnterpriseObjectTemplate[] = await HttpEngine.getInstance().get(url, params);

    return ret && ret.map(x => this.getObjectById(x.id) as EnterpriseObjectTemplateEntity) || [];
  }

  protected getRemoteData(use_cache = true, filters: any = null): Promise<any> {
    if (this.data_loaded) {
      return Promise.resolve(this.object_list);
    }
    return HttpEngine.getInstance()
      .get(FlexIvrSettings.getInstance().getAbsoluteWSv2URL('api/entity/list/enterprise_object_template'))
      .then(
        (response: any) => {
          this.setDataAsReady();
          return this.object_list;
        },
        (response: any) => {
          console.error('Get subscription pack list error', response);

          return [];
        }
      );
  }
}
