import { Component, OnInit, Input } from '@angular/core';
import { EditingComponent, ToastService } from '@wephone-utils';
import { SipPhoneEntity } from '@wephone-core/model/entity/sipphone';
import { FormBuilder, FormControl, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { EntityManager } from '@wephone-core/wephone-core.module';
import { SipPhoneRepository } from '@wephone-core/model/repository/sipphone';
import { ErrorStateMatcher } from '@angular/material/core';
import { _tk, _ti } from '@wephone-translation';
import * as _ from 'lodash';

export class SipPhoneErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;

    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-edit-multiple-sipphone',
  templateUrl: './edit-multiple-sipphone.component.html',
  styleUrls: ['./edit-multiple-sipphone.component.scss']
})
export class EditMultipleSipphoneComponent extends EditingComponent implements OnInit {
  ALLOW_SCROLL = false;
  MULTI_EDITOR = true;

  hidePassword = true;
  matcher = new SipPhoneErrorStateMatcher();

  @Input() editingList: SipPhoneEntity[];
  sipphone: SipPhoneEntity;

  constructor(
    private readonly fb: FormBuilder,
    private readonly toast: ToastService
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    
    this.initFormGroup();
  }

  setEditingData(data: any): void {
    super.setEditingData(data);
    this.resetDummyValue();
  }

  private resetDummyValue(): void {
    this.sipphone = SipPhoneRepository.getInstance<SipPhoneRepository>().create() as SipPhoneEntity;

    const fieldsToEdit = this.getMappingFields();
    for (const k of Object.keys(fieldsToEdit)) {
      const keyName = fieldsToEdit[k] || k;
      let sameValue = true;
      for (let i = 1; i < this.editingList.length; i++) {
        if (this.editingList[i][keyName] !== this.editingList[0][keyName]) {
          sameValue = false;
          break;
        }
      }
      if (sameValue) {
        this.sipphone[keyName] = this.editingList[0][keyName];
      }
    }
  }

  /**
   * Mapped between entity property & updated field
   */
  private getMappingFields(): Record<string, string> {
    const attrMap = {
      password: undefined,
      // user_id: undefined,
      calling_profile_id: undefined,
      calling_number: 'calling_number_id',
      description: undefined,
    };

    return attrMap;
  }

  /**
   * Mapped between entity property & updated field
   */
  private getUpdateFields(): Record<string, string> {
    const attrMap = this.getMappingFields();

    const changeset: any = this.getChangeSet();
    return _.pick(attrMap, Object.keys(changeset));
  }

  initFormGroup(): void {
    this.form = this.fb.group({
      password: [
        this.sipphone ? this.sipphone.password : undefined,
        [
          Validators.minLength(6),
          Validators.maxLength(20),
        ],
      ],
      calling_profile: [this.sipphone ? this.sipphone.calling_profile : undefined],
      calling_number: [this.sipphone ? this.sipphone.did_calling_number : undefined],
      // user: [this.sipphone ? this.sipphone.user : undefined],
      description: [this.sipphone ? this.sipphone.description : undefined, [Validators.maxLength(255)]],
    });

    this.addSubscription(
      this.form.valueChanges.subscribe(changes => {
        this.onFormValueChange();
      })
    );
  }

  private getFormResetData(): object {
    return {
      password: this.sipphone ? this.sipphone.password : undefined,
      calling_profile: this.sipphone ? this.sipphone.calling_profile : undefined,
      calling_number: this.sipphone ? this.sipphone.did_calling_number : undefined,
      // user: this.sipphone ? this.sipphone.user : undefined,
      description: this.sipphone ? this.sipphone.description : undefined
    };
  }

  resetForm(): void {
    this.form.reset(this.getFormResetData());
    this.form.markAsPristine();
    this.onFormValueChange();
  }

  hasControlValue(controlName: string): any {
    return controlName && this.form.controls[controlName].value;
  }

  // Form actions
  removeControlValue(controlName: string): void {
    this.form.controls[controlName].setValue(undefined);
  }

  async submitForm(): Promise<void> {
    const ids: number[] = this.editingList.map(i => i.id);
    const dumpSipPhone = EntityManager.getRepository('SipPhoneRepository').create() as SipPhoneEntity;

    const changeset: any = this.getChangeSet();
    const updatedData: object = {};
    for (const key of Object.keys(changeset)) {
      switch (key) {
        case 'calling_profile':
          updatedData['calling_profile_id'] = changeset[key] && changeset[key].id || null;
          break;
        case 'user':
          updatedData['user_id'] = changeset[key] && changeset[key].id || null;
          break;
        case 'calling_number':
          updatedData['calling_number'] = changeset[key] && changeset[key].id || null;
          break;
        default:
          updatedData[key] = changeset[key];
      }
    }
    dumpSipPhone.setObjectData(updatedData, false);

    try {
      if (!this.form.valid) {
        this.form.markAllAsTouched();
        throw new Error(_ti('form.validator.data_invalid'));
      }

      const updatedFields: Record<string, string> = this.getUpdateFields();
      await EntityManager.getRepository<SipPhoneRepository>('SipPhoneRepository')
        .saveAttrsBulk(ids, dumpSipPhone, Object.keys(updatedData).map(x => updatedFields[x] || x));

      this.clearEditState();
      this.toast.showSuccess(_ti('sipphone.message.update_success'));
    } catch (error) {
      this.toast.showError(error.message || _ti('public.message.update_failure'));
    }
  }
}
