import { Routes, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthenticationService } from '@wephone-core/service/authentication';
import { OverviewPage } from '@wephone/pages/overview/overview';
import { SipPhonePage } from '@wephone/pages/sipphone-page/sipphone-page.component';
import { CampaignPageComponent } from '@wephone/pages/campaign/campaign-page/campaign-page.component';
import { SipPhoneEditComponent } from '@wephone/components/sipphone/sipphone-edit/sipphone-edit.component';
import { UserPage } from '@wephone/pages/user/user-page.component';
import { TeamsComponent } from '@wephone/pages/teams/teams.component';
import { UserEditComponent } from '@wephone/components/user/user-edit/user-edit.component';
import { IvrMenuPage } from '@wephone/pages/ivrmenu/ivrmenu-page.component';
import { NumberRoutingListComponent } from '@wephone/pages/number-routing/number-routing-list/number-routing-list';
import { PauseReasonsComponent } from '@wephone/pages/pause-reasons/pause-reasons.component';
import { NumberRoutingEditComponent } from '@wephone/pages/number-routing/number-routing-edit/number-routing-edit';
import { OpeningHoursComponent } from '@wephone/pages/opening-hours/opening-hours.component';
import { CallQueuePage } from '@wephone/pages/call-queue/call-queue.component';
import { CallQueueEditComponent } from '@wephone/components/call-queue/call-queue-edit/call-queue-edit.component';
import { BlackListComponent } from '@wephone/pages/black-list/black-list.component';
import { EditBlackListComponent } from '@wephone/components/edit-black-list/edit-black-list.component';
import { InvoiceComponent } from '@wephone/pages/invoice/invoice.component';
import { InvoiceDetailComponent } from '@wephone/components/invoice-detail/invoice-detail.component';
import { RoleGuardService } from '@wephone-core/service/role_guard.service';
import { RecordingCallComponent } from '@wephone/pages/recording-call/recording-call.component';
import { AccessRight, EnterpriseUserRoles, UserRole } from '@wephone-core/system';
import { GroupsComponent } from '@wephone/pages/groups/groups.component';
import { EnterpriseInfoPage } from '@wephone/pages/enterprise-info/enterprise-info';
import { EnterpriseCrmListComponent } from '@wephone/components/enterprise-crm/enterprise-crm-list/enterprise-crm-list.component';
import { ManageApiComponent } from '@wephone/pages/manage-api/manage-api.component';
import { QualificationListComponent } from '@wephone/pages/qualification/qualification-list/qualification-list.component';
import { ShortDialCodeListComponent } from '@wephone/pages/short-dial-code/short-dial-code-list/short-dial-code-list.component';
import { ReusableSoundComponent } from '@wephone/pages/reusable-sound/reusable-sound.component';
import { ConferencesComponent } from '@wephone/pages/conferences/conferences.component';
import { ShortcutComponent } from '@wephone/pages/shortcut/shortcut.component';
import { SipTrunkComponent } from '@wephone/pages/sip-trunk/sip-trunk.component';
import { MyvoicemailqueueComponent } from '@wephone-common';
import { PhoneBookManagementComponent } from './pages/phone-book-management/phone-book-management.component';
import { RecordingCallAgentComponent } from '@wephone/pages/recording-call-agent/recording-call-agent.component';
import { IvrRemoteAppsComponent } from '@wephone/pages/ivr-remote-apps/ivr-remote-apps.component';
import { CrmRoutingComponent } from './pages/crm-routing/crm-routing.component';
import { IvrModuleComponent } from './pages/ivr-module/ivr-module.component';
import { IvrModuleEditComponent } from './components/ivr-module-edit/ivr-module-edit.component';
import { SlackChannelListPage } from './pages/slack-channel/slack-channel-list/slack-channel-list';
import { EnterpriseParametersComponent } from './pages/enterprise-parameters/enterprise-parameters.component';
import { CreateCrmFromMarketPlace } from '@wephone/components/enterprise-crm/create-from-market-place/create-from-market-place.component';
import { TagListComponent } from './components/tag/tag-list/tag-list.component';
import { EnterpriseOauthSettingsComponent } from './pages/enterprise-oauth-settings/enterprise-oauth-settings.component';
import { CdrComponent } from './components/cdr/cdr.component';
import { EnterpriseInvoicesPamentComponent } from './pages/enterprise-invoices-pament/enterprise-invoices-pament.component';
import { IvrEvaluationComponent } from './pages/ivr-evaluation/ivr-evaluation.component';
import { TeamEditComponent } from './components/team-edit/team-edit.component';
import { RoutingServiceListComponent } from './pages/routing-service/routing-service-list/routing-service-list.component';
import { HotelRoomPageComponent } from './pages/hotel-room-page/hotel-room-page.component';
import { SipPhoneProvisioningTokenListComponent } from './components/sip-phone-provisioning-token/sip-phone-provisioning-token-list.component';
import { SmsTemplateListComponent } from './pages/sms-template/sms-template-list/sms-template-list';
import { SmsTemplateEditComponent } from './pages/sms-template/sms-template-edit/sms-template-edit';
import { EnterpriseSipGatewayComponent } from './pages/enterprise-sip-gateway/enterprise-sip-gateway.component';
import { EditEnterpriseSipGatewayComponent } from './components/edit-enterprise-sip-gateway/edit-enterprise-sip-gateway.component';
import { ImagesComponent } from './pages/images/images.component';
import { SipPhoneConfigTemplateComponent } from './components/sipphone-config-template/sip-phone-config-template/sip-phone-config-template.component';

export const WephoneRoutes: Routes = [
  {
    path: 'dashboard',
    canActivate: [RoleGuardService],
    data: {
      expectedRoles: EnterpriseUserRoles // Allow all
    },
    component: OverviewPage
  },
  {
    path: 'dashboard/call-detail/:cdr_public_id',
    canActivate: [RoleGuardService],
    data: {
      expectedRoles: EnterpriseUserRoles
    },
    component: OverviewPage
  },
  {
    path: 'number-routing/:id',
    canActivate: [RoleGuardService],
    data: {
      expectedRoles: [UserRole.ADMIN]
    },
    component: NumberRoutingListComponent
  },
  {
    path: 'number-routing',
    canActivate: [RoleGuardService],
    data: {
      expectedRoles: [UserRole.ADMIN]
    },
    component: NumberRoutingListComponent,
    children: [
      {
        path: ':id',
        component: NumberRoutingEditComponent
      }
    ]
  },
  {
    path: 'manage-users',
    canActivateChild: [RoleGuardService],
    data: {
      expectedRoles: [UserRole.ADMIN, UserRole.SUPERVISOR]
    },
    children: [
      {
        path: 'users/:id',
        canActivate: [RoleGuardService],
        data: {
          expectedRoles: [UserRole.ADMIN, UserRole.SUPERVISOR]
        },
        component: UserPage,
      },
      {
        path: 'users',
        canActivate: [RoleGuardService],
        data: {
          expectedRoles: [UserRole.ADMIN, UserRole.SUPERVISOR]
        },
        component: UserPage,
        children: [
          {
            path: ':id',
            data: {
              expectedRoles: [UserRole.ADMIN, UserRole.SUPERVISOR]
            },
            component: UserEditComponent
          }
        ]
      },
      {
        path: 'teams/:id',
        canActivate: [RoleGuardService],
        data: {
          expectedRoles: [UserRole.ADMIN, UserRole.SUPERVISOR]
        },
        component: TeamsComponent,
      },
      {
        path: 'teams',
        canActivate: [RoleGuardService],
        data: {
          expectedRoles: [UserRole.ADMIN, UserRole.SUPERVISOR]
        },
        component: TeamsComponent,
        children: [
          {
            path: ':id',
            data: {
              expectedRoles: [UserRole.ADMIN, UserRole.SUPERVISOR]
            },
            component: TeamEditComponent
          }
        ]
      }
    ]
  },
  {
    path: 'groups/:id',
    canActivate: [RoleGuardService],
    data: {
      expectedRoles: [UserRole.ADMIN, UserRole.SUPERVISOR]
    },
    component: GroupsComponent
  },
  {
    path: 'groups',
    canActivate: [RoleGuardService],
    data: {
      expectedRoles: [UserRole.ADMIN, UserRole.SUPERVISOR]
    },
    component: GroupsComponent
  },
  {
    path: 'media',
    canActivateChild: [RoleGuardService],
    data: {
      expectedRoles: [UserRole.ADMIN, UserRole.SUPERVISOR]
    },
    children: [
      { path: 'reusable-sound/:id', component: ReusableSoundComponent },
      { path: 'reusable-sound', component: ReusableSoundComponent },
      { path: 'image/:id', component: ImagesComponent },
      { path: 'image', component: ImagesComponent },
    ]
  },
  {
    path: 'ivr',
    canActivate: [RoleGuardService],
    data: {
      expectedRoles: [UserRole.ADMIN]
    },
    children: [
      {
        path: 'ivr-menus/:id',
        component: IvrMenuPage
      },
      {
        path: 'ivr-menus',
        component: IvrMenuPage
      },
      {
        path: 'ivr-evaluation/:id',
        component: IvrEvaluationComponent
      },
      {
        path: 'ivr-evaluation',
        component: IvrEvaluationComponent
      },
    ]
  },
  {
    path: 'queues/:id',
    component: CallQueuePage,
    canActivate: [RoleGuardService],
    data: {
      expectedRoles: [UserRole.ADMIN, UserRole.SUPERVISOR]
    }
  },
  {
    path: 'queues',
    component: CallQueuePage,
    canActivate: [RoleGuardService],
    data: {
      expectedRoles: [UserRole.ADMIN, UserRole.SUPERVISOR]
    },
    children: [
      {
        path: ':id',
        component: CallQueueEditComponent
      }
    ]
  },
  {
    path: 'out-call-campaigns/:id',
    canActivate: [RoleGuardService],
    data: {
      expectedRoles: [UserRole.ADMIN, UserRole.SUPERVISOR]
    },
    component: CampaignPageComponent
  },
  {
    path: 'out-call-campaigns',
    canActivate: [RoleGuardService],
    data: {
      expectedRoles: [UserRole.ADMIN, UserRole.SUPERVISOR]
    },
    component: CampaignPageComponent
  },
  {
    path: 'vocal_messages',
    canActivateChild: [RoleGuardService],
    data: {
      expectedRoles: [
        UserRole.ADMIN,
        UserRole.SUPERVISOR,
        UserRole.WATCHER,
        UserRole.USER,
        UserRole.AGENT
      ]
    },
    children: [
      {
        path: 'voicemail-queue', component: MyvoicemailqueueComponent,
        data: {
          expectedRoles: [
            UserRole.ADMIN,
            UserRole.SUPERVISOR,
            UserRole.WATCHER,
            UserRole.USER,
            UserRole.AGENT
          ],
          accessPermission: AccessRight.VoiceMail
        },
      },
      {
        path: 'recorded-call/:file_public_id', component: RecordingCallComponent,
        data: {
          expectedRoles: [
            UserRole.ADMIN,
            UserRole.SUPERVISOR,
            UserRole.WATCHER,
            UserRole.USER,
            UserRole.AGENT,
          ],
          accessPermission: AccessRight.Recording
        }
      },
      {
        path: 'recorded-call', component: RecordingCallComponent,
        data: {
          expectedRoles: [
            UserRole.ADMIN,
            UserRole.SUPERVISOR,
            UserRole.WATCHER,
            UserRole.USER,
            UserRole.AGENT,
          ],
          accessPermission: AccessRight.Recording
        },
        children: [
          {
            path: ':file_public_id',
            component: RecordingCallComponent
          }
        ]
      },
      {
        path: 'recorded-call-agent', component: RecordingCallAgentComponent,
        data: {
          expectedRoles: [
            UserRole.USER,
            UserRole.AGENT,
          ],
          accessPermission: AccessRight.Recording,
          state: 'recorded-call-agent'
        },
      },
    ]
  },
  // {
  //   path: 'campaigns-overview',
  //   canActivate: [RoleGuardService],
  //   data: {
  //     expectedRoles: [UserRole.ADMIN, UserRole.SUPERVISOR]
  //   },
  //   component: CampaignPage
  // },
  // {
  //   path: 'voicemail-queue',
  //   canActivate: [RoleGuardService],
  //   data: {
  //     expectedRoles: [UserRole.ADMIN, UserRole.SUPERVISOR, UserRole.WATCHER, UserRole.AGENT],
  //     accessPermission: 'VOICEMAIL'
  //   },
  //   component: MyvoicemailqueueComponent
  // },
  // {
  //   path: 'recorded-call',
  //   canActivate: [RoleGuardService],
  //   data: {
  //     expectedRoles: [UserRole.ADMIN, UserRole.SUPERVISOR, UserRole.WATCHER, UserRole.AGENT],
  //     accessPermission: 'RECORDING'
  //   },
  //   component: RecordingCallComponent
  // },
  {
    path: 'statistics',
    canActivateChild: [RoleGuardService],
    data: {
      expectedRoles: [UserRole.ADMIN, UserRole.SUPERVISOR, UserRole.WATCHER, UserRole.USER, UserRole.AGENT],
      // accessPermission: AccessRight.Stats
    },
    loadChildren: () => import('../../../src/app/wephone-statistics/wephone-statistics.module').then(m => m.WephoneStatisticsModule)
  },
  {
    path: 'callcenter-params',
    canActivateChild: [RoleGuardService],
    data: {
      expectedRoles: [UserRole.ADMIN]
    },
    children: [
      {
        path: 'opening-hour/:id',
        component: OpeningHoursComponent,
        data: {
          expectedRoles: [UserRole.ADMIN, UserRole.SUPERVISOR]
        },
      },
      {
        path: 'opening-hour',
        component: OpeningHoursComponent,
        data: {
          expectedRoles: [UserRole.ADMIN, UserRole.SUPERVISOR]
        },
      },
      {
        path: 'pause-reasons/:id',
        component: PauseReasonsComponent,
        data: {
          expectedRoles: [UserRole.ADMIN]
        }
      },
      {
        path: 'pause-reasons',
        component: PauseReasonsComponent,
        data: {
          expectedRoles: [UserRole.ADMIN]
        }
      },
      {
        path: 'qualification/:id',
        component: QualificationListComponent,
        data: {
          expectedRoles: [UserRole.ADMIN, UserRole.SUPERVISOR]
        },
      },
      {
        path: 'qualification',
        component: QualificationListComponent,
        data: {
          expectedRoles: [UserRole.ADMIN, UserRole.SUPERVISOR]
        },
      },
      {
        path: 'shortcut/:id',
        component: ShortcutComponent,
        data: {
          expectedRoles: [UserRole.ADMIN]
        }
      },
      {
        path: 'shortcut',
        component: ShortcutComponent,
        data: {
          expectedRoles: [UserRole.ADMIN]
        }
      },
      {
        path: 'black-list/:id',
        canActivate: [RoleGuardService],
        component: BlackListComponent,
        data: {
          expectedRoles: [UserRole.ADMIN, UserRole.SUPERVISOR]
        },
      },
      {
        path: 'black-list',
        canActivate: [RoleGuardService],
        component: BlackListComponent,
        data: {
          expectedRoles: [UserRole.ADMIN, UserRole.SUPERVISOR]
        },
        children: [
          {
            path: ':id',
            component: EditBlackListComponent
          }
        ]
      }
    ]
  },
  {
    path: 'telephony',
    canActivateChild: [RoleGuardService],
    data: {
      expectedRoles: [UserRole.ADMIN]
    },
    children: [
      {
        path: 'sip-phones/:id',
        component: SipPhonePage
      },
      {
        path: 'sip-phones',
        component: SipPhonePage,
        children: [
          {
            path: ':id',
            component: SipPhoneEditComponent
          }
        ]
      },
      {
        path: 'enterprise-gateway/:id',
        component: EnterpriseSipGatewayComponent
      },
      {
        path: 'enterprise-gateway',
        component: EnterpriseSipGatewayComponent,
        children: [
          {
            path: ':id',
            component: EditEnterpriseSipGatewayComponent
          }
        ]
      },
      {
        path: 'sip-trunk/:id',
        component: SipTrunkComponent
      },
      {
        path: 'sip-trunk',
        component: SipTrunkComponent
      },
      {
        path: 'conferences/:id',
        component: ConferencesComponent
      },
      {
        path: 'conferences',
        component: ConferencesComponent
      },
      {
        path: 'short-dial-code/:id',
        component: ShortDialCodeListComponent,
      },
      {
        path: 'short-dial-code',
        component: ShortDialCodeListComponent,
      },
      {
        path: 'cdr',
        data: {
          expectedRoles: [UserRole.ADMIN, UserRole.AGENT]
        },
        component: CdrComponent
      },
      {
        path: 'sip-phone-config-template',
        component: SipPhoneConfigTemplateComponent,
        children: [
          {
            path: ':id',
            component: SmsTemplateEditComponent,
          }
        ],
        canActivate: [RoleGuardService],
        data: {
          expectedRoles: [UserRole.ADMIN]
        },
      },
      {
        path: 'sip-phone-config-template/:id',
        canActivate: [RoleGuardService],
        data: {
          expectedRoles: [UserRole.ADMIN]
        },
        component: SipPhoneConfigTemplateComponent,
      },
    ]
  },
  {
    path: 'enterprise-pms/:id',
    canActivate: [RoleGuardService],
    data: {
      expectedRoles: [UserRole.ADMIN]
    },
    component: EnterpriseCrmListComponent
  },
  {
    path: 'enterprise-pms',
    canActivate: [RoleGuardService],
    data: {
      expectedRoles: [UserRole.ADMIN]
    },
    component: EnterpriseCrmListComponent,
  },
  {
    path: 'crm',
    canActivateChild: [RoleGuardService],
    data: {
      expectedRoles: [UserRole.ADMIN]
    },
    children: [
      {
        path: 'crm-routing/:id',
        canActivate: [RoleGuardService],
        data: {
          expectedRoles: [UserRole.ADMIN]
        },
        component: CrmRoutingComponent
      },
      {
        path: 'crm-routing',
        canActivate: [RoleGuardService],
        data: {
          expectedRoles: [UserRole.ADMIN]
        },
        component: CrmRoutingComponent
      },
      {
        path: 'enterprise-crm/:id',
        canActivate: [RoleGuardService],
        data: {
          expectedRoles: [UserRole.ADMIN]
        },
        component: EnterpriseCrmListComponent
      },
      {
        path: 'enterprise-crm',
        canActivate: [RoleGuardService],
        data: {
          expectedRoles: [UserRole.ADMIN]
        },
        component: EnterpriseCrmListComponent
      },
      {
        path: 'create-from-marketplace',
        canActivate: [RoleGuardService],
        data: {
          expectedRoles: [UserRole.ADMIN]
        },
        component: CreateCrmFromMarketPlace
      },
      {
        path: 'slack-channel/:id',
        component: SlackChannelListPage,
        canActivate: [RoleGuardService],
        data: {
          expectedRoles: [UserRole.ADMIN]
        }
      },
      {
        path: 'slack-channel',
        component: SlackChannelListPage,
        canActivate: [RoleGuardService],
        data: {
          expectedRoles: [UserRole.ADMIN]
        }
      },
      {
        path: 'tag/:id',
        component: TagListComponent,
        canActivate: [RoleGuardService],
        data: {
          expectedRoles: [UserRole.ADMIN]
        }
      },
      {
        path: 'tag',
        component: TagListComponent,
        canActivate: [RoleGuardService],
        data: {
          expectedRoles: [UserRole.ADMIN]
        }
      }
    ]
  },
  {
    path: 'hotelroom/:id',
    canActivate: [RoleGuardService],
    data: {
      expectedRoles: [UserRole.ADMIN]
    },
    component: HotelRoomPageComponent
  },
  {
    path: 'hotelroom',
    canActivateChild: [RoleGuardService],
    data: {
      expectedRoles: [UserRole.ADMIN]
    },
    component: HotelRoomPageComponent,
  },
  {
    path: 'sms',
    canActivate: [RoleGuardService],
    data: {
      expectedRoles: [UserRole.ADMIN, UserRole.AGENT]
    },
    loadChildren: () => import('@wephone-sms/wephone-sms.module').then(m => m.WephoneSMSModule)
  },
  {
    path: 'enterprise',
    canActivate: [RoleGuardService],
    data: {
      expectedRoles: [UserRole.ADMIN, UserRole.AGENT, UserRole.ACCOUNTANT]
    },
    children: [
      {
        path: 'enterprise-info',
        canActivate: [RoleGuardService],
        data: {
          expectedRoles: [UserRole.ADMIN]
        },
        component: EnterpriseInfoPage
      },
      {
        path: 'enterprise-invoices-payment',
        canActivate: [RoleGuardService],
        data: {
          expectedRoles: [UserRole.ADMIN]
        },
        component: EnterpriseInvoicesPamentComponent
      },
      {
        path: 'enterprise-oauth-settings',
        canActivate: [RoleGuardService],
        data: {
          expectedRoles: [UserRole.ADMIN]
        },
        component: EnterpriseOauthSettingsComponent
      },
      {
        path: 'enterprise-parameters',
        canActivate: [RoleGuardService],
        data: {
          expectedRoles: [UserRole.ADMIN]
        },
        component: EnterpriseParametersComponent
      },
      {
        path: 'phone-book/:id',
        canActivate: [RoleGuardService],
        data: {
          expectedRoles: [UserRole.ADMIN, UserRole.AGENT]
        },
        component: PhoneBookManagementComponent
      },
      {
        path: 'phone-book',
        canActivate: [RoleGuardService],
        data: {
          expectedRoles: [UserRole.ADMIN, UserRole.AGENT]
        },
        component: PhoneBookManagementComponent
      },
      {
        path: 'invoice/:id',
        component: InvoiceComponent,
        canActivate: [RoleGuardService],
        data: {
          expectedRoles: [UserRole.ADMIN, UserRole.ACCOUNTANT]
        }
      },
      {
        path: 'invoice',
        component: InvoiceComponent,
        canActivate: [RoleGuardService],
        data: {
          expectedRoles: [UserRole.ADMIN, UserRole.ACCOUNTANT]
        },
        children: [
          {
            path: ':id',
            component: InvoiceDetailComponent
          }
        ]
      }
    ]
  },
  {
    path: 'developpers',
    canActivate: [RoleGuardService],
    data: {
      expectedRoles: [UserRole.ADMIN]
    },
    children: [
      {
        path: 'manage-api',
        component: ManageApiComponent
      },
      {
        path: 'ivr-application/:id',
        component: IvrModuleComponent
      },
      {
        path: 'ivr-application',
        component: IvrModuleComponent,
        children: [
          {
            path: ':id',
            component: IvrModuleEditComponent
          }
        ]
      },
      {
        path: 'ivr-remote-apps/:id',
        component: IvrRemoteAppsComponent
      },
      {
        path: 'ivr-remote-apps',
        component: IvrRemoteAppsComponent
      },
      {
        path: 'routing-service/:id',
        component: RoutingServiceListComponent,
      },
      {
        path: 'routing-service',
        component: RoutingServiceListComponent,
      },
    ]
  },
  {
    path: 'sip-phone-provisioning-token',
    component: SipPhoneProvisioningTokenListComponent,
    canActivate: [RoleGuardService],
    data: {
      expectedRoles: [UserRole.ADMIN]
    },
  },
  {
    path: 'sms-template/:id',
    canActivate: [RoleGuardService],
    data: {
      expectedRoles: [UserRole.ADMIN]
    },
    component: SmsTemplateListComponent,
  },
  {
    path: 'sms-template',
    canActivate: [RoleGuardService],
    data: {
      expectedRoles: [UserRole.ADMIN]
    },
    component: SmsTemplateListComponent,
    children: [
      {
        path: ':id',
        component: SmsTemplateEditComponent,
      }
    ]
  },
];

@Injectable()
export class RoutingService {
  constructor(
    private readonly authService: AuthenticationService,
    private readonly router: Router,
  ) { }

  canActivate(): boolean {
    if (!this.authService.isAuthenticated()) {
      this.router.navigate(['/s/signin']);
      return false;
    }

    return true;
  }
}
