<form [formGroup]="form">
  <div fxFill fxLayout="column">
    <ng-container *ngIf="!customizeConfig">
      <div fxFlex>
        <mat-form-field class="full-width">
          <mat-label>{{ 'public.name'|translate }}</mat-label>
          <input data-ci="name" matInput formControlName="name">
          
          <mat-error [flexFormControlError]="form.get('name')"></mat-error>
        </mat-form-field>
      </div>

      <div fxFlex>
        <mat-form-field class="full-width">
          <mat-label>{{ 'sip_token.phone_model'|translate }}</mat-label>
          <!-- <mat-select data-ci="select-phone-model" formControlName="phone_model">
            <mat-option *ngFor="let phoneModel of phoneModels" [value]="phoneModel.value">
              {{ phoneModel.label }}
            </mat-option>
          </mat-select> -->

          <sipphone-phone-model-input
            formControlName="phone_model"></sipphone-phone-model-input>

          <button mat-icon-button matSuffix type="button" (click)="clearPhoneModel($event)"
            [disabled]="form.get('phone_model').value === null">
            <mat-icon>close</mat-icon>
          </button>
          
          <mat-error [flexFormControlError]="form.get('phone_model')"></mat-error>
        </mat-form-field>
      </div>

      <div>
        <mat-slide-toggle [(ngModel)]="setExpirationDate" [ngModelOptions]="{standalone: true}"
          (change)="changeExpirationDate($event)" data-ci="set-expiration-date">
          {{'public.set_enable_expiration_date'|translate}}
        </mat-slide-toggle>
        <div class="ml-1" *ngIf="setExpirationDate" fxLayout="column">
          <div>
            <mat-form-field class="full-width">
              <mat-label>{{ 'public.expiration_date' | translate }}</mat-label>
              <input matInput [min]="minDate" [matDatepicker]="startPicker" formControlName="valid_until"
                placeholder="{{ 'public.expiration_date' | translate }}">
              <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
              <mat-datepicker #startPicker></mat-datepicker>
            </mat-form-field>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="customizeConfig">
      <mat-form-field class="full-width">
        <mat-label>{{ 'sip_token.content.customize_config'|translate }}</mat-label>
        <textarea matInput cdkTextareaAutosize cdkAutosizeMinRows="5" formControlName="config_template"
          [placeholder]="'sip_token.content.customize_config'|translate"></textarea>
          
        <mat-error [flexFormControlError]="form.get('config_template')"></mat-error>
      </mat-form-field>
    </ng-container>
  </div>
</form>
