import { datafield, Entity, mapped_datafield } from '@wephone-core/model/model';
import { ISipPhoneConfigTemplate } from './sipphone_config_template.i';
import { SipPhoneConfigGroupEntity } from './sipphone_config_group';
import { EntityManager } from '@wephone-core/wephone-core.module';

export class SipPhoneConfigTemplateEntity extends Entity implements ISipPhoneConfigTemplate {
  static object_type_id = 'sipphone_config_template';

  @datafield
  id: number;

  @mapped_datafield('sipphone_config_group_id')
  group_id: number;

  @mapped_datafield('template_name')
  name: string;

  @mapped_datafield('template_content')
  content: string;

  @datafield
  model: string;

  @datafield
  version: string;

  get group(): SipPhoneConfigGroupEntity {
    return EntityManager.getInstance().getRepository('SipPhoneConfigGroupRepository').getObjectById(this.group_id);
  }
}
