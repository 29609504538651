<div fxLayout="column" fxFlex="grow">
  <div [hidden]="!data.show_date" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
    <div fxFlex>
      <button *ngIf="!isToday" mat-stroked-button color="primary" (click)="fastChangeDay()">
        {{ 'dashboard.button.today' | translate }}
      </button>

      <button *ngIf="isToday" mat-raised-button color="primary" (click)="fastChangeDay()">
        {{ 'dashboard.button.today' | translate }}
      </button>
    </div>
    <div fxFlex="50" fxFlex.xs="70">
        <mat-form-field class="full-width date-selector">
          <button matPrefix mat-icon-button color="primary" (click)="fastChangePrevDay()"
            title="{{ 'public.navigation.prev'|translate }}">
            <mat-icon>chevron_left</mat-icon>
          </button>
  
          <input class="input-text" matInput [(ngModel)]="date" [matDatepicker]="scheduleStartDtPicker">

          <mat-datepicker-toggle fxFlex [for]="scheduleStartDtPicker"></mat-datepicker-toggle>
          <mat-datepicker #scheduleStartDtPicker></mat-datepicker>

          <button matSuffix mat-icon-button color="primary" (click)="fastChangeNextDay()"
            title="{{ 'public.navigation.next'|translate }}">
            <mat-icon>chevron_right</mat-icon>
          </button>
        </mat-form-field>
    </div>
  </div>

  <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px" class="mt-2">
    <div fxFlex="24" fxFlex.xs="49">
      <flex-time-picker [(ngModel)]="start_time" placeholder="{{ 'campaign.hour_start'|translate }}"></flex-time-picker>
    </div>
    <div fxFlex="24" fxFlex.xs="49">
      <flex-time-picker [(ngModel)]="end_time" placeholder="{{ 'campaign.hour_end'|translate }}"></flex-time-picker>
    </div>
  </div>

  <div fxLayout="column" [hidden]="!data.show_queue">
    <h3>{{'dashboard.content.filter_queues'|translate}}</h3>
    <flex-tree-input [nodes]="queueNodes" [multiple]="true" [showFilter]="true" [formControl]="queueCtl"
      [showCheckAll]="true" data-ci="filter-items">
    </flex-tree-input>
  </div>
</div>
