<div fxLayout="column" fxFill class="pa-1">

  <flex-table
    [enablePaginator]="false"
    [dataSource]="dataSource"
    [tableConfig]="tableConfig"
    [columnTemplates]="{ name: tplName, action: tplAction }">

    <ng-template #tplName let-item="row">
      <span [ngClass]="item.type === 'group' ? 'name-group' : 'name-template'"
        [matTooltip]="('sipphone_config_template.labels.label_' + item.type) | translate:{name: item.name}">{{ item.name }}</span>
    </ng-template>

    <ng-template #tplAction let-item="row">
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
        <div>
          <button
            mat-icon-button
            type="button"
            color="primary"
            (click)="editSipPhoneItem(item)"
            [matTooltip]="'public.edit' | translate"
          >
            <mat-icon>edit</mat-icon>
          </button>
        </div>

        <div>
          <button
            mat-icon-button
            type="button"
            color="warn"
            (click)="deleteSipPhoneItem(item)"
            [matTooltip]="'public.remove' | translate"
          >
            <mat-icon>delete</mat-icon>
          </button>
        </div>
        
        <div *ngIf="item.type === 'group'">
          <button
            mat-icon-button
            type="button"
            color="primary"
            (click)="createSipPhoneConfigTemplate(item.entity)"
            [matTooltip]="'sipphone_config_group.action.add_to_group' | translate"
          >
            <mat-icon>playlist_add</mat-icon>
          </button>
        </div>
        
      </div>
    </ng-template>

  </flex-table>
</div>
