import * as _ from 'lodash';
import {
  Component,
  OnInit,
  Input,
  TemplateRef,
  ViewChild,
  Output,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  OnDestroy,
  ElementRef
} from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort, MatSortable } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import {
  FlexDataSource,
  IFlexDataSourceColumn,
  IFlexDataSourceColumnList
} from '../../datasource/datasource';
import { Angular5Csv } from 'angular5-csv/dist/Angular5-csv';
import { BehaviorSubject } from 'rxjs';
import { MediaObserver } from '@angular/flex-layout';
import { SubscriptionManagedComponent } from '../base';
import { DynamicFilterSource } from './datasource/dynamic-filter-source';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { VirtualScrollDataSource } from './datasource/virtualscroll-datasource';
import { localNow } from '../../utils';
import { DataRenderService } from '../../services';
import { IFlexTableSidePannelOptions } from '../flex-table-side-pannel/flex-table-side-pannel.component';
import { _ti } from '@wephone-translation';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { FlexPaginatorConfigDefault, FlexTableSelectionChangeEvent, IFlexTableAction, IFlexTableColumnConfig, IFlexTableConfig } from './flex-table.i';
import { MatCheckboxChange } from '@angular/material/checkbox';

const MultipleEdit = 'multiple-edit';
@Component({
  selector: 'flex-table',
  templateUrl: './flex-table.component.html',
  styleUrls: ['./flex-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0', visibility: 'hidden' })),
      state('expanded', style({ height: '*', visibility: 'visible' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class FlexTableComponent extends SubscriptionManagedComponent implements OnInit, OnDestroy {
  private _tableConfig: IFlexTableConfig;
  _headerConfigs: IFlexTableColumnConfig[];
  selection: SelectionModel<any>;
  private _filterString: any;
  private _focusedRow: any;
  private columnDefMap: any;
  private lastNotifiedList: any[];
  // private mediaSubscription: Subscription;
  private canLoadMore = true;
  // filterStr: string;
  // show: boolean;
  // private data: any;
  viewportDataSource: VirtualScrollDataSource | DynamicFilterSource | FlexDataSource;
  placeholderHeight = 0;
  showReloadLink = false;

  private _sortedCfg: MatSortable;
  private _primarySelectionActions: IFlexTableAction[] = [];

  private _searchHintText: string;

  get sortedCfg(): MatSortable {
    return this._sortedCfg;
  }

  set sortedCfg(val: MatSortable) {
    this._sortedCfg = val;
    if (this.dataSource instanceof DynamicFilterSource && this.sort) {
      this.sort.sort(this._sortedCfg);
      // console.log('this._sortedCfg', this._sortedCfg);
      this.dataSource.sort = this.sort; // Dont know why datatable doesn't sort immediately
      this.dataSource.onFilterChange();
    }
  }

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(CdkVirtualScrollViewport, { static: true }) viewport: CdkVirtualScrollViewport;

  @Input() enablePaginator: boolean;
  @Input() noHeader: boolean;
  @Input() compactMode: boolean;
  @Input() dataSource: DynamicFilterSource | FlexDataSource;
  @Input() sidePannelOptions?: IFlexTableSidePannelOptions;
  @Input() multiTemplateDataRows: boolean;
  @Input()
  set tableConfig(config: IFlexTableConfig) {
    this._tableConfig = config;
    let hasVisibleOnFocus = false;
    for (const col of this._tableConfig.columns) {
      if (col.visibleInCompactMode) {
        hasVisibleOnFocus = true;
        break;
      }
    }
    if (!hasVisibleOnFocus) {
      this._tableConfig.columns[0].visibleInCompactMode = true; // Force at least one column to be visible on focus
    }
    this._updateTableConfig();
  }

  get tableConfig(): IFlexTableConfig {
    return this._tableConfig;
  }

  get tableConfigScrollable(): boolean {
    return this._tableConfig && this._tableConfig.scrollable !== false || false;
  }

  @Input() columnTemplates: Map<string, TemplateRef<any>>;
  @Input() filterTemplate: TemplateRef<any>;
  @Input() expandedDetailTemplate: TemplateRef<any>;
  @Input() align: 'left' | 'right' | 'center';

  @Input()
  set filterString(val: string) {
    if (val && !(this.dataSource instanceof MatTableDataSource) && !(this.dataSource instanceof FlexDataSource)) {
      throw new Error('Filter string can only be set for data source of type MatTableDataSource or FlexDataSource');
    }
    this._filterString = val;
    if (this.dataSource && this.dataSource instanceof MatTableDataSource) {
      this.dataSource.filter = this._filterString;
    }

    if (this.dataSource && this.dataSource instanceof FlexDataSource) {
      this.dataSource.setFilterString(this._filterString);
    }
  }

  get filterString(): string {
    return this._filterString;
  }

  @Output() readonly onSelectionChange = new BehaviorSubject<FlexTableSelectionChangeEvent>(new FlexTableSelectionChangeEvent(undefined, []));

  @ViewChild('tplExpandedDetailDefault', { static: true }) defaultExpandedDetailTemplate: TemplateRef<any>;
  @ViewChild('tplCellDefault', { static: true }) defaultColumnTemplate: TemplateRef<any>;
  @ViewChild('tplFilterDefault', { static: true }) defaultFilterTemplate: TemplateRef<any>;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  // pagination configuration
  length = FlexPaginatorConfigDefault.PAGINATOR_LENGTH;
  pageSize = FlexPaginatorConfigDefault.PAGINATOR_PAGE_SIZE;
  pageSizeOptions = [10, 20, 50, 100];

  expandedElement: any;
  isExpansionDetailRow = (i: number, row: Object) => row.hasOwnProperty('detailRow');

  constructor(
    private cdr: ChangeDetectorRef,
    private mediaObserver: MediaObserver,
    private elRef: ElementRef,
    private dataRenderService: DataRenderService
  ) {
    super();
    const initialSelection = [];
    const allowMultiSelect = true;
    this.selection = new SelectionModel<any>(allowMultiSelect, initialSelection);
    this.addSubscription(
      this.selection.changed.subscribe(v => {
        console.log('Selection changed ', v);
        this.notifySelectionChange();
      })
    );

    if (!this.align) {
      this.align = 'left';
    }
  }

  ngOnInit(): void {
    this.showReloadLink = false;
    if (this.tableConfig && this.tableConfig.align) {
      this.align = this.tableConfig.align;
    }
    this._primarySelectionActions = _.clone(this.primarySelectionActionDefault);

    if (this.sidePannelOptions && this.sidePannelOptions.multiItemEditor) {
      this._primarySelectionActions.push({
        id: MultipleEdit,
        icon: 'edit',
        callback: data => {
          return this.notifySelectionChange(true);
        }
      });
    }
    if (this.dataSource instanceof MatTableDataSource) {
      const sortColumnCfg = this._tableConfig.columns.find(x => x.sortable && x.sort !== undefined);
      if (sortColumnCfg && sortColumnCfg.sortable) {
        const sortCfg: MatSortable = {
          id: sortColumnCfg.name,
          start: sortColumnCfg.sort,
          disableClear: false
        };
        this.sortedCfg = sortCfg;
      }
    }
    if (this.dataSource instanceof FlexDataSource) {
      this.addSubscription(this.dataSource.columnChanges.subscribe(this._updateTableConfig));
      // Subscription for data source loading state
      this.addSubscription(this.dataSource.loadingStateChanges.subscribe(this.detectChanges));
      // Subscription for data change
      this.addSubscription(
        this.dataSource.dataChanges.subscribe(data => {
          if (!data) {
            this.selection.clear();
          }
          this.detectChanges();
        })
      );
      // Subscription for new item awareness
      this.addSubscription(
        this.dataSource.newItemAware.subscribe(data => {
          console.log('new item aware', data);
          if (!data) {
            return;
          }

          this.showReloadLink = true;
          this.cdr.detectChanges();
        })
      );
    }
    if (this.dataSource instanceof DynamicFilterSource) {
      this.addSubscription(this.dataSource.dataChanges.subscribe(this.detectChanges));
    }

    if (this.hasPaginator) {
      // Init page navigation
      this.dataSource.paginator = this.paginator;
      if (this.dataSource instanceof FlexDataSource) {
        this.dataSource.load_page(0);
      }
    }

    // Subscribe for windows size changes
    this.addSubscription(this.mediaObserver.asObservable().subscribe(this.detectChanges));

    if (this.tableConfigScrollable && this.viewport) {
      this.viewportDataSource = new VirtualScrollDataSource(
        this.dataSource,
        this.viewport,
        this.tableConfig.rowHeight || 40,
        !!this.tableConfig.enableExpandDetail
      );
      this.addSubscription(
        this.viewportDataSource.offsetChange.subscribe(
          offset => {
            this.placeholderHeight = offset;
          }
        )
      );
    } else {
      this.viewportDataSource = this.dataSource;
    }

    this.setSearchHintText();
  }
  get defaultListActions(): IFlexTableAction[] {
    if (this.tableConfig && this.tableConfig.listActions && this.tableConfig.listActions.defaultActions) {
      return this.tableConfig.listActions.defaultActions.filter(i => i.visible !== false);
    }
    return [];
  }

  get primarySelectionActionDefault(): IFlexTableAction[] {
    if (this.tableConfig && this.tableConfig.listActions &&
      this.tableConfig.listActions.selectionActions && this.tableConfig.listActions.selectionActions.primary) {
      return this.tableConfig.listActions.selectionActions.primary;
    }
    return [];
  }

  get primarySelectionActions(): IFlexTableAction[] {
    let primarySelectionActions = this.primarySelectionActionDefault;
    if (this.selection.selected.length > 1) {
      primarySelectionActions = this._primarySelectionActions;
    }

    return primarySelectionActions.filter(i => i.visible !== false);
  }

  detectChanges = (data?: any): void => {
    this.cdr.markForCheck();
    this.cdr.detectChanges();
  }

  getRowClass(row: any): string {
    if (this.selection.isSelected(row)) {
      return 'checked';
    }

    if (row === this.focusedRow) {
      return 'focused';
    }

    if (this.tableConfig && this.tableConfig.rowClass) {
      return this.tableConfig.rowClass(row);
    }
  }

  async reloadDataSource(filters?: any): Promise<void> {
    if (this.dataSource instanceof FlexDataSource) {
      if (this.paginator) {
        this.paginator.pageIndex = 0;
      }
      await this.dataSource.reload(filters);

      if (this.viewport) {
        this.viewport.scrollToOffset(0);
      }
    }

    if (this.dataSource instanceof DynamicFilterSource) {
      this.dataSource.onFilterChange();
    }

    this.showReloadLink = false;
  }

  // onChangeFilterStr(searchStr: string): void {
  //   this.filterString = this.filterStr;
  //   if (searchStr !== '') {
  //     this.filterString = searchStr;
  //     this.reloadDataSource();
  //   }
  // }

  onKeyupFilterStr(event): void {
    const searchStr: string = event.target.value;
    this.filterString = (searchStr || '').trim();
    // if (searchStr === '' || event.key === 'Enter') {
    this.reloadDataSource();
    // }
  }

  onBlurFilterStr(event): void {
    const searchStr: string = event.target.value;
    if (searchStr !== '') {
      this.filterString = (searchStr || '').trim();
      this.reloadDataSource();
    }
  }

  onClearFilterStr($event): void {
    // this.filterStr = '';
    this.filterString = ''; // this.filterStr;
    this.reloadDataSource();
  }

  sortData(sort: Sort): void {
    if (!sort.active) {
      return;
    }
    // Only work with FlexDataSource
    if (this.dataSource && this.dataSource instanceof FlexDataSource) {
      this.dataSource.setOrder(sort.active, sort.direction);
      this.reloadDataSource();
    }

    if (this.dataSource && this.dataSource instanceof DynamicFilterSource && sort.direction) {
      console.log('Sort', sort);
      const headerCfg = this._headerConfigs.find(col => col.name === sort.active);
      this.dataSource.setOrder(sort.active, sort.direction, headerCfg.customSortFn);
      // console.log('this.dataSource.sort', this.dataSource.sort);
      this.dataSource.onFilterChange();
    }
  }

  get visibleheaders(): IFlexTableColumnConfig[] {
    return this._headerConfigs.filter(col => this.isColumnVisible(col));
  }

  public isColumnVisible = (col: IFlexTableColumnConfig): boolean => {
    return col.visible !== false && (!col.hiddenOn || !this.mediaObserver.isActive(col.hiddenOn)) &&
      (!this.compactMode || col.visibleInCompactMode);
  }

  private _updateHeaderConfigs(columnConfig: IFlexTableColumnConfig): void {
    const headConfig = this._headerConfigs.find(col => col.name === columnConfig.name);
    if (headConfig) {
      _.extend(headConfig, columnConfig);
    }

    // Set default sorting
    if (headConfig.sort && this.dataSource instanceof FlexDataSource && !(this.dataSource as FlexDataSource).columnLoaded) {
      this.dataSource.setOrder(headConfig.name, headConfig.sort);
    }
  }

  private _convertRemoteToLocalColumnCfg(localCol: IFlexTableColumnConfig, remoteCol: IFlexDataSourceColumn): void {
    // Only override if not configured in flex-table yet
    if (remoteCol.visible === false && !localCol.hasOwnProperty('visible')) {
      localCol.visible = false;
    }

    if (remoteCol.displayName && !localCol.hasOwnProperty('label')) {
      localCol.label = remoteCol.displayName;
    }

    localCol.sortable = true;
    if (remoteCol.enableSorting === false) {
      localCol.sortable = false;
    }

    localCol.searchable = true;
    if (remoteCol.searchable === false) {
      localCol.searchable = false;
    }

    if (remoteCol.sort) {
      localCol.sort = remoteCol.sort.direction;
    }

    localCol.type = remoteCol.type || localCol.type;
    localCol.uiWidget = remoteCol.uiWidget || localCol.uiWidget;
  }

  get columns_with_select(): string[] {
    const columns = _.map(this.visibleheaders, obj => obj.name);
    if (this.tableConfig.multiSelect) {
      columns.unshift('select');
    }

    if (this.tableConfig.enableExpandDetail) {
      columns.push('row_toggle');
    }
    return columns;
  }

  private _updateTableConfig = (dataSourceColumns?: IFlexDataSourceColumnList): void => {
    this._headerConfigs = this._tableConfig.columns;

    let remoteCol: IFlexDataSourceColumn;
    if (dataSourceColumns) {
      // Clone table header before updating it with data from remote datasource
      this._headerConfigs = _.cloneDeep(this._tableConfig.columns);

      // this.show = true;
      // Update table columns with columns from data source
      for (const localCol of this._tableConfig.columns) {
        remoteCol = dataSourceColumns.find(item => item.name === localCol.name);
        if (remoteCol) {
          this._convertRemoteToLocalColumnCfg(localCol, remoteCol);
        }

        this._updateHeaderConfigs(localCol);
      }

      (this.dataSource as FlexDataSource).setColumnConfig(this._headerConfigs);
    }

    // Set default values for table config
    _.defaults(this._tableConfig, {
      multiSelect: true,
      rowHeight: 40
    });

    this.columnDefMap = {};
    for (const col of this._tableConfig.columns) {
      this.columnDefMap[col.name] = col;
    }
    this.cdr.markForCheck();
  }

  inSelectionMode(): boolean {
    return this.selection.hasValue();
  }

  isOnlyOneSelected(): boolean {
    return this.selection.selected && this.selection.selected.length === 1;
  }

  get secondarySelections(): IFlexTableAction[] {
    const secondaryActions = this.tableConfig.listActions &&
      this.tableConfig.listActions.selectionActions &&
      this.tableConfig.listActions.selectionActions.secondary || [];

    if (!this.isOnlyOneSelected()) {
      return secondaryActions.filter(x => !x.unique);
    }
    return secondaryActions.filter(i => i.visible !== false);
  }

  get focusedRow(): any {
    return this._focusedRow;
  }

  set focusedRow(row: any) {
    if (row !== this._focusedRow) {
      this._focusedRow = row;
      if (this._focusedRow) {
        this.notifySelectionChange();
      }
    }
  }

  focusRowOffsetY(): number {
    const row = this.elRef.nativeElement.querySelector('.focused');
    const container = this.elRef.nativeElement.querySelector('.viewport');
    if (container && row) {
      return row.offsetTop - container.scrollTop;
    }
  }

  private notifySelectionChange(fireActionMultiEdit = false): void {
    if (this.selection.hasValue()) {
      this._focusedRow = undefined;
    }

    return this.onSelectionChange.next(new FlexTableSelectionChangeEvent(this._focusedRow, this.selection.selected, fireActionMultiEdit));
  }

  highlightRow(index: number): void {
    const nextRow = this.dataSource.data && this.dataSource.data[index];
    if (nextRow) {
      this.focusedRow = nextRow;
      const viewportSize: number = this.viewport.getViewportSize();
      const contentSize: number = index * this._tableConfig.rowHeight;

      const offset = contentSize > viewportSize ? contentSize - this._tableConfig.rowHeight : 0;
      this.viewport.scrollToOffset(offset);

      this.detectChanges();
    }
  }

  onExportCsv(exportFileName?: string): void {
    if (this.dataSource instanceof FlexDataSource) {
      this.dataSource.export_csv();
    }

    if (this.dataSource instanceof MatTableDataSource) {
      const data: any[] = [];

      // Csv header
      const headers: string[] = [];
      for (const headConfig of this._headerConfigs) {
        if (headConfig.exportable !== false) {
          headers.push(_ti(headConfig.label) || headConfig.name);
        }
      }
      const options: any = {
        fieldSeparator: ';',
        quoteStrings: '"',
        decimalseparator: '.',
        // showLabels: true,
        // showTitle: true,
        // title: 'Your title',
        // useBom: true,
        // noDownload: true,
        headers
      };

      // Csv content
      for (const row of this.dataSource.data) {
        const item: any = {};
        for (const headConfig of this._headerConfigs) {
          if (headConfig.exportable !== false) {
            item[headConfig.name] = headConfig.exportedValue
              ? headConfig.exportedValue(row)
              : this.getCellValue(row, headConfig.name);
          }
        }
        data.push(item);
      }

      const now = localNow().toISODate();
      const ret = new Angular5Csv(data, `${exportFileName || `exported`}_${now}`, options);
    }
  }

  // ngAfterContentInit(): void {
  //   console.log(this.columnTemplates); // undefined
  //   // console.log(this.model); // TemplateRef_ {...}
  // }

  getExpandedDetailTemplate(): TemplateRef<any> {
    if (this.expandedDetailTemplate) {
      return this.expandedDetailTemplate;
    }

    return this.defaultExpandedDetailTemplate;
  }

  getCellTemplate(col: IFlexTableColumnConfig): TemplateRef<any> {
    const colName = col.name;
    if (this.columnTemplates && colName && this.columnTemplates[colName]) {
      return this.columnTemplates[colName];
    }

    if (col.uiWidget && this.tableConfig && this.tableConfig.uiGetWidgetTemplate) {
      return this.tableConfig.uiGetWidgetTemplate(col.uiWidget);
    }

    return this.defaultColumnTemplate;
  }

  getCellValue(row: any, colName: string): string {
    const colDef: IFlexTableColumnConfig = this.columnDefMap[colName];
    if (colDef.renderCell) {
      return colDef.renderCell(row, colName);
    }

    return this.dataRenderService.renderData(row[colName], colDef.type);
  }

  getAlign(colName: string): string {
    const colDef: IFlexTableColumnConfig = this.columnDefMap[colName];
    if (colDef && colDef.align) {
      return colDef.align;
    }

    return this.align;
  }

  onRowDoubleClicked(row: any): void {
    this._focusedRow = null;
  }

  onRowClicked(row: any): void {
    console.log('click row', row);
    if (this.isReadonly(row)) {
      console.log('Row is readonly');
      return;
    }
    if (row !== this._focusedRow) {
      this._focusedRow = row;
    }

    if (this._focusedRow) {
      // Force send notification change even if user click on the already-focused row
      this.notifySelectionChange();
    }
  }

  onCheckboxChanged(row): void {
    this.selection.toggle(row);
  }

  isReadonly(row: any): boolean {
    // return row.isReadonly && row.isReadonly();
    return this.dataSource instanceof DynamicFilterSource && this.dataSource.isRowReadonly(row);
  }

  isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.filter(x => !this.isReadonly(x)).length;

    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle($event: MatCheckboxChange): void {
    if (!$event.checked) { //  || this.isAllSelected()
      this.selection.clear();
    } else {
      this.dataSource.data.forEach(row => !this.isReadonly(row) && this.selection.select(row));
    }
    this.detectChanges();
  }

  clearSelection(row: any): void {
    if (this.selection.isSelected(row)) {
      this.selection.deselect(row);
      this.detectChanges();
    }
  }

  async runActionCallback(item: IFlexTableAction): Promise<any> {
    await item.callback({ selectedItems: _.clone(this.selection.selected) });
    if (item.id !== MultipleEdit) {
      this.selection.clear();
    }
  }

  async handleScroll(event?: any): Promise<void> {
    if (!this.canLoadMore) {
      console.log('Remaining data is empty, not load more');
      // return Promise.resolve();
    }
    try {
      console.log('Scroll got called', event);
      if (this.dataSource instanceof FlexDataSource) {
        const ds = this.dataSource as FlexDataSource;
        const hasMore = await ds.load_more();
        console.log('data load more', hasMore);
        if (!hasMore) {
          this.canLoadMore = false;
        } else {
          this.canLoadMore = true;
        }
      }
    } catch (e) {
      console.error('FlexTable: Error while handing scroll event', e);
    }
    // scrolled ? this.getData() : _noop();
  }

  get totalItems(): number {
    if (this.dataSource instanceof FlexDataSource) {
      return this.dataSource.total || 0;
    }

    return (this.dataSource.data || []).length;
  }

  get hasPaginator(): boolean {
    // return (this.dataSource.data || []).length > this.length;
    return this.enablePaginator;
  }

  filterContainerVisible(): boolean {
    if (this.tableConfig.enableExportCsv || this.tableConfig.enableFilter) {
      return this.dataSource instanceof MatTableDataSource || (this.dataSource && (this.dataSource as FlexDataSource).columnLoaded);
    }

    return false;
  }

  isLoading(): boolean {
    return (this.dataSource as FlexDataSource).isLoading;
  }

  placeholderWhen = (index: number, _: any): boolean => {
    return (index === 0) && (this.placeholderHeight !== 0);
  }

  toggleDetail(e: Event, row: any): void {
    e.stopPropagation();
    this.expandedElement = this.expandedElement == row ? undefined : row;
    if (this.expandedElement && this.tableConfig && this.tableConfig.expandCallback) {
      this.tableConfig.expandCallback(row);
    }
  }

  get searchHintText(): string {
    if (!this._searchHintText) {
      this.setSearchHintText();
    }
    return this._searchHintText;
  }

  private setSearchHintText(): void {
    if (!_.isEmpty(this._headerConfigs)) {
      const labels: any[] = this._headerConfigs.filter(c => c.label && !_.hasIn(c, 'searchable') || c.searchable === true)
        .map(c => _ti(c.label));

      if (labels.length) {
        this._searchHintText = _ti('flex_table.search.hint', { columns: labels.join(', ') });
      }
    }
  }

  getNextPage(e: PageEvent): void {
    if (this.dataSource instanceof FlexDataSource) {
      const filters = this.dataSource.getFilter();
      filters.length = e.pageSize;
      this.dataSource.load_page(e.pageIndex);
    }
  }
}
