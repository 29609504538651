import { Component, OnInit, OnDestroy, Self, Optional, ElementRef, Input, ChangeDetectorRef } from '@angular/core';
import { FormControl, NgControl } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import { IFlexSelectOptions, FlexMatInputWrapper, regexSearch } from '@wephone-utils';
import { _ti } from '@wephone-translation';
import { ConfigManager } from '@wephone-core/wephone-core.module';

export interface ISipPhoneModel {
  label: string;
  value: string;
}

@Component({
  selector: 'sipphone-phone-model-input',
  templateUrl: './sipphone-phone-model-input.component.html',
  styleUrls: ['./sipphone-phone-model-input.component.scss'],
  providers: [{ provide: MatFormFieldControl, useExisting: SipphonePhoneModelInputComponent }]
})
export class SipphonePhoneModelInputComponent extends FlexMatInputWrapper
  implements OnInit, OnDestroy, MatFormFieldControl<ISipPhoneModel> {
  static nextId = 0;
  @Input() multiple: boolean;
  @Input() hasDefault = false;
  @Input() filterItem: (item: ISipPhoneModel) => boolean;

  flexSelect: FormControl = new FormControl();
  flexSelectOptions: IFlexSelectOptions;
  list: ISipPhoneModel[];

  static async getPhoneModelList(): Promise<ISipPhoneModel[]> {
    const list = await ConfigManager.getInstance().getPhoneModels() || [];
    return list;
  }

  static async getPhoneModel(model: string): Promise<ISipPhoneModel> {
    const list = await SipphonePhoneModelInputComponent.getPhoneModelList();
    return list.find((item: ISipPhoneModel) => item.value === model);
  }

  constructor(
    @Optional()
    @Self()
    ngControl: NgControl,
    elRef: ElementRef,
    cdr: ChangeDetectorRef
  ) {
    super(ngControl, elRef, cdr);
    this.flexSelectOptions = {
      filterFunc: (item: ISipPhoneModel, filterString: string) => {
        return regexSearch(item.label, filterString) || regexSearch(item.value, filterString);
      },
      compareWith: (a: ISipPhoneModel, b: ISipPhoneModel) => {
        return a && b && a.label === b.label;
      }
    };
  }

  get wrappedControl(): FormControl {
    return this.flexSelect;
  }

  async ngOnInit(): Promise<void> {
    await this._setList();

    if (this.required && !this.flexSelect.value) {
      this.flexSelect.setValue(this.list[0]);
    }
    super.ngOnInit();
    this.cdr.detectChanges();
  }

  private async _setList(): Promise<void> {
    this.list = await SipphonePhoneModelInputComponent.getPhoneModelList();
  }

  get controlType(): string {
    return 'sipphone-phone-model-select-form-input';
  }
}
