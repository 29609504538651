
<form [formGroup]="form">
    <div fxLayout="column" class="ma-1">
        <div>
            <mat-form-field class="full-width">
                <input matInput [placeholder]="'sipphone_config_template.content.group_name'|translate" formControlName="name" />
                
                <mat-error [flexFormControlError]="form.get('name')"
                    [customMessages]="{duplicated: 'public.message.name_exist'|translate}"
                    *ngIf="!form.get('name').valid && form.get('name').touched"></mat-error>
            </mat-form-field>
        </div>
    </div>
  </form>
  