<flex-select *ngIf="list"
              [placeholder]="placeholder" 
              [formControl]="flexSelect" 
              [enabled]="enabled"
              [itemList] = "list"
              [itemTemplate] = "itemTemplate"
              [options] = "flexSelectOptions"
              [multiple]="multiple">
  <ng-template #itemTemplate let-item="item">
    {{ item.label }}
  </ng-template>
</flex-select>
