import { Component, OnInit, ViewChild } from '@angular/core';
import { DynamicFilterSource, IFlexTableConfig, IFlexTableSidePannelOptions, DialogService, FlexCRUDPageComponent, regexSearch } from '@wephone-utils';
import { SipPhoneConfigTemplateRepository } from '@wephone-core/model/repository/sipphone_config_template';
import { SipPhoneConfigTemplateEntity } from '@wephone-core/model/entity/sipphone_config_template';
import { FlexBasePage } from '@wephone-core-ui';
import { _tk, _ti } from '@wephone-translation';

import { FormBuilder, FormGroup } from '@angular/forms';
import { SipPhoneConfigTemplateEditComponent } from '../sip-phone-config-template-edit/sip-phone-config-template-edit.component';
import { SipPhoneService } from '@wephone-common/service';
import { SipPhoneConfigTemplateAddComponent } from '../sip-phone-config-template-add/sip-phone-config-template-add.component';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { SipPhoneConfigGroupEntity } from '@wephone-core/model/entity/sipphone_config_group';
import { SipPhoneConfigGroupAddEditComponent } from '../sip-phone-config-group-add-edit/sip-phone-config-group-add-edit.component';

interface ISipPhoneConfigItem {
  name: string;
  action: number;
  entity: SipPhoneConfigTemplateEntity | SipPhoneConfigGroupEntity;
  type: 'template' | 'group';
}

@Component({
  selector: 'app-sip-phone-config-template',
  templateUrl: './sip-phone-config-template.component.html',
  styleUrls: ['./sip-phone-config-template.component.scss']
})
export class SipPhoneConfigTemplateComponent extends FlexBasePage implements OnInit {
  repo = SipPhoneConfigTemplateRepository.getInstance<SipPhoneConfigTemplateRepository>();
  dataSource: DynamicFilterSource;
  tableConfig: IFlexTableConfig;
  @ViewChild('flexCrud') flexCrud: FlexCRUDPageComponent;
  enterprises: any = {};
  form: FormGroup;

  private _dataSource: BehaviorSubject<ISipPhoneConfigItem[]>;

  constructor(
    private readonly sipPhoneService: SipPhoneService,
    private readonly dialogService: DialogService,
    private readonly fb: FormBuilder
  ) {
    super();
    this.repo = SipPhoneConfigTemplateRepository.getInstance<SipPhoneConfigTemplateRepository>();

    this._dataSource = new BehaviorSubject<ISipPhoneConfigItem[]>([]);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.initDS();
  }

  getDataSource<T extends ISipPhoneConfigItem = any>(): Observable<T[]> {
    return this._dataSource.asObservable() as Observable<T[]>;
  }

  initDS(): void {
    const datasource = this.getDataSource<ISipPhoneConfigItem>();
    this.dataSource = new DynamicFilterSource(datasource); // , this.filterFunc

    this.tableConfig = {
      scrollable: false,
      multiSelect: false,
      columns: [
        {
          name: 'name',
          label: _tk('sipphone_config_template.content.group_and_name'),
          sortable: false,
          searchable: false
        },
        {
          name: 'action',
          label: _tk('public.action'),
          sortable: false,
          searchable: false
        }
      ],
      listActions: {
        defaultActions: [
          // Visible action buttons when no item is selected
          {
            id: 'add',
            icon: 'add',
            callback: (): any => {
              return this.createSipPhoneConfigGroup();
            }
          }
        ]
      }
    };

    this.form = this.fb.group({
      filterString: []
    });

    this.addSubscription(
      this.form.valueChanges.subscribe(this.onFormValueChange)
    );

  }

  private onFormValueChange = (formValues): void => {
    const filterString = formValues.filterString && formValues.filterString.trim() || '';

    this.dataSource.filter = filterString;
    this.dataSource.onFilterChange();
  }

  editSipPhoneItem(item: ISipPhoneConfigItem): void {
    if (item.type === 'template') {
      this.dialogService.openDialog2(SipPhoneConfigTemplateEditComponent, {
        size: 'fs',
        data: {
          item: item.entity as SipPhoneConfigTemplateEntity
        }
      },
      resp => {
        if (resp) {
          this.updateDatasource();
        }
      });
    } else if (item.type === 'group') {
      this.dialogService.openDialog3(_ti('sipphone_config_group.title.update'), SipPhoneConfigGroupAddEditComponent, {
        size: 'xs',
        data: {
          item: item.entity as SipPhoneConfigGroupEntity
        }
      },
      resp => {
        if (resp) {
          this.updateDatasource();
        }
      });
    }
  }

  deleteSipPhoneItem(item: ISipPhoneConfigItem): Promise<void> {
    return this.dialogService.confirmDialog(
      _ti('dialogs.confirmation'),
      _ti(item.type === 'group' ? 'sipphone_config_group.message.confirm_delete_group' : 'user.title.delete'),
      async () => {
        try {
          if (item.type === 'template') {
            await this.sipPhoneService.deleteSipPhoneConfigTemplates([item.entity as SipPhoneConfigTemplateEntity]);
          } else if (item.type === 'group') {
            await this.sipPhoneService.deleteSipPhoneConfigGroups([item.entity as SipPhoneConfigGroupEntity]);
          }

          this.updateDatasource();

          this.successToast(_ti('public.message.delete_success'));
        } catch (e) {
            this.showErrorMessage(e,  _ti('public.message.delete_failure'));
          }
      }
    );
  }

  private async createSipPhoneConfigGroup(): Promise<void> {
    const dialogRef = this.dialogService.openDialog2(SipPhoneConfigGroupAddEditComponent, { size: 'xs' });
    const createdItem = await dialogRef.afterClosed().toPromise();
    if (!createdItem) {
      return;
    }

    await this.updateDatasource();
  }

  async createSipPhoneConfigTemplate(group: SipPhoneConfigGroupEntity): Promise<void> {
    const dialogRef = this.dialogService.openDialog2(SipPhoneConfigTemplateAddComponent, { 
      size: 'xs',
      data: {
        group
      }
    });

    const createdItem = await dialogRef.afterClosed().toPromise();
    if (!createdItem) {
      return;
    }

    // this.router.navigate(['telephony', 'sip-phone-config-template', createdItem.id], {skipLocationChange: false});
    await this.updateDatasource();
  }

  protected async resolveData(): Promise<any> {
    await this.updateDatasource();
  }

  async updateDatasource(): Promise<void> {
    const lists = await this.sipPhoneService.listSipPhoneConfigTemplates();

    const groups = lists.groups;
    const templates = lists.templates;

    const ret: ISipPhoneConfigItem[] = [];
    for (const g of groups) {
      ret.push({ name: g.name, action: g.id, entity: g, type: 'group' });

      for (const t of templates) {
        if (t.group_id === g.id) {
          ret.push({ name: t.name,  action: t.id, entity: t, type: 'template' });
        }
      }
    }

    this._dataSource.next(ret);
  }
}
