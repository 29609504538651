<div fxFill fxLayout="column">
  <div class="wrapper">
    <div class="col-left">
      <div class="inner">
        <ul class="stepper stepper-vertical">
          <li *ngFor="let step of steps | keyvalue; let i=index;">
            <a (click)="mainArea.scrollToElement(step.key)" class="step {{ step.key }}"
              [ngClass]="{'active': mainArea.activeAnchor === step.key}">
              <span class="circle">{{ i+1 }}</span>
              <span class="label">{{ step.value | translate }}</span>
            </a>
          </li>
        </ul>
      </div>
    </div>

    <div class="col-right activeAnchor" flexAnchorArea #mainArea="flexAnchorArea">
      <div class="inner">
        <ng-container *ngTemplateOutlet="tplEditForm"></ng-container>

        <ng-container *ngIf="!uiSipTrunk">
          <mat-divider [inset]="true" class="section-divider"></mat-divider>

          <div id="_3inused" anchorPart>
            <h1 class="font-weight-bold">{{ 'sip_trunk.content.using'|translate }}</h1>
            <ng-container *ngIf="sipTrunk.routedDids.length; else tplNoUsedData">
              <div fxLayout="row wrap" fxLayoutAlign="start center" class="pb-1 full-width">
                <div class="inused-item" *ngFor="let item of sipTrunk.routedDids" (click)="gotoEditDidPage(item)">
                  <app-did-mini-block [did]="item"></app-did-mini-block>
                </div>
              </div>
            </ng-container>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>

<ng-template #tplNoUsedData>
  <p>{{ 'sip_trunk.content.no_data'|translate }}</p>
</ng-template>

<ng-template #tplEditForm>
  <form [formGroup]="form" (ngSubmit)="submitForm()" *ngIf="form" id="_1general" anchorPart>
    <div align="end">
      <mat-slide-toggle *ngIf="uiSipTrunk" formControlName="is_default" color="primary"
        [disabled]="!!sipTrunk.is_default">
        <span class="fontfamily-bold"> {{ 'siptrunk.form.set_as_default' | translate }}</span>
      </mat-slide-toggle>
    </div>

    <mat-form-field class="full-width">
      <mat-label>{{'siptrunk.form.name'|translate}}</mat-label>
      <input matInput formControlName="name" [placeholder]="'siptrunk.form.name'|translate" data-ci="name">

      <mat-error [flexFormControlError]="form.get('name')"></mat-error>
    </mat-form-field>

    <div fxLayout.xs="column" fxLayout="row" fxLayoutAlign.gt-xs="start center" fxLayoutGap.xs="0" fxLayoutGap="10px">
      <div fxFlex.xs="none" fxFlex="50">
        <mat-form-field class="full-width">
          <mat-label>{{'dialogs.setup_sipphone.label.domain'|translate}}</mat-label>
          <input matInput [value]="sipDomain" [disabled]="true" class="sip-domain">
          <button matSuffix type="button" mat-icon-button [disabled]="!sipDomain" (click)="copyToClipboard(sipDomain)"
            title="{{ 'clipboard.copy_title'|translate }}">
            <mat-icon>content_copy</mat-icon>
          </button>
        </mat-form-field>
        <div fxFlex.xs="none" fxFlex="50"></div>
      </div>
    </div>

    <div fxLayout="row" fxFlexFill fxLayoutGap="10px">
      <div fxFlex="50">
        <mat-form-field class="full-width">
          <mat-label>{{'siptrunk.form.username'|translate}}</mat-label>
          <input matInput formControlName="username" [placeholder]="'siptrunk.form.username'|translate"
            data-ci="username">

          <mat-error [flexFormControlError]="form.get('username')"></mat-error>
        </mat-form-field>
      </div>
      <div fxFlex="50">
        <mat-form-field class="full-width">
          <mat-label>{{'siptrunk.form.password'|translate}}</mat-label>
          <input matInput formControlName="password" [type]="hidePassword ? 'password' : 'text'"
            [placeholder]="'siptrunk.form.password'|translate" data-ci="password">
          <div matSuffix fxLayout="row" fxLayoutAlign="start center">
            <button type="button" mat-icon-button (click)="hidePassword = !hidePassword"
              title="{{ (hidePassword ? 'public.show_pw' : 'public.hide_pw')|translate }}">
              <mat-icon>{{ hidePassword ? 'visibility_off' : 'visibility' }}</mat-icon>
            </button>
            <button type="button" mat-icon-button [disabled]="!form.get('password').value"
              (click)="copyToClipboard(form.get('password').value)"
              [matTooltip]="form.get('password').value ? ('clipboard.copy_title'|translate) : ''">
              <mat-icon>content_copy</mat-icon>
            </button>
          </div>

          <mat-error [flexFormControlError]="form.get('password')"></mat-error>
        </mat-form-field>
      </div>
    </div>

    <div fxLayout.xs="column" fxLayout="row" fxLayoutAlign.gt-xs="start center" fxLayoutGap.xs="0" fxLayoutGap="10px">
      <div fxFlex.xs="none" fxFlex="50">
        <mat-slide-toggle formControlName="backup_number_enable" color="primary">
          <span class="fontfamily-bold"> {{ 'siptrunk.form.backup_number_enable' | translate }}</span>
        </mat-slide-toggle>
      </div>
      <div fxFlex.xs="none" fxFlex="50">
        <mat-slide-toggle formControlName="sip_ping_enable" color="primary">
          <span class="fontfamily-bold"> {{ 'siptrunk.form.sip_ping_enable' | translate }}</span>
        </mat-slide-toggle>
      </div>
    </div>

    <div *ngIf="form.get('backup_number_enable').value">
      <mat-form-field class="full-width">
        <mat-label>{{'siptrunk.form.backup_number'|translate}}</mat-label>
        <input matInput formControlName="backup_number" [placeholder]="'siptrunk.form.backup_number'|translate"
          data-ci="backup_number">
        <button type="button" mat-icon-button matSuffix data-ci="clear-backup-number"
          (click)="clearValue($event, 'backup_number')" [disabled]="!form.get('backup_number').value">
          <mat-icon>clear</mat-icon>
        </button>

        <mat-error [flexFormControlError]="form.get('backup_number')"
          *ngIf="form.get('backup_number').invalid && form.get('backup_number').touched"></mat-error>
      </mat-form-field>
    </div>

    <h2 class="pt-2">{{ 'siptrunk.form.primary_proxy'|translate }}</h2>
    <div fxLayout="row" fxLayoutAlign="start center" fxFlexFill fxLayoutGap="10px">
      <div fxFlex="50">
        <mat-form-field class="full-width">
          <mat-label>{{'siptrunk.form.host'|translate}}</mat-label>
          <input matInput formControlName="proxy_host" [placeholder]="'siptrunk.form.host'|translate">

          <mat-error [flexFormControlError]="form.get('proxy_host')"></mat-error>
        </mat-form-field>
      </div>
      <div fxFlex="50">
        <mat-form-field class="full-width">
          <mat-label>{{'siptrunk.form.port'|translate}}</mat-label>
          <input matInput placeholder="{{'siptrunk.form.port'|translate}}" formControlName="proxy_port" type="number">

          <mat-error [flexFormControlError]="form.get('proxy_port')"></mat-error>
        </mat-form-field>
      </div>
    </div>

    <h2 class="pt-2">{{ 'siptrunk.form.secondary_proxy'|translate }}</h2>
    <div>
      <mat-form-field class="full-width">
        <mat-label>{{'siptrunk.form.mode'|translate}}</mat-label>
        <mat-select formControlName="secondary_proxy_mode">
          <mat-option [value]="0">{{'siptrunk.form.disabled'|translate}}</mat-option>
          <mat-option [value]="1">{{'siptrunk.form.load_balancing'|translate}}</mat-option>
          <mat-option [value]="2">{{'siptrunk.form.fail_over'|translate}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div fxLayout.xs="column" fxLayout="row" fxLayoutAlign.gt-xs="start center" fxLayoutGap.xs="0" fxLayoutGap="10px"
      *ngIf="form.get('secondary_proxy_mode').value != 0">
      <div fxFlex.xs="none" fxFlex="50">
        <mat-form-field class="full-width">
          <mat-label>{{'siptrunk.form.host'|translate}}</mat-label>
          <input matInput formControlName="secondary_proxy_host" [placeholder]="'siptrunk.form.host'|translate">

          <mat-error [flexFormControlError]="form.get('secondary_proxy_host')" [customMessages]="{
            maxlength: 'form.validator.max_length' | translate: {
              max: form.get('secondary_proxy_host').errors?.maxlength?.requiredLength
            }
          }"></mat-error>
        </mat-form-field>
      </div>
      <div fxFlex.xs="none" fxFlex="50">
        <mat-form-field class="full-width">
          <mat-label>{{'siptrunk.form.port'|translate}}</mat-label>
          <input matInput formControlName="secondary_proxy_port" type="number" min="10"
            [placeholder]="'siptrunk.form.port'|translate">

          <mat-error [flexFormControlError]="form.get('secondary_proxy_port')"></mat-error>
        </mat-form-field>
      </div>
    </div>
    <div>
      <mat-slide-toggle formControlName="outgoing_enabled" color="primary">
        {{'siptrunk.form.outgoing_enabled'|translate}}
      </mat-slide-toggle>
    </div>
    <div>
      <mat-form-field class="full-width">
        <mat-label>{{'siptrunk.form.alias'|translate}}</mat-label>
        <input matInput formControlName="alias" [placeholder]="'siptrunk.form.alias'|translate">
        <button type="button" mat-icon-button matSuffix data-ci="clear-channel-max"
          (click)="clearValue($event, 'alias')" [disabled]="!form.get('alias').value">
          <mat-icon>clear</mat-icon>
        </button>

        <mat-error [flexFormControlError]="form.get('alias')"></mat-error>
      </mat-form-field>
    </div>
    <div>
      <mat-form-field class="full-width">
        <mat-label>{{'siptrunk.form.channel_max'|translate}}</mat-label>
        <input type="number" matInput formControlName="channel_max" min="0"
          [placeholder]="'siptrunk.form.channel_max'|translate" data-ci="channel_max">
        <button type="button" mat-icon-button matSuffix data-ci="clear-channel-max"
          (click)="clearValue($event, 'channel_max')" [disabled]="!form.get('channel_max').value">
          <mat-icon>clear</mat-icon>
        </button>

        <mat-error [flexFormControlError]="form.get('channel_max')"></mat-error>
      </mat-form-field>
    </div>
  </form>

  <mat-divider [inset]="true" class="section-divider"></mat-divider>

  <mat-card class="shadow-none ma-0 pa-0" id="_2sip_info" anchorPart>
    <mat-card-header>
      <div mat-card-avatar>
        <h2><i class="material-icons">sip</i></h2>
      </div>
      <mat-card-title>
        <h2>{{ 'sipphone.content.sip_connection_info' | translate }}</h2>
      </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <app-sip-connection-info
        [data]="{username: sipTrunk?.username, password: sipTrunk?.password}"></app-sip-connection-info>
    </mat-card-content>
  </mat-card>
</ng-template>
