import * as _ from 'lodash';

import { Component, Inject, OnInit } from '@angular/core';
import { Colors, DialogActionButton, FormService, ToastService } from '@wephone-utils';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogComponentBase } from '@wephone-core-ui';
import { _tk, _ti } from '@wephone-translation';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EnterpriseEntity } from '@wephone-core/model/entity/enterprise';
import { SipPhoneConfigTemplateEntity } from '@wephone-core/model/entity/sipphone_config_template';
import { SipPhoneService } from '@wephone-common/service';
import { SipPhoneConfigGroupEntity } from '@wephone-core/model/entity/sipphone_config_group';


@Component({
  selector: 'app-sip-phone-config-template-add',
  templateUrl: './sip-phone-config-template-add.component.html',
  styleUrls: ['./sip-phone-config-template-add.component.scss']
})
export class SipPhoneConfigTemplateAddComponent extends DialogComponentBase implements OnInit {
  dialogTitle = _tk('sipphone_config_template.title.create');
  dialogRightActions: DialogActionButton[] = [
    {
      label: _tk('public.apply'),
      action: () => {
        this.submitForm();
      },
      visible: () => {
        return true;
      },
      color: Colors.PRIMARY
    }
  ];

  form: FormGroup;
  group: SipPhoneConfigGroupEntity;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
      group: SipPhoneConfigGroupEntity
    },
    dialogRef: MatDialogRef<SipPhoneConfigTemplateAddComponent>,
    private readonly fb: FormBuilder,
    private readonly sipPhoneService: SipPhoneService,
    private readonly toast: ToastService,
    private readonly formService: FormService,
  ) {
    super(dialogRef);

    this.group = data.group;
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.form = this.fb.group({
      name: [ '', [Validators.required, Validators.maxLength(128)] ],
      model: [ '', [Validators.required, Validators.maxLength(64)] ],
      version: [ '', [Validators.maxLength(64)] ]
    });
  }

  async submitForm(): Promise<void> {
    try {
      if (!this.form.valid) {
        console.error('Form invalid', this.form);
        this.form.markAllAsTouched();
  
        this.updateDialogLayout();
        throw new Error(_ti('public.message.data_invalid'));
      }
  
      const createValue: any = {
        group_id: this.group.id,
        name: this.form.value.name,
        model: this.form.value.model ? this.form.value.model.value : null,
        version: this.form.value.version,
      };

      const createdItem: SipPhoneConfigTemplateEntity = await this.sipPhoneService.addSipPhoneConfigTemplate(createValue);

      if (!createdItem) {
        throw new Error('An error occurs');
      }

      console.log('responsed sipPhoneConfigTemplate', createdItem);
      this.toast.showSuccess(_ti('public.message.create_success'));
      this.dismiss(createdItem, false);
    } catch (e) {
      this.formService.handleResponsedError(e, this.form);
      console.error('Save sip phone config template error', e);
      this.toast.showErrorMessage(e, _ti('public.message.create_failure'));
    } finally {
      this.updateDialogLayout();
    }
  }
}
