import { datafield, Entity } from '@wephone-core/model/model';

export class SipPhoneConfigGroupEntity extends Entity {
  static object_type_id = 'sipphone_config_group';

  @datafield
  id: number;

  @datafield
  name: string;
}
