<net-status></net-status>
<mat-toolbar class="main-header gradient-background">
  <button [matTooltip]="(menuOpen ? 'topbar.action.click_to_close':'topbar.action.click_to_open')|translate"
    (click)="toggleSidenav.emit()" mat-icon-button>
    <mat-icon *ngIf="menuOpen">menu_open</mat-icon>
    <mat-icon *ngIf="!menuOpen">menu</mat-icon>
  </button>
  <div class="branding">
    <img src="assets/images/logo-graam-white.png" alt="Graam" title="Graam" />
  </div>
  <div fxHide.xs="true" class="search-bar" fxFlex fxLayout="column">
    <div class="search-wrapper" (clickOutside)="clearGlobalSearch()" *ngIf="isAdmin || isSupervisor">
      <flex-search-input class="search-text" [(ngModel)]="globalSearchText" [ngModelOptions]="{standalone: true}"
        (ngModelChange)="onGlobalSearchChange($event)" [placeholder]="'topbar.action.search'|translate"
        autofocus="true"></flex-search-input>
      <div class="global-search-wrapper" *ngIf="globalSearch && globalSearchText">
        <app-global-search class="global-search" [keyword]="globalSearch">
        </app-global-search>
      </div>
    </div>
    <!-- <form class="search-form">
      <mat-icon [matTooltip]="'topbar.action.search'|translate">search</mat-icon>
      <input class="search-text" type="search" [(ngModel)]="globalSearchText" [ngModelOptions]="{standalone: true}"
        (ngModelChange)="onGlobalSearchChange($event)" [placeholder]="'topbar.action.search'|translate"
        autofocus="true" />
    </form> -->
  </div>
  <div class="ml-auto">
    <ng-container *ngIf="!uiSipTrunk">
      <button mat-icon-button [matTooltip]="'user.content.connect_as' | translate" (click)="connectAs($event, 'phone')"
        *ngIf="canConnectAsPhone">
        <mat-icon attr.aria-label="{{'user.content.connect_as'|translate}}">phonelink_lock</mat-icon>
      </button>
      <button [matTooltip]="'topbar.action.show_sipphone_provisioning_config'|translate"
        data-ci="manage-ip-phone-provisioning-token" (click)="showConfigRequests()" mat-icon-button
        *ngIf="showSipphoneProvisioning">
        <mat-icon>token</mat-icon>
      </button>
    </ng-container>
    <!-- <toolbar-menu-shortcuts></toolbar-menu-shortcuts> -->
    <button [matTooltip]="'topbar.action.go_fullscreen'|translate" fxHide.lt-md="true" (click)="fullScreenToggle()"
      mat-icon-button>
      <mat-icon>fullscreen</mat-icon>
    </button>
    <button (click)="toggleNotificationSidenav.emit()" mat-icon-button class="ml-xs overflow-visible" *ngIf="devMode">
      <mat-icon>notifications</mat-icon>
      <span class="notification-label">5</span>
    </button>
    <app-language-swiching class="language-swiching"></app-language-swiching>
    <button [matMenuTriggerFor]="user" mat-icon-button class="ml-xs" data-ci="current-user-button">
      <mat-icon></mat-icon>
      <span class="user-avatar">
        <user-avatar [uploadAvatar]="false" width="25px" height="25px" [user]="loggedUser" [title]="loggedUser?.name">
        </user-avatar>
      </span>
    </button>
    <mat-menu #user="matMenu" x-position="before">
      <button mat-menu-item *ngIf="loggedUser" (click)="showUserProfile()">
        <mat-icon>account_box</mat-icon>
        {{ loggedUser?.name }}
      </button>
      <button mat-menu-item *ngIf="myenterprise">
        <mat-icon>business</mat-icon>
        {{ myenterprise.name }}
      </button>
      <button mat-menu-item (click)="logout()" data-ci="logout">
        <mat-icon>exit_to_app</mat-icon>
        {{ 'menu.logout' | translate }}
      </button>
    </mat-menu>
  </div>
</mat-toolbar>

<div fxLayoutAlign="end start" fxLayoutAlign.xs="center start" [hidden]="!isPlayerVisible()">
  <fileentry-player class="player" fxFlex="350px" fxFlex.xs="100"></fileentry-player>
</div>
