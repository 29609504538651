import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { _tk, _ti } from '@wephone-translation';
import * as _ from 'lodash';
import { ConfigManager } from '@wephone-core/wephone-core.module';
import { EnterpriseParamDict } from '@wephone-core/system';
import { EnterpriseService } from '@wephone/services/enterprise.service';
import { EditingComponent, ToastService } from '@wephone-utils';
import { Text2SpeechService } from '@wephone-core/model/entity/enterprise_parameters.i';

@Component({
  selector: 'app-enterprise-parameters',
  templateUrl: './enterprise-parameters.component.html',
  styleUrls: ['./enterprise-parameters.component.scss']
})
export class EnterpriseParametersComponent extends EditingComponent implements OnInit {
  enterpriseParams: EnterpriseParamDict;
  form: FormGroup;
  constructor(
    private readonly fb: FormBuilder,
    private readonly configService: ConfigManager,
    private readonly enterpriseService: EnterpriseService,
    private readonly toast: ToastService,
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    
    this.setEnterpriseParams();
    this.form = this.fb.group({
      cc_allow_call_direction_selection: [this.enterpriseParams.cc_allow_call_direction_selection],
      disable_phone_in_iframe: [this.enterpriseParams.disable_phone_in_iframe],
      agent_auto_disconnect: [this.enterpriseParams.agent_auto_disconnect],
      dialer_queue_selector: [this.enterpriseParams.dialer_queue_selector],
      my_own_recorded_calls: [this.enterpriseParams.my_own_recorded_calls],
      inbound_on_secondary_number: [this.enterpriseParams.inbound_on_secondary_number],
      call_quality_enable: [this.enterpriseParams.call_quality_enable],
      call_analysis_enable: [this.enterpriseParams.call_analysis_enable],
      text2speech_service: [this.enterpriseParams.text2speech_service],
      eleven_labs_key: [this.enterpriseParams.eleven_labs_key],
    });

    this.form.valueChanges.subscribe(() => {
      this.onFormValueChange();
    });
  }

  private setEnterpriseParams(): void {
    this.enterpriseParams = this.configService.getEnterpriseParams();
  }

  resetForm(): void {
    const params = this.enterpriseParams;
    this.form.reset({
      cc_allow_call_direction_selection: params.cc_allow_call_direction_selection,
      disable_phone_in_iframe: params.disable_phone_in_iframe,
      agent_auto_disconnect: params.agent_auto_disconnect,
      dialer_queue_selector: params.dialer_queue_selector,
      my_own_recorded_calls: params.my_own_recorded_calls,
      call_quality_enable: params.call_quality_enable,
      inbound_on_secondary_number: params.inbound_on_secondary_number,
      call_analysis_enable: params.call_analysis_enable,
      text2speech_service: params.text2speech_service,
      eleven_labs_key: params.eleven_labs_key,
    });
    this.form.markAsPristine();
  }

  getChangeSet(): {
    cc_allow_call_direction_selection: 1 | 0;
    disable_phone_in_iframe: 1 | 0;
    agent_auto_disconnect: 1 | 0;
    dialer_queue_selector: 1 | 0;
    my_own_recorded_calls: 1 | 0;
    call_quality_enable: 1 | 0;
    inbound_on_secondary_number: 1 | 0;
    call_analysis_enable: 1 | 0;
    text2speech_service?: Text2SpeechService;
    eleven_labs_key?: string;
  } {
    const data = super.getChangeSet();
    for (const field of Object.keys(data)) {
      if (!_.includes([
        'eleven_labs_key',
        'text2speech_service'
      ], field)) {
        data[field] = data[field] ? 1 : 0;
      }
    }
    return data;
  }

  async update(): Promise<void> {
    try {
      const data = this.getChangeSet();
      await this.enterpriseService.updateEnterpriseParammeters(data);

      for (const key of Object.keys(data)) {
        this.configService.setEnterpriseParam(key, data[key]);
      }

      this.setEnterpriseParams();
      this.resetForm();
      this.toast.showInfo(_ti('public.message.update_success'));
    } catch (error) {
      console.log('Error: ', error);
      this.toast.showErrorMessage(error, _ti('public.message.update_failure'));
    }
  }
}
