import { Component, OnInit, Input, SimpleChanges, OnChanges, OnDestroy } from '@angular/core';
import { AgentEntity } from '@wephone-core/model/entity/agent';
import { SipPhoneEntity } from '@wephone-core/model/entity/sipphone';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { EditingComponent, NoWhitespaceValidator, PhoneNumberValidator } from '@wephone-utils';
import { _tk, _ti } from '@wephone-translation';
import { PhoneNumberOnlyDigitValidated } from '@wephone/services/form-validator';
import { UserEntity } from '@wephone-core/model/entity/user';
// import { ValidatorService } from '@wephone-utils/services/validator.service';
import * as _ from 'lodash';
import { UserService } from '@wephone/services/user.service';
import { AgentPhoneMode } from '@wephone-core/system';

@Component({
  selector: 'user-call-reception',
  templateUrl: './user-call-reception.component.html',
  styleUrls: ['./user-call-reception.component.scss']
})
export class UserCallReceptionComponent extends EditingComponent implements OnInit, OnChanges, OnDestroy {
  @Input() agent: AgentEntity;
  @Input() numberEditable: boolean;
  @Input() showAutoPickup: boolean;

  sipphone: SipPhoneEntity;
  user: UserEntity;
  phoneModeType = AgentPhoneMode;

  private hasInitialize: boolean;

  constructor(
    private readonly fb: FormBuilder,
    // private readonly validatorService: ValidatorService,
    private readonly userService: UserService
  ) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.hasInitialize) {
      return;
    }

    this.initForm();

    if (!changes['agent'].isFirstChange()) {
      this.phoneModeCtrl.setValue(this.agent.phone_mode, { onlySelf: true, emitEvent: false });
      this.backupPhoneCtrl.setValue(this.agent.user.phone, { onlySelf: true, emitEvent: false });
      this.updateBackupPhone(this.agent.phone_mode);
    }
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.initForm();
    this.hasInitialize = true;
  }

  initForm(): void {
    const agent = _.cloneDeep(this.agent);
    this.user = agent.user as UserEntity;
    this.sipphone = this.user && this.user.sipphone;

    this.form = this.fb.group({
      phoneMode: [this.agent.phone_mode],
      backupPhone: [{ value: this.agent.user.phone, disabled: this.agent.phone_mode !== 1 }],
      auto_pickup: [this.agent.user.auto_pickup],
    });

    this.setBackupPhoneValidation();

    this.addSubscription(
      this.phoneModeCtrl.valueChanges.subscribe(val => {
        this.setBackupPhoneValidation();
      })
    );

    this.addSubscription(
      this.form.valueChanges.subscribe(val => {
        this.updateBackupPhone(val.phoneMode);
        this.onFormValueChange();
      })
    );
  }

  get phoneModeCtrl(): FormControl {
    return this.form.get('phoneMode') as FormControl;
  }

  get backupPhoneCtrl(): FormControl {
    return this.form.get('backupPhone') as FormControl;
  }

  private setBackupPhoneValidation(): void {
    this.backupPhoneCtrl.setErrors(null);
    this.backupPhoneCtrl.clearValidators();

    const backupPhoneValidattors = [NoWhitespaceValidator, PhoneNumberOnlyDigitValidated(), PhoneNumberValidator, Validators.maxLength(20)];
    if (this.phoneModeCtrl.value === AgentPhoneMode.BACKUP_NUMBER) {
      backupPhoneValidattors.push(Validators.required);
    }
    this.backupPhoneCtrl.addValidators(backupPhoneValidattors);
    this.backupPhoneCtrl.updateValueAndValidity();
  }

  resetForm(): void {
    if (!this.form) {
      return;
    }

    this.form.reset({
      phoneMode: this.agent.phone_mode,
      backupPhone: this.agent.user.phone,
      auto_pickup: this.agent.user.auto_pickup,
    });
    this.form.markAsPristine();
    this.onFormValueChange();
  }

  updateBackupPhone(phoneMode: number): void {
    if (phoneMode !== 1) {
      this.backupPhoneCtrl.reset(this.agent.user.phone, {
        onlySelf: true,
        emitEvent: false,
      });
      this.backupPhoneCtrl.disable({ emitEvent: false });
      return;
    }
    this.backupPhoneCtrl.enable({ emitEvent: false });
    return;
  }

  isAvailableSipphoneExtension(): string {
    return this.sipphone && this.sipphone.extension || '';
  }

  isValid(): boolean {
    if (this.backupPhoneCtrl.valid) {
      this.backupPhoneCtrl.setErrors(null);
    }

    if (this.phoneModeCtrl.value === AgentPhoneMode.BACKUP_NUMBER) {
      const phone: string = this.backupPhoneCtrl.value && this.backupPhoneCtrl.value.trim() || '';
      if (phone) {
        if (!this.userService.isValidSecondaryPhoneNumber(phone)) {
          this.backupPhoneCtrl.setErrors({ isForbidden: true });
        }
      }
    }

    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return false;
    }

    return true;
  }

  getChangeSet(): any {
    if (this.form.dirty) {
      return this.form.value;
    }

    return {};
  }
}
