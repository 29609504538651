export enum UserRole {
  SUPER_ADMIN = 'ROLE_SUPER_ADMIN',
  SUPER_ACCOUNTANT = 'ROLE_SUPER_ACCOUNTANT',
  ADMIN = 'ROLE_ADMIN',
  SUPERVISOR = 'ROLE_SUPERVISOR',
  USER = 'ROLE_ENTERPRISE_USER',
  ACCOUNTANT = 'ROLE_ACCOUNTANT',
  AGENT = 'ROLE_AGENT',
  WATCHER = 'ROLE_WATCHER',
  API_SYSTEM = 'ROLE_API_SYSTEM',
  API_IVRENGINE = 'ROLE_API_IVRENGINE',
  API_OAT = 'ROLE_API_OAT',
  API_SMS_PROVIDER = 'ROLE_API_SMS_PROVIDER',
  API_SMS_ENTERPRISE_ADMIN = 'ROLE_API_ENTERPRISE_ADMIN',
}

export const EnterpriseUserRoles = [
  UserRole.ADMIN,
  UserRole.ACCOUNTANT,
  UserRole.USER,
  UserRole.SUPERVISOR,
  UserRole.WATCHER,
  UserRole.AGENT
];

export enum UserType {
  SUPER_ADMIN = 'super_admin',
  SUPER_ACCOUNTANT = 'super_accountant',
  ACCOUNTANT = 'accountant',
  ADMIN = 'admin',
  SUPERVISOR = 'supervisor',
  AGENT = 'agent',
  WATCHER = 'watcher',
  USER = 'user',
  API = 'api',
  API_SYSTEM = 'api_system',
  API_IVRENGINE = 'api_ivrengine',
  API_SMS_PROVIDER = 'api_sms_provider',
}

export const BoDomain = '*';

export enum UserPhoneMode {
  CALLCENTER = 0,
  OFFICE = 1
}

export enum AgentPhoneMode {
  SIP_PHONE = 0,
  BACKUP_NUMBER = 1,
  BOTH = 2
}

export enum BusinessType {
  ENTERPRISE = 'bt_enterprise',
  CALLCENTER = 'bt_callcenter',
  HOTEL = 'bt_hotel',
  RESELLER = 'bt_reseller',
  SIPTRUNK = 'bt_siptrunk'
}

export enum UI_MODE {
  ENTERPRISE = 'ui_enterprise',
  CALLCENTER = 'ui_callcenter',
  HOTEL = 'ui_hotel',
  RESELLER = 'ui_reseller',
  SIPTRUNK = 'ui_siptrunk'
}

export enum AgentInOutState {
  BOTH = 0,
  INBOUND = 1,
  OUTBOUND = 2
}

export enum TTSService {
  GOOGLE_CLOUD = 'google_cloud',
  ELEVEN_LABS = 'eleven_labs',
}

export enum SystemParam {
  sip_domain = 'sip_domain',
  sip_port = 'sip_port',
  telecom_has_anonymous = 'telecom_has_anonymous',
  adyen_public_key = 'adyen_public_key',
  stripe_publishable_key = 'stripe_publishable_key',
  recorded_call_url = 'recorded_call_url',
  detail_call_url = 'detail_call_url',
  phone_number_provider = 'phone_number_provider',
  tts_service = 'tts_service',
  provisioning_url = 'provisioning_url'
}

export enum EnterpriseParam {
  direction_selection = 'cc_allow_call_direction_selection',
  dialer_queue_selector = 'dialer_queue_selector',
  agent_auto_disonnect = 'agent_auto_disconnect',
  buy_number_from_provider = 'buy_number_from_provider',
  onboarding_done = 'onboarding_done',
  subscription_expired = 'subscription_expired',
  my_own_recorded_calls = 'my_own_recorded_calls',
  sipphone_provisioning = 'sipphone_provisioning',
  inbound_on_secondary_number = 'inbound_on_secondary_number',
  conferencing = 'conferencing',
  speech2text = 'speech2text',
  group_management = 'group_management',
  disable_phone_in_iframe = 'disable_phone_in_iframe',
  sms_enable = 'sms_enable',
  call_quality_enable = 'call_quality_enable',
  call_analysis_enable = 'call_analysis_enable',
  cc_license = 'cc_license',
  sip_trunk_enable = 'sip_trunk_enable',
  text2speech_service = 'text2speech_service',
  eleven_labs_key = 'eleven_labs_key'
}

export enum UserParam {
  open_bo_on_call = 'open_bo_on_call',
  iframe_bo_position = 'iframe_bo_position',
  ringtone_id = 'ringtone_id',
  mfa_enabled = 'mfa_enabled'
}

export enum AccessRight {
  LivePage = 'LIVEPAGE',
  CallHistory = 'CALLHISTORY',
  Stats = 'STATS',
  VoiceMail = 'VOICEMAIL',
  Recording = 'RECORDING',
  Eavesdrop = 'EAVESDROP',
}

export enum FeatureName {
  SERVICE_GROUP = 'SERVICE_GROUP',
  SMS = 'SMS',
  SIP_TRUNK = 'SIP_TRUNK',
}

export type EnterpriseParamDict = {
  [key in EnterpriseParam]: number|string;
};

export type UserParamDict = {
  [key in UserParam]: number;
};
