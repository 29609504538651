import { Component, OnInit, ChangeDetectorRef, ViewChild, Input, SimpleChanges, OnChanges, OnDestroy } from '@angular/core';
import { FlexBaseComponent } from '@wephone-core-ui';
import { LiveStatsService, UserStatsDailyLiveOverview } from '@wephone-core/service/live-stats.service';
import { interval } from 'rxjs';
import { Router } from '@angular/router';
import { Chart } from 'angular-highcharts';
import { HighChartsHelperService } from '@wephone-core/service/high_charts_helper.service';
import * as _ from 'lodash';

interface CallsStatsDaily {
  listened_voicemail: number;
  listened_voicemail_percent: number;
  handled_missedcall: number;
  handled_missedcall_percent: number;
}

@Component({
  selector: 'qos',
  templateUrl: './qos.component.html',
  styleUrls: ['./qos.component.scss']
})
export class QosComponent extends FlexBaseComponent implements OnInit, OnChanges, OnDestroy {
  @Input() filterGroupIds: number[];
  @ViewChild('pieCanvas') pieCanvas: any;
  realtime_pushapi = true;

  statsCallCounter: CallsStatsDaily = {
    listened_voicemail: 0,
    listened_voicemail_percent: 0,
    handled_missedcall: 0,
    handled_missedcall_percent: 0
  };

  statsDailyLive: UserStatsDailyLiveOverview = {
    inbound_call_count: 0,
    outbound_call_count: 0,
    inbound_call_answered: 0,
    missed_call_count: 0,
    missed_call_unhandled: 0,
    outbound_call_answered: 0,
    inbound_call_effective: 0,
    outbound_call_effective: 0,
    total_voicemail_today: 0,
    unread_voicemail_today: 0,
    active_inbound_call: 0,
    active_outbound_call: 0,
    agent_connected: 0,
    agent_available: 0,
    agent_incall: 0,
    agent_pause: 0,
    qos: 0,
  };

  qosChart: Chart;

  // private highchartConfigs:any = {};
  private readonly todayQosOptions = {
    udupercent: 0,
    lineWidth: 8,
    trackColor: '#e8eff0',
    barColor: '#24a6e7',
    scaleColor: false,
    size: 150,
    rotate: 90,
    lineCap: 'butt'
  };

  constructor(
    private readonly liveStatsService: LiveStatsService,
    private readonly router: Router,
    private readonly highChartsHelperService: HighChartsHelperService,
    cdr: ChangeDetectorRef,
  ) {
    super(cdr);

    // this.todayQosOptions = {
    //   udupercent: 0,
    //   lineWidth: 8,
    //   trackColor: '#e8eff0',
    //   barColor: '#24a6e7',
    //   scaleColor: false,
    //   size: 150,
    //   rotate: 90,
    //   lineCap: 'butt'
    // };

    // this.highchartConfigs.today_qos = {
    //   percent: 0,
    //   lineWidth: 4,
    //   trackColor: '#e8eff0',
    //   barColor: '#24a6e7',
    //   scaleColor: false,
    //   size: 150,
    //   rotate: 90,
    //   lineCap: 'butt'
    // };

    this.addSubscription(
      interval(30000).subscribe(() => {
        this.updateStatsDailyLive();
      })
    );
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.updateStatsDailyLive();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.filterGroupIds) {
      this.updateStatsDailyLive();
    }
  }

  private updateStatsDailyLive(): void {
    this.liveStatsService.dashboardGetAgentInfo(this.filterGroupIds).then((res: UserStatsDailyLiveOverview) => {
      this.statsDailyLive = res;
      this.updateCallCounter(this.statsDailyLive);
      this.updateTodayQos(this.statsDailyLive.qos);
    });
  }

  private updateCallCounter(stats: UserStatsDailyLiveOverview): void {
    const listnedVm: number = stats.total_voicemail_today - stats.unread_voicemail_today;
    const handledMc: number = stats.missed_call_count - stats.missed_call_unhandled;

    this.statsCallCounter = {
      listened_voicemail: listnedVm,
      listened_voicemail_percent: stats.total_voicemail_today > 0 ? Math.round(listnedVm * 100 / stats.total_voicemail_today) : 0,
      handled_missedcall: handledMc,
      handled_missedcall_percent: stats.missed_call_count > 0 ? Math.round(handledMc * 100 / stats.missed_call_count) : 0,
    };
  }

  private updateTodayQos(qos: number): void {
    if (this.qosChart) {
      this.qosChart.destroy();
    }

    const options: any = this.highChartsHelperService.getQosChartOptions();
    _.extend(options.chart, {
      height: 200,
      width: 200,
      backgroundColor: 'transparent',
      margin: [0, 0, 0, 0]
    });

    _.extend(options.subtitle, {
      text: `<span style="font-size:30px; font-weight:600; color:${this.todayQosOptions.barColor};">
            ${qos || 0}<span style="font-size:15px; font-weight:400;">%</span>
          </span>`,
    });

    _.extend(options.plotOptions.pie, {
      startAngle: this.todayQosOptions.rotate
    });

    _.extend(options.series, [{
      data: [qos || 0, 100 - (qos || 0)]
    }]);

    this.qosChart = new Chart(options);
  }

  gotoTab(view: string): void {
    this.router.navigate([], { queryParams: { view } });
  }

  todayMissedCalls(): void {
    this.router.navigate([], { queryParams: { view: 'missed-calls' }, state: { period: 'today' } });
  }

  gotoPage(pageAlias: string): void {
    this.router.navigateByUrl(pageAlias);
  }
}
